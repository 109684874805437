import React, { useState, useCallback } from "react";
import { Modal, Button, Input } from "antd";

const useGlobalModal = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [modalConfig, setModalConfig] = useState({});
  const [inputValue, setInputValue] = useState("");

  const showModal = useCallback((content, config = {}) => {
    setModalConfig({ content, ...config });
    setInputValue(config.initialInputValue || "");
    setIsVisible(true);
  }, []);

  const hideModal = useCallback(() => {
    setIsVisible(false);
    setModalConfig({});
    setInputValue("");
  }, []);

  const handleOk = useCallback(() => {
    if (modalConfig.onOk) {
      modalConfig.onOk(inputValue);
    }
    hideModal();
  }, [modalConfig, hideModal, inputValue]);

  const handleCancel = useCallback(() => {
    if (modalConfig.onCancel) {
      modalConfig.onCancel();
    }
    hideModal();
  }, [modalConfig, hideModal]);

  const handleInputChange = useCallback((e) => {
    setInputValue(e.target.value);
  }, []);

  const GlobalModal = () => (
    <Modal visible={isVisible} onCancel={handleCancel} {...modalConfig}>
      {modalConfig.input ? (
        <Input
          value={inputValue}
          onChange={handleInputChange}
          placeholder={modalConfig.inputPlaceholder || "Enter text"}
        />
      ) : null}
      {modalConfig.content}
    </Modal>
  );

  return { showModal, hideModal, GlobalModal };
};

export default useGlobalModal;
