import React, { useState } from "react";
import { Modal, Button, Typography, Card } from "antd";
import { useStore } from "../../store/useStore";
import style from "../../Styles/style";
import { useNavigate } from "react-router-dom";
import {
  PlayCircleOutlined,
  ThunderboltOutlined,
  RobotOutlined,
  FormatPainterFilled,
  CheckCircleOutlined,
  RiseOutlined,
} from "@ant-design/icons";
import { createCheckoutSession } from "../../services/creditService";
import { loadStripe } from "@stripe/stripe-js";
import mixpanel from "mixpanel-browser";
import { useAuthStore } from "../../store/useAuthStore";
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE);
function CreditModal() {
  const navigate = useNavigate();
  const { user } = useAuthStore();
  const { Title, Text } = Typography;
  const { isShowingModalCredit, hideCreditModal } = useStore();

  const handleCancel = () => {
    mixpanel.track("Cancel Credit Modal");
    hideCreditModal();
  };
  async function goToPayment() {
    try {
      const session = await createCheckoutSession({
        credits: 100,
        email: user.email,
        userid: user._id,
      });

      const stripe = await stripePromise;
      const result = await stripe.redirectToCheckout({ sessionId: session.id });

      if (result.error) {
        alert(result.error.message);
      }
    } catch (error) {
      mixpanel.track("Error checkout From popup", { numberOfCredit: 100 });
      console.error("Error during the checkout process:", error);
      // Handle errors here
    }
  }
  return (
    <Modal visible={isShowingModalCredit} onCancel={handleCancel} footer={null}>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Title level={3}>Buy credits to continue</Title>
        <p>You have currently 0 credits. Here is our best selling pack :</p>
        <Card
          style={{
            border: "2px solid #21B3CD",
            margin: 30,
            marginBottom: 15,
            borderRadius: 20,
            borderColor: style.colors.color,
            backgroundColor: style.colors.background1,
          }}
          bordered={false}
        >
          <div
            className="popular-badge"
            style={{ backgroundColor: style.colors.color }}
          >
            MOST POPULAR
          </div>
          <Title
            style={{
              borderRadius: 15,
              backgroundColor: style.colors.background2,
              textAlign: "center",
            }}
            level={2}
          >
            100 credits
          </Title>
          <div style={{ padding: "0 16px", marginTop: 20 }}>
            {" "}
            {/* Add padding for the text content */}
            <Text style={{ display: "block", margin: "8px 0" }}>
              {" "}
              {/* Display block for each Text */}
              <CheckCircleOutlined
                style={{ color: style.colors.color, marginRight: 20 }}
              />
              Image Generation
            </Text>
            <Text style={{ display: "block", margin: "8px 0" }}>
              <CheckCircleOutlined
                style={{ color: style.colors.color, marginRight: 20 }}
              />{" "}
              YouTube & Instagram Visual
            </Text>
            <Text style={{ display: "block", margin: "8px 0" }}>
              <CheckCircleOutlined
                style={{ color: style.colors.color, marginRight: 20 }}
              />{" "}
              VIP Support
            </Text>
            <Text style={{ display: "block", margin: "8px 0" }}>
              <CheckCircleOutlined
                style={{ color: style.colors.color, marginRight: 20 }}
              />{" "}
              Access to future features first
            </Text>
          </div>
          <Title level={3} className="price" style={{ textAlign: "center" }}>
            <span style={{ color: style.colors.textSoft }}>US</span> $15
          </Title>
          <Button
            size="big"
            style={{
              height: 50,
              borderRadius: 20,
              width: "100%",
              alignSelf: "center",
              backgroundColor: style.colors.color,
            }}
            onClick={() => {
              mixpanel.track("Modal : Pay now");
              goToPayment();
            }}
          >
            Pay now
          </Button>
        </Card>
        <a
          onClick={() => {
            mixpanel.track("Modal : View offers");
            hideCreditModal();
            navigate("/dashboard/buy-credits", { replace: true });
          }}
          style={{
            fontWeight: 600,
            margin: "0 0px",
            color: style.colors.color,
          }}
        >
          View all offers
        </a>
        <Button
          type="default"
          size="small"
          style={{
            marginTop: 30,
            height: 50,
            borderRadius: 20,

            width: "100%",
            alignSelf: "center",
          }}
          onClick={() => {
            handleCancel();
          }}
        >
          Later
        </Button>
      </div>
    </Modal>
  );
}

export default CreditModal;
