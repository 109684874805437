import React, { useEffect, useState } from "react";
import { fabric } from "fabric";
import icons from "../../images/platforms-icons.png";
import FontFaceObserver from "fontfaceobserver";

export const ImagePlatformComponent = ({
  shouldgenerateVertical,
  shouldgenerateHorizontal,
  background,
  onImageCreated,
  onHorizontalCreated,
  info,
}) => {
  const [readyCanvas, setReadyCanvas] = useState(null);
  const createImageWithText = async (background, callback) => {
    const canvas = new fabric.Canvas("myCanvas");

    canvas.width = 1080;
    canvas.height = 1920;
    canvas.setBackgroundColor("black");
    const urlWithCacheBustingBackground =
      background + "?_=" + new Date().getTime();
    fabric.Image.fromURL(
      urlWithCacheBustingBackground,
      (img) => {
        console.log("img height", img.height);
        img.set({
          opacity: 0.5,
          scaleY: 1920 / img.height,
          scaleX: 1920 / img.height,
          left: (canvas.width - 1920) / 2,
          top: 0,
        });
        img.filters.push(new fabric.Image.filters.Blur({ blur: 0.5 }));
        img.applyFilters();
        canvas.add(img);

        fabric.Image.fromURL(
          icons,
          (icons) => {
            icons.scaleToWidth(397);
            icons.set({
              left: (canvas.width - icons.getScaledWidth()) / 2,
              top: 1660,
            });
            canvas.add(icons);
            const urlWithCacheBustingBackground2 =
              background + "?_=" + new Date().getTime();
            fabric.Image.fromURL(
              urlWithCacheBustingBackground2,
              (smallImg) => {
                smallImg.scaleToWidth(780);
                smallImg.set({
                  left: (canvas.width - smallImg.getScaledWidth()) / 2,
                  top: 386,
                });
                canvas.add(smallImg);

                const artist = new fabric.Text(info.artist, {
                  left: canvas.width / 2,
                  top: 1274,
                  fontFamily: "Barlow",
                  fontSize: 64,
                  fill: "white",
                  originX: "center",
                  charSpacing: 200,
                  id: "artist",
                });
                canvas.add(artist);
                console.log("artistWidth", artist.width);
                const title = new fabric.Text(info.title, {
                  left: canvas.width / 2,
                  top: 1350,
                  fontFamily: "Barlow-Bold",
                  fontSize: 96,
                  fill: "white",
                  originX: "center",
                  id: "title",
                });
                canvas.add(title);
                console.log("TitleWidth", title.width);
                const text = new fabric.Text(info.text, {
                  left: canvas.width / 2,
                  top: 1585,
                  fontFamily: "Barlow-Condensed",
                  fontSize: 48,
                  fill: "white",
                  originX: "center",
                  id: "text",
                });
                canvas.add(text);
                scaleVerticalText(title, info.title, artist, info.artist);
                canvas.renderAll();
                setReadyCanvas(canvas);

                const dataURL = canvas.toDataURL({
                  format: "png",
                  quality: 1.0,
                });
                callback(dataURL);
              },
              { crossOrigin: "anonymous" }
            );
          },
          { crossOrigin: "anonymous" }
        );
      },
      { crossOrigin: "anonymous" }
    );
  };

  const createHorizontalImageWithText = async (background, callback) => {
    const canvas = new fabric.Canvas("myCanvas");

    canvas.width = 1920;
    canvas.height = 1080;
    canvas.setBackgroundColor("black");
    const urlWithCacheBustingBackground =
      background + "?_=" + new Date().getTime();
    fabric.Image.fromURL(
      urlWithCacheBustingBackground,
      (img) => {
        console.log("img height", img.height);
        img.set({
          opacity: 0.5,
          scaleY: 1920 / img.height,
          scaleX: 1920 / img.height,
          left: 0,
          top: (canvas.height - 1920) / 2,
        });
        img.filters.push(new fabric.Image.filters.Blur({ blur: 0.5 }));
        img.applyFilters();
        canvas.add(img);

        fabric.Image.fromURL(
          icons,
          (icons) => {
            icons.scaleToWidth(397);
            icons.set({
              left: (3 * canvas.width) / 4 - icons.getScaledWidth() / 2,
              top: 684,
            });
            canvas.add(icons);
            const urlWithCacheBustingBackground2 =
              background + "?_=" + new Date().getTime();
            fabric.Image.fromURL(
              urlWithCacheBustingBackground2,
              (smallImg) => {
                smallImg.scaleToWidth(780);
                smallImg.set({
                  left: 277,
                  top: (canvas.height - smallImg.getScaledHeight()) / 2,
                });
                canvas.add(smallImg);

                const artist = new fabric.Text(info.artist, {
                  left: (3 * canvas.width) / 4,
                  top: 286,
                  fontFamily: "Barlow",
                  fontSize: 64,
                  fill: "white",
                  originX: "center",
                  charSpacing: 200,
                  id: "artist",
                });
                canvas.add(artist);

                console.log("artistwidth", artist.width);

                const title = new fabric.Text(info.title, {
                  left: (3 * canvas.width) / 4,
                  top: 374,
                  fontFamily: "Barlow-Bold",
                  fontSize: 120,
                  fill: "white",
                  originX: "center",
                  id: "title",
                });
                canvas.add(title);
                console.log("titlewidth", title.width);

                // Base dimensions and text lengths
                scaleText(title, info.title, artist, info.artist);

                const text = new fabric.Text(info.text, {
                  left: (3 * canvas.width) / 4,
                  top: 630,
                  fontFamily: "Barlow-Condensed",
                  fontSize: 48,
                  fill: "white",
                  originX: "center",
                  id: "text",
                });

                canvas.add(text);
                console.log("Width of horizontal: ", title.width);
                canvas.renderAll();
                setReadyCanvas(canvas);

                const dataURL = canvas.toDataURL({
                  format: "png",
                  quality: 1.0,
                });
                callback(dataURL);
              },
              { crossOrigin: "anonymous" }
            );
          },
          { crossOrigin: "anonymous" }
        );
      },
      { crossOrigin: "anonymous" }
    );
  };

  function scaleText(title, titleText, artist, artistText) {
    const widthOftitle = 338;
    const widthOfArtist = 275;
    const initialNumberCharacter = 6;
    const widthPerTitleCharacter = widthOftitle / initialNumberCharacter;
    const widthPerArtistCharacter = widthOfArtist / initialNumberCharacter;

    if (widthPerTitleCharacter * titleText.length > 600) {
      title.scaleToWidth(600);
    }
    if (widthPerArtistCharacter * artistText.length > 500) {
      artist.scaleToWidth(500);
      let currentScale = artist.scaleY;
      artist.top = artist.top + 20 * (1 / currentScale);
    }
    let minScale = Math.min(title.scaleY, artist.scaleY);
    title.top = title.top + 80 * (1 - minScale);
    artist.top = artist.top + 80 * (1 - minScale);
  }
  function scaleVerticalText(title, titleText, artist, artistText) {
    const widthOftitle = 271;
    const widthOfArtist = 275;
    const initialNumberCharacter = 6;
    const widthPerTitleCharacter = widthOftitle / initialNumberCharacter;
    const widthPerArtistCharacter = widthOfArtist / initialNumberCharacter;

    if (widthPerTitleCharacter * titleText.length > 650) {
      title.scaleToWidth(650);
    }
    if (widthPerArtistCharacter * artistText.length > 500) {
      artist.scaleToWidth(500);
      let currentScale = artist.scaleY;
      artist.top = artist.top + 20 * (1 / currentScale);
    }
    let minScale = Math.min(title.scaleY, artist.scaleY);
    title.top = title.top + 80 * (1 - minScale);
    artist.top = artist.top + 80 * (1 - minScale);
  }

  const loadFontAndAddText = async () => {
    const fontObserver = new FontFaceObserver("Barlow-Bold");
    const fontObserver2 = new FontFaceObserver("Barlow");
    const fontObserver3 = new FontFaceObserver("Barlow-Condensed");
    if (!shouldgenerateVertical && !shouldgenerateHorizontal) {
      return;
    }
    try {
      await fontObserver.load();
      await fontObserver2.load();
      await fontObserver3.load();
      if (shouldgenerateVertical) {
        console.log("vertical");
        // Font is loaded, now add text to the canvas
        createImageWithText(background, onImageCreated);
      }
      if (shouldgenerateHorizontal) {
        console.log("horizontal");
        createHorizontalImageWithText(background, onHorizontalCreated);
      }
    } catch (error) {
      console.error("Font loading failed:", error);
    }
  };

  useEffect(() => {
    console.log("logimage", shouldgenerateHorizontal);
    loadFontAndAddText();
  }, [background, shouldgenerateVertical, shouldgenerateHorizontal]);

  useEffect(() => {
    console.log("info changed");
    if (readyCanvas) {
      console.log("yeees");
      const textboxs = readyCanvas.getObjects("text");
      console.log("textboxs", textboxs);
      for (const textbox of textboxs) {
        if (textbox.id === "title") {
          textbox.set({ text: info.text });
        }
        if (textbox.id === "artist") {
          textbox.set({ text: info.artist });
        }
      }
      readyCanvas.renderAll();
    }
  }, [info]);
  return (
    <div style={{ display: "none" }}>
      <canvas
        style={{ display: "none", width: 1080, height: 1920 }}
        id="myCanvas"
        width="1080"
        height="1920"
      ></canvas>
    </div>
  );
};
