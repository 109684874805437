import React, { useEffect, useState } from "react";
import parental from "../../images/parental-advisory.png";
import WebFont from "webfontloader";
import { fabric } from "fabric";
import {
  Avatar,
  Input,
  Typography,
  Row,
  Col,
  List,
  Card,
  Form,
  Button,
  Select,
  Collapse,
  Flex,
  Switch,
} from "antd";

import "./thumbnailcard.css"; // Import your CSS file here
import { UserOutlined } from "@ant-design/icons";
import { FabricJSCanvas, useFabricJSEditor } from "fabricjs-react";
import ThumbnailCard from "./ThumbnailCard";
import style from "../../Styles/style";
import { PlusOutlined } from "@ant-design/icons";
import DraggableList from "./DraggableList";
import useProjectStore from "../../store/useProjectStore";
import { useStore } from "../../store/useStore";
import { throttle } from "../../tools/ScrollTools";
import mixpanel from "mixpanel-browser";
const { Meta } = Card;
const { Title, Text, Paragraph } = Typography;
const googlefontkey = "AIzaSyBY_ANk4B5C3WbyRej - s61taffvTiBSiB8";
const { Option } = Select;

const { Panel } = Collapse;
const ThumbnailEditor = ({ url, edit, validateCover, hasJson, json }) => {
  const { editor, onReady } = useFabricJSEditor();
  const { selectedImage, setIsCreatingCover, isCreatingCover } =
    useProjectStore();
  const [isDirty, setIsDirty] = useState(false);
  const [texte, setTexte] = useState("title thhhh");
  const [copiedtexte, setcopiedTexte] = useState("title thhhh");
  const [hasLogoParental, sethasLogoParental] = useState(false);
  const { showLoading, hideLoading } = useStore();
  const shouldRenderRevert =
    (hasJson && isCreatingCover) || (hasJson && isDirty);
  const handleInputChange = (e) => {
    setTexte(e.target.value);
    const canvasTextbox = editor.canvas.getObjects("textbox")[0];

    canvasTextbox.text = e.target.value;
    editor?.canvas.renderAll();
  };
  const [isMobile, setIsMobile] = useState(window.innerWidth < 992);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 992);
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Call it once to set initial state based on current window size

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const [items, setItems] = useState([]);

  const handleAddItem = () => {
    mixpanel.track("Editor : Add Text");
    setIsDirty(true);
    edit();
    const uniqueId = Date.now().toString();
    let newItem = {
      text: "Nouveau texte",

      id: uniqueId,
      fill: "#ffffff",
      isVisible: true,
    };

    const txt = new fabric.Textbox("Nouveau texte", {
      fontSize: 40,
      fontWeight: "bold",
      fill: "#ffffff",

      textAlign: "center",
      id: uniqueId,
    });
    txt.bringToFront();
    txt.selectable = true;
    txt.top = editor.canvas.height / 2 - txt.height / 2;
    txt.left = editor.canvas.width / 2 - txt.width / 2;

    txt.set("shadow", { blur: 15, offsetX: 0, offsetY: 0 });

    editor.canvas.add(txt);
    editor.canvas.setActiveObject(txt);
    editor.canvas.renderAll();

    setItems([newItem, ...items]);
  };

  const updateItem = (index, key, value, id) => {
    edit();
    setIsDirty(true);
    const newItems = [...items];
    newItems[index][key] = value;

    setItems(newItems);
    if (id) {
      const textboxs = editor.canvas.getObjects("textbox");
      for (const textbox of textboxs) {
        if (textbox.id === id) {
          console.log(textbox);
          textbox.set({ [key]: value });
          editor.canvas.renderAll();

          return;
        }
      }
    }
  };
  function removeItem(itemId) {
    setIsDirty(true);
    const item = editor.canvas.getObjects().find((item) => item.id === itemId);
    editor.canvas.remove(item);
    editor.canvas.renderAll();
  }

  const updateInputs = (id, text) => {
    setItems((items) =>
      items.map((item) => (item.id === id ? { ...item, text: text } : item))
    );
  };

  const reorderLayer = (startIndex, endIndex) => {
    edit();
    // Now reorder the canvas layers to match the new order
    const canvasObjects = editor.canvas.getObjects();

    // Assuming that the list items and canvas objects are in sync
    const movedObject = canvasObjects[startIndex];
    editor.canvas.moveTo(movedObject, endIndex);

    editor.canvas.renderAll();
  };
  const handleDeleteItem = (id) => {
    edit();
    // Remove the corresponding textbox from the canvas
    const textboxs = editor.canvas.getObjects("textbox");
    const textboxToDelete = textboxs.find((textbox) => textbox.id === id);
    if (textboxToDelete) {
      editor.canvas.remove(textboxToDelete);
      editor.canvas.renderAll();
    }

    // Remove the deleted item from the items array
    const updatedItems = items.filter((item) => item.id !== id);
    setItems(updatedItems);
  };

  useEffect(() => {
    const resizeListener = () => {
      updateCanvasSize();
      console.log("resize");
    };

    window.addEventListener("resize", resizeListener);

    return () => {
      window.removeEventListener("resize", resizeListener);
    };
  }, [editor]);

  const updateCanvasSize = () => {
    if (!url || !editor) {
      return;
    }
    resizeCanvas();
  };

  function resizeTo(newWidth) {
    // If there's a background image, scale the canvas based on the image's aspect ratio
    if (editor.canvas.backgroundImage) {
      const bg = editor.canvas.backgroundImage;
      const scaleRatio = Math.min(newWidth / bg.width, newWidth / bg.height);
      const objScaleRatio = newWidth / editor.canvas.height;
      editor.canvas.setWidth(bg.width * scaleRatio);
      editor.canvas.setHeight(bg.height * scaleRatio);

      // Update the scale of the background image
      editor.canvas.backgroundImage.scaleX = scaleRatio;
      editor.canvas.backgroundImage.scaleY = scaleRatio;
      console.log("objscaleRatio", objScaleRatio);

      // Update positions and scales of other canvas objects
      editor.canvas.getObjects().forEach((obj) => {
        obj.scaleX *= objScaleRatio;
        obj.scaleY *= objScaleRatio;
        obj.left *= objScaleRatio;
        obj.top *= objScaleRatio;
        obj.setCoords();
      });
    } else {
      // If no background image, just resize the canvas to the new width
      editor.canvas.setWidth(newWidth);
      editor.canvas.setHeight(newWidth); // Keeping it square as per your initial setup
    }

    editor.canvas.renderAll();
  }

  function resizeCanvas() {
    const parentContainer = editor.canvas.getElement().parentNode;
    const newWidth = parentContainer.clientWidth;

    // If there's a background image, scale the canvas based on the image's aspect ratio
    if (editor.canvas.backgroundImage) {
      const bg = editor.canvas.backgroundImage;
      const scaleRatio = Math.min(newWidth / bg.width, newWidth / bg.height);
      const objScaleRatio = newWidth / editor.canvas.height;
      editor.canvas.setWidth(bg.width * scaleRatio);
      editor.canvas.setHeight(bg.height * scaleRatio);

      // Update the scale of the background image
      editor.canvas.backgroundImage.scaleX = scaleRatio;
      editor.canvas.backgroundImage.scaleY = scaleRatio;
      console.log("objscaleRatio", objScaleRatio);

      // Update positions and scales of other canvas objects
      editor.canvas.getObjects().forEach((obj) => {
        obj.scaleX *= objScaleRatio;
        obj.scaleY *= objScaleRatio;
        obj.left *= objScaleRatio;
        obj.top *= objScaleRatio;
        obj.setCoords();
      });
    } else {
      // If no background image, just resize the canvas to the new width
      editor.canvas.setWidth(newWidth);
      editor.canvas.setHeight(newWidth); // Keeping it square as per your initial setup
    }

    editor.canvas.renderAll();
  }

  const loadFromJson = () => {
    console.log("Loading from json");
    showLoading();
    let strToJson = JSON.parse(json);
    let originalWidthOfImage = strToJson.backgroundImage.width;
    let originalHeightOfImage = strToJson.backgroundImage.height;
    let initialScaleOfImage = strToJson.backgroundImage.scaleX;

    console.log("originalWidthOfImage", originalWidthOfImage);

    const parentContainer = editor.canvas.getElement().parentNode;
    const newWidth = parentContainer.clientWidth;
    console.log("Width of parent", newWidth);
    editor.canvas.loadFromJSON(strToJson, () => {
      // Calculate the scale ratio for the background image
      const bgScaleRatio = Math.min(
        newWidth / originalWidthOfImage,
        newWidth / originalHeightOfImage
      );

      // If there's a background image, adjust its scale
      if (editor.canvas.backgroundImage) {
        editor.canvas.setWidth(originalWidthOfImage * bgScaleRatio);
        editor.canvas.setHeight(originalHeightOfImage * bgScaleRatio);
        editor.canvas.backgroundImage.scaleX = bgScaleRatio;
        editor.canvas.backgroundImage.scaleY = bgScaleRatio;
      }

      // Calculate the scale ratio for other objects
      const originalCanvasWidth = strToJson.backgroundImage
        ? strToJson.backgroundImage.width
        : editor.canvas.width;

      const oldWidth = 1024 * initialScaleOfImage;

      const objScaleRatio = newWidth / oldWidth;
      // Update positions and scales of other canvas objects
      editor.canvas.getObjects().forEach((obj) => {
        if (
          obj.type !== "image" ||
          obj.src !== editor.canvas.backgroundImage.src
        ) {
          obj.scaleX *= objScaleRatio;
          obj.scaleY *= objScaleRatio;
          obj.left *= objScaleRatio;
          obj.top *= objScaleRatio;
          obj.setCoords();
        }
      });

      const textboxs = editor.canvas.getObjects("textbox");
      let arrayItems = [];
      const fontsToLoad = new Set();
      let index = 0;
      for (const textbox of textboxs) {
        console.log(textbox);
        const uniqueId = Date.now().toString() + index;
        textbox.id = uniqueId;
        if (textbox.fontFamily && textbox.fontFamily != "Arial") {
          fontsToLoad.add(textbox.fontFamily);
        }
        arrayItems.push(textbox);
        index++;
      }
      setItems(arrayItems);

      let containsParentalAdvisory = false;
      console.log("how many fonts to load", fontsToLoad);
      if (fontsToLoad.size > 0) {
        WebFont.load({
          google: {
            families: Array.from(fontsToLoad),
          },
          active: () => {
            // Perform actions after fonts are loaded
            hideLoading();
            editor.canvas.renderAll();
          },
        });
      } else {
        hideLoading();
      }
      const images = editor.canvas.getObjects("image");
      let hasaParentalLogo = false;
      for (const image of images) {
        console.log("load img src", image);

        if (
          image.src === "http://localhost:3000/parental-advisory.jpg" &&
          image.visible
        ) {
          image.id = "parental";
          sethasLogoParental(true);
          hasaParentalLogo = true;
        }
      }
      if (!hasaParentalLogo) {
        addLogoParental(false, bgScaleRatio);
      }

      console.log("images", images);
    });
  };

  function verticaAlign(itemid) {
    mixpanel.track("Editor : Align Vertical");
    setIsDirty(true);
    const textbox = editor.canvas
      .getObjects()
      .find((item) => item.id === itemid);
    const objectHeight = textbox.getScaledHeight();
    const canvasHeight = editor.canvas.getHeight();
    textbox.set("top", (canvasHeight - objectHeight) / 2);
    editor.canvas.renderAll();
  }
  function horizontalAlign(itemid) {
    mixpanel.track("Editor : Align Horizontal");
    setIsDirty(true);
    const textbox = editor.canvas
      .getObjects()
      .find((item) => item.id === itemid);
    const objectwidth = textbox.getScaledWidth();
    const canvasWidth = editor.canvas.getWidth();
    textbox.set("left", (canvasWidth - objectwidth) / 2);
    editor.canvas.renderAll();
  }
  const loadBackground = () => {
    console.log("Loading background");
    const urlWithCacheBusting = url + "?_=" + new Date().getTime();
    fabric.Image.fromURL(
      urlWithCacheBusting,
      function (oImg) {
        editor.canvas.setHeight(oImg.getScaledHeight());

        const scaleRatio = Math.min(
          editor.canvas.width / oImg.width,
          editor.canvas.height / oImg.height
        );

        editor.canvas.setWidth(oImg.width * scaleRatio);
        editor.canvas.setHeight(oImg.height * scaleRatio);
        editor.canvas.setBackgroundImage(
          oImg,
          editor.canvas.renderAll.bind(editor.canvas),
          {
            scaleX: scaleRatio,
            scaleY: scaleRatio,
            originX: "left",
            originY: "top",
            crossOrigin: "anonymous",
          }
        );
        addLogoParental(false, scaleRatio);
      },
      { crossOrigin: "anonymous" }
    );
  };
  function addLogoParental(visible, scaleRatio) {
    fabric.Image.fromURL(
      "/parental-advisory.jpg",
      (img) => {
        img.set({
          top: (1024 - 110) * scaleRatio,
          left: (1024 - 160) * scaleRatio,
          angle: 0,
          scaleX: 0.7 * scaleRatio,
          scaleY: 0.7 * scaleRatio,
          cornersize: 10,
          visible: visible,
          id: "parental",
        });

        // Add image to the canvas
        editor.canvas.add(img);
      },
      { crossOrigin: "anonymous" }
    );
  }
  const setVisibleLogoParental = (visible) => {
    setIsDirty(true);
    console.log("pastHaslogo", hasLogoParental);
    const images = editor.canvas.getObjects("image");
    const image = images.find((img) => img.id === "parental");
    console.log("islogoparental found", image);
    if (image) {
      sethasLogoParental(visible);
      image.visible = visible;
      editor.canvas.renderAll();
    }
  };
  useEffect(() => {
    hideLoading();
  }, []);

  useEffect(() => {}, [items]);
  useEffect(() => {
    if (!url || !editor) {
      return;
    }

    editor?.canvas.clear();
    editor?.canvas.on("text:changed", function (e) {
      //this will only work using the dev build
      updateInputs(e.target.id, e.target.text);
    });
    /*if ("ontouchstart" in document.documentElement) {
      editor.canvas.selection = false; // Disable multi-selection for touch devices
    }

    const canvasElement = document.querySelector(".fabricCanvas"); // Your canvas element

    let startY;

    canvasElement.addEventListener(
      "touchstart",
      (e) => {
        startY = e.touches[0].pageY;
      },
      { passive: true }
    );

    const handleTouchMove = throttle((e) => {
      const moveY = e.touches[0].pageY;
      const diffY = moveY - startY;

      // Scroll the window or a specific container
      window.scrollBy(0, -diffY);

      // Update start position for next move
      startY = moveY;

      e.preventDefault();
    }, 100); // Adjust the 100ms throttle limit as needed

    canvasElement.addEventListener("touchmove", handleTouchMove, {
      passive: false,
    });*/

    if (hasJson && !isCreatingCover) {
      loadFromJson();
      return;
    }

    loadBackground();

    // Create a new text object and add it to the canvas
  }, [url]);
  const handleFileChange = (e) => {
    edit();
    const file = e.target.files[0];
    if (file && editor) {
      const reader = new FileReader();
      reader.onload = (f) => {
        const imgObj = new Image();
        imgObj.src = f.target.result;
        imgObj.onload = () => {
          const image = new fabric.Image(imgObj);
          image.set({
            angle: 0,
            padding: 10,
            cornersize: 10,
          });
          editor.canvas.add(image);
          editor.canvas.renderAll();
        };
      };
      reader.readAsDataURL(file);
    }
  };
  const triggerFileInput = () => {
    // Trigger the hidden file input
    document.getElementById("fileInput").click();
  };

  const validate = () => {
    const originalWidth = editor.canvas.getWidth();
    resizeTo(1024);

    const canvasJson = editor.canvas.toJSON();
    //console.log(canvasJson)
    const dataURL = editor.canvas.toDataURL({
      format: "jpeg",
      quality: 1, // Quality from 0 to 1 for JPEG (not applicable to PNG)
    });
    validateCover(canvasJson, dataURL);
    resizeTo(originalWidth);
    //saveJson();
    //downloadImage()
  };
  const revertChanges = () => {
    mixpanel.track("Editor : Revert Changes");
    setIsCreatingCover(false);
    setIsDirty(false);
    resetAndReloadJson();
  };
  const resetAndReloadJson = () => {
    editor?.canvas.clear();
    setItems([]);
    if (hasJson) {
      loadFromJson();
    }
  };
  const saveJson = () => {
    const canvasState = editor.canvas.toJSON();
    const jsonString = JSON.stringify(canvasState);
    let size = new Blob([jsonString]).size;
  };
  const downloadImage = () => {
    const dataURL = editor.canvas.toDataURL({
      format: "jpeg",
      quality: 1, // Quality from 0 to 1 for JPEG (not applicable to PNG)
    });

    const link = document.createElement("a");
    if (typeof link.download === "string") {
      link.href = dataURL;
      link.download = "canvas-image.jpg"; // Provide the name for the downloaded file

      // Append to html link element page
      document.body.appendChild(link);

      // Start download
      link.click();

      // Clean up and remove the link
      document.body.removeChild(link);
    } else {
      window.open(dataURL); // For browsers that don't support download attribute
    }
  };
  return (
    <div>
      <Row
        style={{
          padding: 0,
          margin: 0,
          marginTop: 10,
          width: "100%",
          height: "100vh",
        }}
        gutter={24}
      >
        <Col
          style={{
            borderRadius: "10px 10px 0px 0px",

            padding: 0,
          }}
          xs={24}
          lg={8}
        >
          <div>
            <Row
              style={{
                padding: 10,

                background: style.colors.background0,
                borderRadius: "10px 10px 0px 0px",
                border: "1px solid grey",
                borderColor: style.colors.background2,
              }}
              align="middle"
              justify="space-between"
            >
              <Col>
                <Title level={5} style={{ margin: 0, lineHeight: "32px" }}>
                  Add a text layer
                </Title>
              </Col>
              <Col>
                <Button
                  type="primary"
                  shape="circle"
                  icon={<PlusOutlined />}
                  size="small" // Set the size to small
                  onClick={handleAddItem}
                />
              </Col>
            </Row>
            <div
              style={{
                borderRadius: "0px 0px 10px 10px",
                backgroundColor: style.colors.background0,
                height: "300px", // Set a fixed height
                overflowY: "auto", // Enable vertical scrolling
                padding: 10,
              }}
            >
              <DraggableList
                items={items}
                horizontalAlign={horizontalAlign}
                verticaAlign={verticaAlign}
                reorderLayer={reorderLayer}
                updateItem={updateItem}
                setItems={setItems}
                removeItem={removeItem}
              ></DraggableList>
            </div>
            <div
              style={{
                marginTop: 5,
                borderRadius: "10px 10px 10px 10px",
                height: 50,
                display: "flex",
                alignItems: "center",
                padding: 10,
                backgroundColor: style.colors.background0,
                justifyContent: "space-between", // This line is added
              }}
            >
              <Text strong style={{ color: style.colors.text }}>
                Parental advisory logo
              </Text>
              <Switch
                checked={hasLogoParental}
                onChange={(checked) => {
                  mixpanel.track("Editor : parental logo", {
                    visible: checked,
                  });
                  console.log("wasLogoParental");
                  setVisibleLogoParental(checked);
                }}
              ></Switch>
            </div>
            {/*<div
              style={{
                marginTop: 5,
                borderRadius: "10px 10px 10px 10px",
                height: 80,
                padding: 10,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                backgroundColor: style.colors.background0,
                // This line is added
              }}
            >
              <input
                type="file"
                accept="image/*"
                onChange={handleFileChange}
                id="fileInput"
                style={{ display: "none" }}
              />
              <Text strong style={{ color: style.colors.text }}>
                Add an image layer
              </Text>
              <Button
                style={{ marginTop: 10, width: "100%" }}
                icon={<PlusOutlined />}
                onClick={triggerFileInput}
              >
                Upload Image
              </Button>
            </div>*/}
            <div
              style={{
                marginTop: 5,
                borderRadius: "10px 10px 10px 10px",
                height: 50,
                padding: 10,

                display: "flex",

                alignItems: "center",
                backgroundColor: style.colors.background0,
                // This line is added
              }}
            >
              <Button
                type="primary"
                style={{ width: "100%" }}
                onClick={validate}
              >
                Validate cover
              </Button>
            </div>
            {/* ) ||
              (isDirty && hasJson */}
            {shouldRenderRevert && (
              <div
                style={{
                  marginTop: 5,
                  borderRadius: "10px 10px 10px 10px",
                  height: 50,
                  padding: 10,

                  display: "flex",

                  alignItems: "center",
                  backgroundColor: style.colors.background0,
                  // This line is added
                }}
              >
                <Button
                  danger
                  style={{ width: "100%" }}
                  onClick={revertChanges}
                >
                  Revert all changes
                </Button>
              </div>
            )}
          </div>
        </Col>
        <Col
          xs={24}
          lg={16}
          style={{
            maxHeight: "100%",
            margin: 0,
            paddingLeft: isMobile ? 0 : 20,
          }}
        >
          <FabricJSCanvas
            className="fabricCanvas"
            style={{
              width: "100%",
              marginTop: isMobile ? 20 : 0,
            }}
            onReady={onReady}
          />
        </Col>
      </Row>
    </div>
  );
};

export default ThumbnailEditor;
