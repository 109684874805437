import { message } from "antd";

import { useStore } from "../store/useStore";
import { useAuthStore } from "../store/useAuthStore";
import mixpanel from "mixpanel-browser";
const { showModal } = useStore.getState();

const useCreditCheck = () => {
  const { user, decrementCredits } = useAuthStore();
  const { showCreditModal } = useStore();
  const [messageApi, contextHolder] = message.useMessage();
  const hasCredits = () => {
    return user && user.credits > 0;
  };

  const decrements = () => {
    decrementCredits();
    message.open({
      type: "info",
      content: "You have used 1 credit.",
      className: "custom-message",
      style: {
        marginTop: "10vh",
      },
    });
  };
  const checkCredit = () => {
    if (hasCredits()) {
      return true;
    } else {
      mixpanel.track("Not enough credits");
      showCreditModal();
      return false;
    }
  };

  return { hasCredits, checkCredit, decrements };
};

export default useCreditCheck;
