import React, { useState, useEffect, useRef } from "react";
import { Layout, Typography, Input, Button, Avatar } from "antd";
import { motion, AnimatePresence } from "framer-motion";
import { useAuthStore } from "../store/useAuthStore";
import style from "../Styles/style";
import placeholderimage from "../images/skeleton.png";
import { useNavigate } from "react-router-dom";
import { useStore } from "../store/useStore";
import { createProject, useProjectService } from "../services/projectService";
import { TypingText } from "../components/fx/TypingText";

const { Content } = Layout;
const { Title } = Typography;

export const WelcomePage = () => {
  const { showIntroModal } = useStore();
  const { user } = useAuthStore();
  const { addproject } = useAuthStore();
  const [typingComplete, setTypingComplete] = useState(false);
  const navigate = useNavigate();
  const [isIntroShown, setIsIntroShown] = useState(true);
  const [saveDisabled, setSaveDisabled] = useState(false);
  const [nameInput, setnameInput] = useState("");
  const showLoading = useStore((state) => state.showLoading);
  const createProject = useProjectService();
  const inputRef = useRef();
  const focusInput = () => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };
  const containerVariants = {
    hidden: {},
    show: {
      transition: {
        staggerChildren: 0.2, // 100ms stagger
      },
    },
  };
  const parentVariants = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        delay: 0, // Delay should be long enough for all child animations to complete
        duration: 0.01, // A very short dummy animation
      },
    },
  };
  const variants = {
    hidden: { opacity: 0, y: -20 },
    visible: { opacity: 1, y: 0 },
    exit: { opacity: 0, y: -20 }, // Fade out and move up
  };
  const projectVariants = {
    hidden: { opacity: 0, y: -20 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2, // 100ms stagger
      },
    },
    exit: { opacity: 0, y: -20 },
  };
  const introVariants = {
    hidden: {
      y: 50,
      opacity: 0,
    },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        y: { stiffness: 1000, velocity: -100 },
      },
    },
  };

  const childVariants = {
    hidden: {
      y: 50,
      opacity: 0,
    },
    show: {
      y: 0,
      opacity: 1,
      transition: {
        y: { stiffness: 1000, velocity: -100 },
      },
    },
  };
  const handleInputChange = (event) => {
    setnameInput(event.target.value);
  };
  const showProjectName = () => {
    setIsIntroShown(false);
    focusInput();
  };

  const handleTypingComplete = () => {
    setTypingComplete(true);
  };
  const saveProject = () => {
    setSaveDisabled(true);
    //createProject();

    createProject(nameInput, () => {
      navigate("/dashboard/project");
    });
    console.log(nameInput);

    //navigate("/dashboard/profile");
  };

  return (
    <Content
      style={{
        padding: "50px",
        height: "calc(100vh - 64px)",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div style={{ textAlign: "center" }}>
        <AnimatePresence>
          {isIntroShown && ( // Only render the components if isShown is true
            <motion.div
              initial="hidden"
              animate="visible"
              exit="exit" // Define the exit animation
              variants={variants}
              transition={{ duration: 0.5 }} // Adjust the timing as needed
              style={{ textAlign: "center" }}
            >
              <TypingText
                text={`Welcome ${
                  user ? user.email : "Guest"
                }, ready to start ?`}
                onComplete={handleTypingComplete}
              />
              <motion.div
                variants={containerVariants}
                initial="hidden"
                animate={typingComplete ? "show" : "hidden"}
                style={{ display: "flex", flexDirection: "column" }}
              >
                <motion.div variants={childVariants}>
                  <img
                    src={placeholderimage} // Replace with your image path
                    style={{
                      width: "auto", // Keep the image's aspect ratio
                      maxHeight: "200px", // Maximum height of the image
                      objectFit: "cover", // If the image's aspect ratio doesn't match the div, it will be cropped
                    }}
                  />
                </motion.div>
                <motion.div variants={childVariants}>
                  <Button
                    onClick={showProjectName}
                    style={{ marginTop: 20 }}
                    type="primary"
                    size="large"
                  >
                    Create a project
                  </Button>
                </motion.div>
              </motion.div>
              <motion.div
                variants={parentVariants}
                initial="hidden"
                style={{ display: "flex", flexDirection: "column" }}
              ></motion.div>
            </motion.div>
          )}
          {!isIntroShown && ( // Only render the components if isShown is true
            <motion.div
              initial="hidden"
              animate="visible"
              exit="exit" // Define the exit animation
              variants={projectVariants}
              transition={{ duration: 0.5 }} // Adjust the timing as needed
              style={{ display: "flex", flexDirection: "column" }}
            >
              <motion.div variants={introVariants}>
                <TypingText
                  text={`Give a name to your project of video ?`}
                  onComplete={() => {}}
                />
              </motion.div>
              <motion.div variants={introVariants}>
                <Input
                  autoFocus
                  ref={inputRef}
                  value={nameInput}
                  onChange={handleInputChange}
                  type="text"
                  placeholder="Your text here"
                  style={{ height: 50, marginTop: 16 }}
                />
                <Button
                  onClick={saveProject}
                  style={{ paddingLeft: 30, paddingRight: 30, marginTop: 20 }}
                  type="primary"
                  size="large"
                  disabled={saveDisabled}
                >
                  Save
                </Button>
              </motion.div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </Content>
  );
};
