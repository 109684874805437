import React, { useState, useEffect } from "react";
import { Link, useNavigate, useOutlet } from "react-router-dom";
import {
  Layout,
  Button,
  Card,
  Row,
  Col,
  Typography,
  Popover,
  Image,
  Flex,
  Space,
} from "antd";
import {
  EditOutlined,
  CheckOutlined,
  ArrowRightOutlined,
} from "@ant-design/icons";
import { PlusOutlined } from "@ant-design/icons";
import placeholdercover from "../images/placeholdercover.png";
import style from "../Styles/style";
import { useAuth } from "../hooks/useAuth";
import { useProjectService } from "../services/projectService";
import useProjectStore from "../store/useProjectStore";
import "../Styles/myprojects.css";
import RightArrowIcon from "../icons/RightArrowIcon";
import { useStore } from "../store/useStore";
import useCreditCheck from "../hooks/creditChecks";
import { refetchUser } from "../services/loginService";
import { useAuthStore } from "../store/useAuthStore";
import mixpanel from "mixpanel-browser";
const { Header, Content } = Layout;
const { Title, Text, Paragraph } = Typography;

export const MyProjectsPage = () => {
  const { user } = useAuth();
  const { setUser } = useAuthStore();
  const navigate = useNavigate();
  const { setSelectedProject } = useProjectStore();
  const { createProject } = useProjectService();

  const { showLoading, hideLoading } = useStore();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const updateDimensions = () => {
    setWindowWidth(window.innerWidth);
  };
  const [isMobile, setIsMobile] = useState(window.innerWidth < 992);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 992);
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Call it once to set initial state based on current window size

    return () => window.removeEventListener("resize", handleResize);
  }, []);
  useEffect(() => {
    const navigationEntries = performance.getEntriesByType("navigation");
    if (navigationEntries.length > 0) {
      // type: 1 indicates a reload
      if (navigationEntries[0].type === "reload") {
        console.log("This page was reloaded.");
        // Call your function here
        reload();
      }
    }
  }, []);

  async function reload() {
    const response = await refetchUser(user.email);
    setUser(response.user);
    console.log("user refetch");
  }

  const handleCreateNewProject = () => {
    mixpanel.track("Create Project");
    createProject("New project", () => {});
  };

  const modifyProject = (project) => {
    mixpanel.track("Click to modify project");
    setSelectedProject(project);
    navigate("create");
  };
  const download = (url, name) => {
    mixpanel.track("Download from Projects");
    const a = document.createElement("a");
    a.href = url;
    a.download = name + ".png";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const imgStyle = { objectFit: "cover", height: "100%", width: "150px" };

  const cardStyle = {
    width: "100%",
    height: 80,

    display: "flex", // Use flex for horizontal layout
    alignItems: "stretch", // Stretch children to fill the card height
    overflow: "hidden",
    marginBottom: "16px", // Add space between the cards
  };
  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);
  const getResponsiveStyle = () => {
    if (windowWidth < 600) {
      // Mobile styles5
      return {
        fontSize: "14px",
        padding: 0,
        margin: 0,
        marginTop: 10,
        lineHeight: "24px",
      };
    } else if (windowWidth < 1024) {
      // Tablet styles
      return { fontSize: "18px", lineHeight: "24px" };
    } else {
      // Desktop styles
      return { fontSize: "22px", lineHeight: "28px" };
    }
  };

  return (
    <Layout
      className="layout"
      style={{
        background: `url(/backsoft.png)`, // Use backgroundImage with url()
        backgroundSize: "cover", // Ensures the image covers the entire Layout
        backgroundPosition: "center", // Centers the background image
        backgroundRepeat: "no-repeat",
        backgroundAttachment: "fixed", //

        height: "100vh",
        width: "100%",
        display: "flex",
        alignItems: "center",
      }}
    >
      <div
        style={{
          width: "100%",
          minHeight: "100vh", // Ensures at least full viewport height
          height: "auto",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <div style={{ maxWidth: 1200, width: "100%" }}>
          <div
            style={{
              background: "none",
              padding: "20px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <div style={{ display: "flex", alignItems: "center", height: 100 }}>
              <Title style={{ fontSize: isMobile ? 22 : 28, color: "#fff" }}>
                My Covers
              </Title>
            </div>
            <div style={{ display: "flex", alignItems: "center", height: 100 }}>
              <Button
                type="primary"
                onClick={() => {
                  handleCreateNewProject();
                }}
              >
                Create a cover
                <PlusOutlined />
              </Button>
            </div>
          </div>
        </div>

        {/* ... header code */}
        <div
          style={{
            width: "100%",
            paddingLeft: 20,
            paddingRight: 20,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            background: `url(/backsoft.png)`, // Use backgroundImage with url()
            backgroundSize: "cover", // Ensures the image covers the entire Layout
            backgroundPosition: "center", // Centers the background image
            backgroundRepeat: "no-repeat",
            backgroundAttachment: "fixed", //
          }}
        >
          {user?.projects?.map((project) => (
            <Row
              onClick={() => {
                modifyProject(project);
              }}
              style={{
                transition: "all 0.3s ease",
                backgroundColor: style.colors.background1,
                width: "100%",
                borderRadius: 15,
                height: 100,
                maxWidth: 1000,
                marginBottom: 15,
              }}
              gutter={24}
              onMouseOver={(e) => {
                e.currentTarget.style.cursor = "pointer"; // Change cursor to indicate hover
              }}
            >
              <Col
                style={{ height: "100%", paddingLeft: 0 }}
                xs={6}
                lg={4}
                xl={4}
              >
                <img
                  src={project.cover?.url || placeholdercover}
                  style={{
                    borderRadius: "15px 0px 0px 15px",
                    objectFit: "cover",
                    width: "100%",
                    height: "100%",
                  }}
                ></img>
              </Col>
              <Col
                style={{
                  display: "flex",
                  flexDirection: "column",
                  height: "100%",
                  justifyContent: "center",
                }}
                xs={10}
                xl={10}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Text className="myprojectsProjectName">{project.title}</Text>
                  <Paragraph style={{ color: style.colors.textSoft }}>
                    Created on{" "}
                    {new Date(project.dateCreated).toLocaleDateString("fr")}
                  </Paragraph>
                </div>
              </Col>

              {project.cover?.isUpscaled ? (
                <Col
                  style={{
                    height: 100,
                    display: isMobile ? "none" : "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  className="columnStatus"
                  xs={4}
                  xl={4}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      width: "100%",
                    }}
                  >
                    <CheckOutlined
                      style={{ color: style.colors.color, marginRight: 5 }}
                    ></CheckOutlined>
                    <Paragraph
                      style={{
                        color: style.colors.textSoft,
                        marginRight: 5,
                        marginBottom: 0,
                      }}
                    >
                      READY
                    </Paragraph>
                  </div>
                </Col>
              ) : (
                <Col
                  style={{
                    height: 100,
                    display: isMobile ? "none" : "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  className="columnStatus"
                  xs={4}
                  xl={4}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      width: "100%",
                    }}
                  >
                    <EditOutlined
                      style={{ color: style.colors.textSoft, marginRight: 5 }}
                    ></EditOutlined>
                    <Paragraph
                      style={{
                        color: style.colors.textSoft,
                        marginRight: 5,
                        marginBottom: 0,
                      }}
                    >
                      DRAFT
                    </Paragraph>
                  </div>
                </Col>
              )}

              <Col
                style={{
                  height: 100,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                }}
                xs={8}
                md={6}
                lg={6}
                xl={6}
              >
                {project.cover?.isUpscaled ? (
                  <Popover
                    style={{
                      background: style.colors.background3,
                      backgroundColor: style.colors.background3,
                    }}
                    content={
                      <div
                        style={{
                          backgroundColor: style.colors.background3,
                          width: 200,
                        }}
                      >
                        <Button
                          onClick={(e) => {
                            e.stopPropagation();
                            download(project.cover.upscaledUrl, project.name);
                          }}
                          style={{ width: "100%" }}
                          type="default"
                        >
                          Cover
                        </Button>
                        {project.cover.youtubeImageUrl && (
                          <Button
                            onClick={(e) => {
                              e.stopPropagation();
                              download(
                                project.cover.youtubeImageUrl,
                                project.name
                              );
                            }}
                            style={{ width: "100%" }}
                            type="default"
                          >
                            Youtube
                          </Button>
                        )}
                        {project.cover.instagramImageUrl && (
                          <Button
                            onClick={(e) => {
                              e.stopPropagation();
                              download(
                                project.cover.instagramImageUrl,
                                project.name
                              );
                            }}
                            style={{ width: "100%" }}
                            type="default"
                          >
                            Instagram
                          </Button>
                        )}
                      </div>
                    }
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                    trigger="click"
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                        justifyContent: "flex-end",
                      }}
                    >
                      <Button type="primary" style={{ marginRight: 10 }}>
                        Download
                      </Button>
                      <div
                        style={{
                          height: "100%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <RightArrowIcon style={{ color: style.colors.text }} />
                      </div>
                    </div>
                  </Popover>
                ) : (
                  <div
                    style={{
                      height: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <RightArrowIcon style={{ color: style.colors.text }} />
                  </div>
                )}
              </Col>
            </Row>
          ))}
        </div>
      </div>
    </Layout>
  );
};
