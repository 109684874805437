// useCreateProject.js
import { useCallback } from "react";
import axios from "axios";
import api from "./apiInfo";
import { useAuthStore } from "../store/useAuthStore";
import { useStore } from "../store/useStore";
import { Navigate, useNavigate } from "react-router-dom";
import { axiosInstance } from "./AxiosSetting";
import useCreditCheck from "../hooks/creditChecks";
import { dataURLtoBlob } from "../tools/ImageTools";
import useProjectStore from "../store/useProjectStore";

export const useProjectService = () => {
  const { user, addProjectToUser, updateProject, setArtistName } =
    useAuthStore();
  const { showLoading, hideLoading } = useStore();
  const { setSelectedProject } = useProjectStore();

  const navigate = useNavigate();

  const finishSetup = useCallback(
    async (artistname, callback) => {
      showLoading();
      console.log(user);
      console.log("user", user._id);
      try {
        const url = "finishsetup/";
        const response = await axiosInstance.post(url, {
          name: "New cover",
          artistname: artistname,
          id: user._id,
        });
        console.log("recu", response);
        await addProjectToUser(response.data.project);
        setArtistName(artistname);
        setSelectedProject(response.data.project);
      } catch (error) {
        console.error("Failed to create project:", error);
        hideLoading();
        // Handle the error, e.g. by setting an error message in your state
      } finally {
        callback();
        hideLoading();
      }
    },
    [user, addProjectToUser, showLoading, hideLoading]
  );
  const createProject = useCallback(
    async (projectName, callback) => {
      showLoading();
      console.log(user);
      console.log("user", user._id);
      try {
        const url = "createproject/";
        const response = await axiosInstance.post(url, {
          name: projectName,
          id: user._id,
        });
        console.log("recu", response);
        await addProjectToUser(response.data.project);
        setSelectedProject(response.data.project);
        navigate("/dashboard/project/create", { replace: true });
      } catch (error) {
        console.error("Failed to create project:", error);
        hideLoading();
        // Handle the error, e.g. by setting an error message in your state
      } finally {
        callback();
        hideLoading();
      }
    },
    [user, addProjectToUser, showLoading, hideLoading]
  );
  const updateName = useCallback(
    async (projectid, projectName, callback) => {
      console.log("updating name");
      try {
        const url = "updatename/";
        const response = await axiosInstance.post(url, {
          projectid: projectid,
          projectname: projectName,
        });
        updateProject(response.data.project);
        setSelectedProject(response.data.project);
        console.log("updatedname", response.data.project);
      } catch (error) {
        console.error("Failed to create project:", error);

        // Handle the error, e.g. by setting an error message in your state
      } finally {
        callback();
      }
    },
    [user]
  );

  return { createProject, updateName, finishSetup };
};

export const useGenerateIdeasService = () => {
  const {
    user,
    addProjectToUser,
    addNewIdeas,

    updateProject,
    updateCover,

    deleteIdea,
    setIsGeneratingCover,
    addNewImage,
  } = useAuthStore();
  const { setCover, setSelectedProject, setSelectedImage } = useProjectStore();
  const { showLoading, hideLoading } = useStore();
  const { checkCredit, decrements } = useCreditCheck();
  const navigate = useNavigate();

  const generateIdeas = useCallback(
    async (project, script, callback, errorCallback) => {
      if (!checkCredit()) {
        return;
      }
      console.log("generate Ideas");
      try {
        const url = "generateidea/";
        const response = await axiosInstance.post(url, {
          script: script,
          project_id: project._id,
          userid: user._id,
        });
        console.log("response", response);
        decrements();
        addNewIdeas(project._id, response.data.imageDescriptions);
        // navigate(path);
      } catch (error) {
        errorCallback();
        console.error("Failed to create project:", error);
        //hideLoading();
        // Handle the error, e.g. by setting an error message in your state
      } finally {
        //hideLoading();
        callback();
      }
    },
    [user]
  );

  const createContentAsset = useCallback(
    async (
      project_id,
      platform,
      artistinfo,
      titleinfo,
      textinfo,
      dataURL,

      callbacksuccess,
      callbackerror
    ) => {
      console.log("Create Asset");
      if (!checkCredit()) {
        callbackerror();
        return;
      }
      //generatingImage(project_id, imagedescription_id, true);
      try {
        const blob = await dataURLtoBlob(dataURL);

        // Use FormData to construct the data
        const formData = new FormData();
        formData.append("userid", user._id);
        formData.append("projectid", project_id);
        formData.append("platform", platform);
        formData.append("artistinfo", artistinfo);
        formData.append("titleinfo", titleinfo);
        formData.append("textinfo", textinfo); // Assuming coverJson is an object
        formData.append("image", blob, "image.png"); // Add the Blob
        console.log("heho");
        const url = "createcontentasset/";
        const response = await axiosInstance.post(url, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        decrements();
        console.log("response", response);
        console.log("yeeesss");
        updateCover(response.data.cover, project_id);
        setCover(response.data.cover);
      } catch (error) {
        console.error("Failed to create project:", error);

        //hideLoading();
        // Handle the error, e.g. by setting an error message in your state
      } finally {
        callbacksuccess();
        //hideLoading();
        //callback();
      }
    },
    [user]
  );

  const createCover = useCallback(
    async (project_id, image_id, coverJson, dataURL, callback) => {
      console.log("Create Cover");
      showLoading();
      //generatingImage(project_id, imagedescription_id, true);
      try {
        const blob = await dataURLtoBlob(dataURL);

        // Use FormData to construct the data
        const formData = new FormData();

        formData.append("projectid", project_id);
        formData.append("imageid", image_id);
        formData.append("coverjson", JSON.stringify(coverJson)); // Assuming coverJson is an object
        formData.append("coverimage", blob, "cover-image.png"); // Add the Blob

        const url = "createcover/";
        const response = await axiosInstance.post(url, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        console.log("response", response);
        console.log("yeeesss");
        updateProject(response.data.project);
        setSelectedProject(response.data.project);
        setSelectedImage(response.data.project.cover.imageOfCover);
        callback();
        hideLoading();
      } catch (error) {
        console.error("Failed to create project:", error);
        setIsGeneratingCover(false);
        //hideLoading();
        // Handle the error, e.g. by setting an error message in your state
      } finally {
        setIsGeneratingCover(false);
        //hideLoading();
        //callback();
      }
    },
    [user]
  );

  const generateImages = useCallback(
    async (project_id, inputprompt, callback) => {
      if (!checkCredit()) {
        return;
      }
      console.log("generate Image");
      setIsGeneratingCover(true);
      //generatingImage(project_id, imagedescription_id, true);
      try {
        const url = "generateimage/";
        const response = await axiosInstance.post(url, {
          projectid: project_id,
          inputPrompt: inputprompt,
          userid: user._id,
        });
        decrements();
        console.log("response", response);
        addNewImage(project_id, response.data.image);
        setIsGeneratingCover(false);
        callback(response.data.image);
        //generatingImage(project_id, imagedescription_id, false);
        // navigate(path);
      } catch (error) {
        console.error("Failed to create project:", error);
        setIsGeneratingCover(false);
        //hideLoading();
        // Handle the error, e.g. by setting an error message in your state
      } finally {
        setIsGeneratingCover(false);
        //hideLoading();
        //callback();
      }
    },
    [user]
  );

  const upScaleCover = useCallback(
    async (cover, project_id, callback) => {
      if (!checkCredit()) {
        callback();
        return;
      }

      try {
        const url = "upscale/";
        const response = await axiosInstance.post(url, {
          coverid: cover._id,
          projectid: project_id,
          userid: user._id,
        });

        console.log("response", response);
        updateCover(response.data.cover, project_id);
        setCover(response.data.cover);
        decrements();
        // navigate(path);
      } catch (error) {
        console.error("Failed to create project:", error);
        //hideLoading();
        callback();
        // Handle the error, e.g. by setting an error message in your state
      } finally {
        callback();
      }
    },
    [user]
  );
  const deleteIdeaById = useCallback(
    async (project_id, id) => {
      console.log("deleting idea", id);
      try {
        showLoading();
        const url = "deleteidea/";
        const response = await axiosInstance.post(url, {
          id: id,
        });

        console.log("response", response);
        deleteIdea(project_id, id);
        // navigate(path);
      } catch (error) {
        console.error("Failed to create project:", error);
        //hideLoading();
        // Handle the error, e.g. by setting an error message in your state
      } finally {
        hideLoading();
      }
    },
    [user]
  );

  return {
    generateIdeas,
    deleteIdeaById,
    generateImages,
    createCover,
    createContentAsset,
    upScaleCover,
  };
};
