// theme.js or designTokens.js
const style = {
  /*colors: {
    black: "#09090b",
    background0: "#121317",
    background1: "#1c1c22",
    background2: "#272832",
    text: "#fcfcfc",
    textSoft: "#707070",
    hint: "#21949c",
    // ... other colors
  },*/
  /* colors: {
    black: "#EBEBEB",
    background0: "#E9EBED",
    background1: "#fff",
    background2: "#F8FAFB",
    text: "#121317",
    textInput: "#484C5B",
    textSelected: "#121317",
    textSoft: "#535C65",
    hint: "#21949c",
    color: "#156AE4",
    // ... other colors
  },*/
  colors: {
    black: "#EBEBEB",
    background0: "#121317",
    background1: "#1c1c22",
    //background1: "#1B231A",
    background2: "#272832",
    background3: "#3F4050",
    text: "#fcfcfc",
    textInput: "#707070",
    textInputVisible: "#909090",
    textSelected: "#fcfcfc",
    textSoft: "#707070",
    hint: "#21949c",
    color: "#21B3CD",
    transparent: "rgba(0,0,0,0)",
    // ... other colors
  },
  fonts: {
    primary: '"Helvetica Neue", Helvetica, Arial, sans-serif',
    secondary: '"Comic Sans MS", "Comic Sans", cursive',
    // ... other font families
  },
  fontSizes: {
    small: "0.8rem",
    medium: "1rem",
    large: "1.2rem",
    // ... other font sizes
  },
  // ... other design tokens like spacing, breakpoints etc.
};

export default style;
