import React from "react";
import { Navigate, useOutlet } from "react-router-dom";
import { AppBar } from "./AppBar";
import { useAuth } from "../hooks/useAuth"; // Update the path to the useAuth file accordingly

export const HomeLayout = () => {
  const { user } = useAuth();
  const outlet = useOutlet();

  if (user) {
    return <Navigate to="/dashboard/project" replace />;
  }

  return (
    <div>
      <AppBar
        pages={[
          { label: "Home", path: "/" },
          { label: "Login", path: "/login" },
        ]}
      />
      {outlet}
    </div>
  );
};

export default HomeLayout;
