import React, { useState, useEffect } from "react";
import {
  Button,
  Form,
  Input,
  Row,
  Col,
  Select,
  Typography,
  Divider,
} from "antd"; // Assuming you're using Ant Design for buttons
import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";
import style from "../../Styles/style";
import {
  UpOutlined,
  DownOutlined,
  VerticalAlignMiddleOutlined,
} from "@ant-design/icons";
import GoogleFontsSelect from "./GoogleFontSelect";

const { Option } = Select;
const DraggableList = ({
  items,
  setItems,
  horizontalAlign,
  verticaAlign,
  updateItem,
  reorderLayer,
  removeItem,
}) => {
  const updateItemText = (index, key, value, id) => {
    updateItem(index, key, value, id);
  };

  const handleFontChange = (font, itemId) => {
    // Update the font for the specific item
    const newItems = items.map((item) =>
      item.id === itemId ? { ...item, fontFamily: font } : item
    );
    setItems(newItems);
  };

  const reorder = (array, startIndex, endIndex) => {
    // Clone the array to avoid directly mutating the state
    const result = Array.from(array);
    // Remove the item from its current position
    const [removed] = result.splice(startIndex, 1);
    // Insert the item at its new position
    result.splice(endIndex, 0, removed);
    reorderLayer(startIndex, endIndex);
    // In case you need to reorder the canvas layers, you should call the function responsible for it here.
    // For example:
    // reorderCanvasLayers(startIndex, endIndex);

    return result;
  };

  const toggleDescriptionVisibility = (itemId) => {
    const newItems = items.map((item) =>
      item.id === itemId ? { ...item, isVisible: !item.isVisible } : item
    );
    setItems(newItems);
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    console.log("itemms", items);
    const newItems = reorder(
      items,
      result.source.index,
      result.destination.index
    );
    setItems(newItems);
  };

  const handleDeleteItem = (itemId) => {
    // Logic to handle delete action
    const filteredItems = items.filter((item) => item.id !== itemId);
    removeItem(itemId);
    setItems(filteredItems);
  };
  function handleChangeShadows(index, value, item_id) {
    let shadow;
    if (value) {
      shadow = { color: "rgba(0,0,0,0.3)", blur: 15, offsetX: 0, offsetY: 0 };
    } else {
      shadow = { color: "rgba(0,0,0,0.0)", blur: 15, offsetX: 0, offsetY: 0 };
    }
    console.log(shadow);
    updateItem(index, "shadow", shadow, item_id);
  }
  function handleChangeFont(index, fontCategory, item_id) {
    updateItem(index, "fontCategory", fontCategory, item_id);
  }

  // Add more handlers for different actions if necessary

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {(provided) => (
          <div {...provided.droppableProps} ref={provided.innerRef}>
            {items.map((item, index) => (
              <Draggable key={item.id} draggableId={item.id} index={index}>
                {(provided) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={{
                      ...provided.draggableProps.style,
                      userSelect: "none",

                      margin: "0 0 8px 0",
                      color: "white",

                      border: "0.5px solid lightgrey",
                      borderColor: style.colors.background2,
                      borderRadius: "10px",

                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        backgroundColor: style.colors.background2, // Example color based on the screenshot
                        borderRadius: "6px",
                        color: "white",
                        width: "100%",
                        padding: "16px",
                        marginBottom: "8px", // Add space between items if needed
                      }}
                    >
                      <div
                        style={{
                          height: 10,
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          marginBottom: "0px", // Space between header and form
                        }}
                      >
                        <Typography.Title
                          level={5}
                          style={{
                            // Set the maximum width of the label
                            overflow: "hidden",
                            paddingRight: 15,
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            color: "white",
                            margin: 0,
                          }}
                        >
                          {item.text}
                        </Typography.Title>
                        <div>
                          <Button
                            onClick={() => toggleDescriptionVisibility(item.id)}
                            style={{}} // Space between buttons
                          >
                            {item.isVisible ? <UpOutlined /> : <DownOutlined />}
                          </Button>
                        </div>
                      </div>
                      {item.isVisible && (
                        <Form
                          layout="vertical"
                          style={{
                            width: "100%",
                          }}
                        >
                          <Divider
                            style={{ marginTop: 15, marginBottom: 10 }}
                          />

                          <div key={item.id} style={{ marginBottom: "10px" }}>
                            {/* This container ensures that label and input are on the same line */}
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                marginBottom: "8px",
                              }}
                            >
                              <label
                                style={{
                                  marginRight: "8px",
                                  marginBottom: 0,
                                  flexShrink: 0,
                                }}
                              >
                                Text:
                              </label>
                              <Input
                                placeholder="Enter text"
                                value={item.text}
                                onChange={(e) =>
                                  updateItem(
                                    index,
                                    "text",
                                    e.target.value,
                                    item.id
                                  )
                                }
                                style={{
                                  backgroundColor: "#1A1A1A",
                                  color: "white",
                                  flexGrow: 1, // Input takes the rest of the space
                                }}
                              />
                            </div>

                            <GoogleFontsSelect
                              category={item.fontCategory || "All"}
                              font={item.fontFamily}
                              onFontChange={(font) =>
                                updateItem(index, "fontFamily", font, item.id)
                              }
                            />

                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                marginBottom: "8px",
                              }}
                            >
                              <label
                                style={{
                                  marginRight: "8px",
                                  marginBottom: 0,
                                  flexShrink: 0,
                                }}
                              >
                                Color:
                              </label>
                              <div style={{ display: "flex", flexGrow: 1 }}>
                                <Input
                                  onChange={(e) =>
                                    updateItem(
                                      index,
                                      "fill",
                                      e.target.value,
                                      item.id
                                    )
                                  }
                                  type="color"
                                  value={item.fill}
                                  style={{ width: "30%", marginRight: "8px" }}
                                />
                                <Input
                                  value={item.fill}
                                  style={{ width: "70%" }}
                                />
                              </div>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",

                                marginBottom: "8px",
                                width: "100%",
                              }}
                            >
                              <label
                                style={{
                                  marginRight: "8px",
                                  marginBottom: 0,
                                  flexShrink: 0,
                                }}
                              >
                                Shadows:
                              </label>
                              <Select
                                defaultValue="Soft"
                                style={{ flexGrow: 1 }} // Adjust this to use only flexGrow
                                onChange={(value) =>
                                  handleChangeShadows(index, value, item.id)
                                }
                              >
                                <Option value={false}>None</Option>
                                <Option value={true}>Soft</Option>
                              </Select>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",

                                marginBottom: "8px",
                              }}
                            >
                              <label
                                style={{
                                  marginRight: "8px",
                                  marginBottom: 0,
                                  flexShrink: 0,
                                }}
                              >
                                Alignment:
                              </label>

                              <Button
                                style={{ height: "100%", flexGrow: 1 }}
                                icon={<VerticalAlignMiddleOutlined />}
                                onClick={() => {
                                  verticaAlign(item.id);
                                }}
                              ></Button>
                              <Button
                                style={{ height: "100%", flexGrow: 1 }}
                                icon={
                                  <VerticalAlignMiddleOutlined rotate={90} />
                                }
                                onClick={() => {
                                  console.log("horizontal", item.id);
                                  horizontalAlign(item.id);
                                }}
                              ></Button>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                                width: "100%",
                                marginTop: 40,
                              }}
                            >
                              <Button
                                danger
                                size="small"
                                onClick={() => handleDeleteItem(item.id)}
                                style={{ alignSelf: "flex-start" }}
                              >
                                Delete
                              </Button>
                            </div>
                          </div>
                        </Form>
                      )}
                    </div>
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default DraggableList;
