// axiosInstance.js
import axios from "axios";
import api from "./apiInfo";
import { useAuthStore } from "../store/useAuthStore";
import { useStore } from "../store/useStore";

// import other necessary modules (e.g., store or utilities for refresh token)
const { showModal } = useStore.getState();
const axiosInstance = axios.create({
  baseURL: api.url,
  // other custom settings
});
axiosInstance.interceptors.request.use(
  (config) => {
    const { accessToken } = useAuthStore.getState();
    // Implement this function to retrieve the access token
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
// Response Interceptor to handle token expiration
axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    if (error.response.status === 405) {
      console.log("interceptor");
      showModal(
        <p>
          Sorry you have no credit left, purchase some credits if you want to
          continue
        </p>,
        {
          title: "Oops, you have no credit left",
          isInfoModal: true,
          okText: "Ok",
          onOk: () => console.log("Info OK clicked"),
        }
      );
    }

    // Check for token expiration (401 status)
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      try {
        const { refreshToken, user } = useAuthStore.getState();
        let obj = {};
        obj.refreshToken = refreshToken;
        obj.email = user.email;
        console.log("call refreshtoken");
        // Implement this function to retrieve the refresh token
        const response = await axiosInstance.post("/refreshtoken", obj);
        console.log("response", response);
        let newAccessToken = response.data.accessToken;
        let newRefreshToken = response.data.refreshToken; // Implement refreshAccessToken to get a new token
        setAccessAndRefreshTokens(newAccessToken, newRefreshToken);
        return axiosInstance(originalRequest);
      } catch (refreshError) {
        // Handle errors (e.g., redirect to login)
        return Promise.reject(refreshError);
      }
    }

    return Promise.reject(error);
  }
);

const setAccessAndRefreshTokens = (accessToken, refreshToken) => {
  const { setTokens } = useAuthStore.getState();
  console.log("access", accessToken);
  console.log("refresh", refreshToken);
  setTokens(accessToken, refreshToken);
  axiosInstance.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${accessToken}`;
};

export { axiosInstance, setAccessAndRefreshTokens };
