import React, { useEffect, useState } from "react";
import { fabric } from "fabric";
import { Avatar, Card, Input, Typography } from "antd";
import "./thumbnailcard.css"; // Import your CSS file here
import { UserOutlined } from "@ant-design/icons";
import { FabricJSCanvas, useFabricJSEditor } from "fabricjs-react";
const { Meta } = Card;
const { Title, Text } = Typography;
const googlefontkey = "AIzaSyBY_ANk4B5C3WbyRej - s61taffvTiBSiB8";
const ThumbnailCard = ({
  videoTitle,
  channelName,
  url,
  viewCount,
  timeAgo,
  videoLength,
}) => {
  const { editor, onReady } = useFabricJSEditor();
  const [texte, setTexte] = useState("title thhhh");
  const [copiedtexte, setcopiedTexte] = useState("title thhhh");
  const txt = new fabric.Textbox(texte, {
    fontSize: 20,
    fontWeight: "bold",
    fill: "white",
    top: 100,
    left: 100,
    id: "okfokef",
  });
  let imageAdded = false;
  const handleInputChange = (e) => {
    setTexte(e.target.value);
    const canvasTextbox = editor.canvas.getObjects("textbox")[0];
    console.log(canvasTextbox);
    canvasTextbox.text = e.target.value;
    editor?.canvas.renderAll();
  };

  useEffect(() => {
    if (!url || !editor) {
      return;
    }
    editor?.canvas.clear();
    editor?.canvas.setHeight(300);

    fabric.Image.fromURL(url, function (oImg) {
      oImg.scaleToHeight(200);
      oImg.scaleToWidth(editor.canvas.width);
      oImg.lockMovementX = false;
      oImg.lockMovementY = true;
      oImg.lockUniScaling = true;
      oImg.lockScalingY = true;
      oImg.lockScalingX = true;
      oImg.selectable = false;
      editor?.canvas.add(oImg);
      oImg.sendToBack();

      var snapZone = 15;
    });
    // Add text to canvas
    editor.canvas.add(txt);
    txt.bringToFront();
    /* editor.canvas.item(1).set({
      borderColor: "gray",
      cornerColor: "black",
      cornerSize: 12,
      transparentCorners: false,
    });*/
    // editor.canvas.setActiveObject(editor.canvas.item(1));
    // Set up event listener for text change
    editor?.canvas.on("text:changed", function (e) {
      //this will only work using the dev build

      console.log(e.target.text);
      setTexte(e.target.text);
      /* editor?.canvas.on("object:moving", function (options) {
    var objectMiddle =
      options.target.left + options.target.getWidth() / 2;
    if (
      objectMiddle > editor?.canvas.width / 2 - snapZone &&
      objectMiddle < editor?.canvas.width / 2 + snapZone
    ) {
      options.target
        .set({
          left: editor?.canvas.width / 2 - options.target.getWidth() / 2,
        })
        .setCoords();
    }
  });*/
      //console.log("Text changed:", e.target.text);
    });
    // Create a new text object and add it to the canvas
  }, [url]);

  return (
    <div>
      <Input value={texte} onChange={handleInputChange}></Input>
      <FabricJSCanvas onReady={onReady} />
      <Meta
        title={
          <Title level={5} style={{ color: "white" }}>
            {videoTitle}
          </Title>
        }
        description={
          <div style={{ display: "flex", alignItems: "center" }}>
            <div>
              <Avatar
                icon={<UserOutlined />}
                style={{ backgroundColor: "#f56a00", marginRight: "8px" }}
              />
            </div>
            <div>
              <Text style={{ color: "white" }}>
                {channelName ? channelName : "Channel"}
              </Text>
              <br />
              <Text style={{ color: "white" }}>{`${
                viewCount ? viewCount : "30k de vues"
              } • ${timeAgo ? timeAgo : "il y a 7 jours"}`}</Text>
            </div>
          </div>
        }
        style={{
          position: "relative",
          bottom: "8px",
          left: "8px",
          color: "white",
        }}
      />
      {/*<div
        className="thumbnail-card"
        style={{ position: "relative", width: "fit-content" }}
      >
   
        <span
          className="video-length"
          style={{
            position: "absolute",
            bottom: "8px",
            right: "8px",
            backgroundColor: "rgba(0, 0, 0, 0.75)",
            color: "white",
            padding: "2px 4px",
            borderRadius: "2px",
          }}
        >
          {videoLength ? videoLength : "20:30"}
        </span>
      </div>

      <Meta
        title={
          <Title level={5} style={{ color: "white" }}>
            {videoTitle}
          </Title>
        }
        description={
          <div style={{ display: "flex", alignItems: "center" }}>
            <div>
              <Avatar
                icon={<UserOutlined />}
                style={{ backgroundColor: "#f56a00", marginRight: "8px" }}
              />
            </div>
            <div>
              <Text style={{ color: "white" }}>
                {channelName ? channelName : "Channel"}
              </Text>
              <br />
              <Text style={{ color: "white" }}>{`${
                viewCount ? viewCount : "30k de vues"
              } • ${timeAgo ? timeAgo : "il y a 7 jours"}`}</Text>
            </div>
          </div>
        }
        style={{
          position: "relative",
          bottom: "8px",
          left: "8px",
          color: "white",
        }}
      />*/}
    </div>
  );
};

export default ThumbnailCard;
