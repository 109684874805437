import create from "zustand";
import { persist } from "zustand/middleware";

const useProjectStore = create((set) => ({
  selectedProject: null,
  isEditingCover: false,
  setIsEditingCover: (edit) => {
    set({ isEditingCover: edit });
  },
  isCreatingCover: false,
  setIsCreatingCover: (edit) => {
    set({ isCreatingCover: edit });
  },
  setHasCover: (change) =>
    set((state) => {
      return {
        selectedProject: { ...state.selectedProject, hasCover: change },
      };
    }),
  setSelectedProject: (project) => {
    set((state) => ({
      selectedProject: project,
      projectName: project.title,
      tempSelectedImage: project.images[project.images.length - 1],
      selectedImage: project.hasCover
        ? project.cover.imageOfCover
        : project.images[project.images.length - 1],
      actualPrompt:
        Array.isArray(project.images) && project.images.length > 0
          ? project.images[project.images.length - 1].prompt
          : "",
      instagramImage: project.cover?.instagramImageUrl,
      youtubeImage: project.cover?.youtubeImageUrl,
      cover: project.cover,
  
      // isModifyingScript: Array.isArray(project.inputPrompts) && project.inputPrompts.length === 0,
    }));
  },
  cover: null,
  setCover: (cov) => {
    set({
      cover: cov,
      youtubeImage: cov.youtubeImageUrl,
      instagramImage: cov.instagramImageUrl,
    });
  },
  selectedImage: null,
  setSelectedImage: (image) => {
    console.log(image);
    set({ selectedImage: image, actualPrompt: image.prompt });
  },
  youtubeImage: null,
  setYoutubeImage: (image) => {
    console.log(image);
    set({ youtubeImage: image });
  },
  instagramImage: null,
  setInstagramImage: (image) => {
    console.log(image);
    set({ instagramImage: image });
  },
  tempSelectedImage: null,
  setTempSelectedImage: (image) => {
    console.log(image);
    set({ tempSelectedImage: image, actualPrompt: image.prompt });
  },
  setProjectName: (name) => {
    set({ projectName: name });
  },
  projectName: "",
  actualPrompt: "",
  setActualPrompt: (value) => set({ actualPrompt: value }),
  /* scriptValue: "",
  setScriptValue: (value) => set({ scriptValue: value }),
  isModifyingScript: false, // Corrected name
  setIsModifyingScript: (value) => set({ isModifyingScript: value }), // Corrected name*/
}));

export default useProjectStore;
