import React, { useState, useEffect, useRef } from "react";
import { Link, Navigate, useOutlet } from "react-router-dom";
import { AppBar } from "./AppBar";
import { Row, Col, Layout, Space, Button, Menu, Typography } from "antd";
import { useAuth } from "../hooks/useAuth"; // Update the path to the useAuth file accordingly
import style from "../Styles/style";
import { PlusOutlined } from "@ant-design/icons";
import { useAuthStore } from "../store/useAuthStore";
import useGlobalModal from "../components/Modal/useModalGlobal";
import { refetchUser } from "../services/loginService";

const { Title, Paragraph } = Typography;
const { Header, Footer, Sider, Content } = Layout;
export const ProtectedLayout = () => {
  const { user, setUser } = useAuthStore();
  const { logout } = useAuth();

  const outlet = useOutlet();
  const { showModal, GlobalModal } = useGlobalModal();

  if (!user) {
    return <Navigate to="/" />;
  }

  if (!user.artistName) {
    return <Navigate to="/intro" />;
  }
  //logout();
  const fullHeightStyle = {
    height: "100vh", // 100% of the viewport height
    display: "flex",
    flexDirection: "column",
    backgroundColor: style.colors.background0,
  };

  return (
    <div>
      <GlobalModal />
      <AppBar
        pages={[
          { label: "My covers", path: "project" },
          { label: "Buy credit", path: "buy-credits" },

          { label: "Login", path: "login" },
        ]}
      />
      <Layout style={fullHeightStyle}>
        {" "}
        {/* Apply the style here */}
        <Layout style={{ flex: 1, backgroundColor: style.colors.background0 }}>
          {" "}
          {/* Ensure flex grow is set for inner layout 
          <Sider
            style={{
              paddingTop: 74,
              height: "100vh",
              position: "fixed",
              paddingLeft: 15,
              paddingRight: 15,
              backgroundColor: style.colors.background,
            }}
          >
            <Space
              direction="vertical"
              style={{
                width: "100%",
                padding: "10px",
                backgroundColor: style.colors.background,
              }}
            >
              <Row align="middle" justify="space-between">
                <Col>
                  <Title level={5} style={{ margin: 0, lineHeight: "32px" }}>
                    My projects
                  </Title>
                </Col>
                <Col>
                  <Button
                    type="primary"
                    shape="circle"
                    icon={<PlusOutlined />}
                    size="small" // Set the size to small
                    onClick={handleAddProject}
                  />
                </Col>
              </Row>
            </Space>
            <Menu mode="inline" style={{ height: "100%", borderRight: 0 }}>
              {user.projects.map((project) => (
                <Menu.Item
                  key={project._id}
                  onClick={() => handleSelectProject(project._id)}
                >
                  {project.name}
                </Menu.Item>
              ))}
            </Menu>
          </Sider>
          <Layout style={{ paddingLeft: "200px" }}>
            {" "}
         */}
          <Content
            style={{
              backgroundColor: style.colors.background0,
              minHeight: "280px",
              paddingTop: 64,
            }}
          >
            {outlet}
          </Content>
          {/*</Layout>        */}
        </Layout>
      </Layout>
    </div>
  );
};

export default ProtectedLayout;
