import React, { useState, useEffect } from "react";
import {
  Layout,
  Typography,
  Row,
  Col,
  Drawer,
  Button,
  Modal,
  Popover,
  message,
} from "antd";

import { useNavigate, useLocation } from "react-router-dom";
import { MenuOutlined, CloseOutlined, GoogleOutlined } from "@ant-design/icons";
import { useAuth } from "../hooks/useAuth";
import style from "../Styles/style";
import { GoogleLogin, useGoogleLogin } from "@react-oauth/google";
import { useStore } from "../store/useStore";
import UserCredits from "../components/navbar/usercredits";
import { useAuthStore } from "../store/useAuthStore";
import mixpanel from "mixpanel-browser";

const { Header } = Layout;
const { Title } = Typography;
export const AppBar = ({ pages }) => {
  const [drawerVisible, setDrawerVisible] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const currentPath = location.pathname;
  const { user, login, loginCustom, logout, fakelogin } = useAuth();
  const { decrementCredits } = useAuthStore();
  const { isLoginModalVisible, showLoginModal, hideLoginModal } = useStore();
  const { showLoading, hideLoading } = useStore();
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  // ... other state and hooks ...
  function cancelLogin() {
    hideLoginModal();
    mixpanel.track("Close login modal");
  }
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Call it once to set initial state based on current window size

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const googleLogin = useGoogleLogin({
    flow: "implicit", // or 'auth-code' depending on the flow you need
    scope: "openid email profile",
    onSuccess: (tokenResponse) => {
      mixpanel.track("Google Login Success");
      // The tokenResponse should contain the ID token if configured correctly
      hideLoginModal();
      showLoading();
      // The ID token is usually found in the id_token field

      // Send the ID token to your server for verification and processing
      loginCustom({
        token: tokenResponse.access_token,
      });
    },
    onError: (error) => {
      mixpanel.track("Google Login Error");
      console.log("Login Failed:", error);
    },
    // Other necessary configurations...
  });
  const handleGoogleLoginClick = () => {
    // Track the button click event
    mixpanel.track("Google Login Button");

    // Call the googleLogin function
    googleLogin();
  };
  const handleLogin = async (googleData) => {
    // Here you can use googleData to authenticate the user in your backend
    // and log them in your app with the login function from useAuth hook.
    console.log(googleData);
    console.log(googleData.credential);
    login({
      token: googleData.credential, // or whatever token your backend requires
    });
  };

  const handleNavClick = (path, label) => {
    mixpanel.track("Navbar clicked" + label);
    setDrawerVisible(false);

    if (label === "Logout") {
      logout();
    } else if (label === "Login") {
      // Instead of navigating, show the login modal
      showLoginModal();
    } else {
      // Navigate only if the path is different from the current path
      if (path !== currentPath) {
        navigate(path);
      }
    }
  };

  const toggleDrawer = () => {
    setDrawerVisible(!drawerVisible);
  };
  const decrement = () => {
    decrementCredits();
  };
  const navigationLinks = pages?.map((page) => {
    // Determine the label based on user's login status and the page label
    let label = page.label;
    if (page.label === "Login" && user) {
      label = "Logout";
      if (isMobile) {
        return (
          <div>
            <UserCredits
              style={{
                marginLeft: 10,
                marginTop: 30,
                height: 25,
                backgroundColor: style.colors.background2,
              }}
              userName={"azdzadzadazdzadzadazdzad"}
              credits={user.credits}
            />
            <Button
              type="primary"
              onClick={() => {
                logout();
              }}
              style={{ marginTop: 30, width: "100%" }}
            >
              Logout
            </Button>
          </div>
        );
      }
      return (
        <Popover
          placement="bottom"
          style={{ backgroundColor: style.colors.background2 }}
          content={
            <div style={{ width: 200, padding: 5 }}>
              <Button
                onClick={() => {
                  logout();
                }}
                style={{ width: "100%" }}
                type="default"
              >
                Logout
              </Button>
            </div>
          }
          trigger="click"
        >
          <div
            onMouseOver={(e) => {
              e.currentTarget.style.cursor = "pointer"; // Change cursor to indicate hover
            }}
          >
            <UserCredits
              style={{
                marginLeft: 10,
                height: 25,
                backgroundColor: style.colors.background2,
              }}
              userName={"azdzadzadazdzadzadazdzad"}
              credits={user.credits}
            />
          </div>
        </Popover>
      );
    }

    if (isMobile && page.label === "Login") {
      console.log("passed");
      return (
        <Button
          style={{
            height: 50,
            borderRadius: 30,
            fontWeight: "bold",
            background: style.colors.color,
          }}
          onClick={() => handleGoogleLoginClick()}
        >
          <GoogleOutlined></GoogleOutlined>Sign in with Google
        </Button>
      );
    }

    const path = user ? "/dashboard/" + page.path : page.path;
    return (
      <Typography.Link
        key={page.key}
        onClick={() => handleNavClick(page.path, label)} // Pass the possibly modified label
        style={{
          color:
            currentPath === path
              ? style.colors.color
              : style.colors.textSelected,
          backgroundColor:
            currentPath === path ? style.colors.background2 : "transparent",
          padding: "0 16px",
          fontWeight: currentPath === path ? "bold" : "normal",
          display: "inline-block",
          lineHeight: "44px",
          borderRadius: 20,
          marginTop: 5,
          marginBottom: 5,
        }}
      >
        {label}
      </Typography.Link>
    );
  });

  return (
    <Header
      style={{
        boxShadow: "0 0px 30px 0 rgba(0,0,0,.6)",

        position: "fixed",
        zIndex: 3,
        width: "100%",
        height: "64px", // Adjust height as needed
        lineHeight: "64px", // Align text vertically
        padding: "0 50px", // Adjust horizontal padding as needed
        margin: 0, // Ensure there's no margin
      }}
      className="header"
    >
      <Modal
        visible={isLoginModalVisible}
        onCancel={cancelLogin}
        footer={
          [
            // Customize modal footer as needed, e.g.:
            // Add other buttons like "Login" here if necessary
          ]
        }
      >
        {/* The content of the login modal, e.g., a login form */}
        <div
          style={{
            display: "flex",
            padding: 0,
            flexDirection: "column",
            alignItems: "center",
            height: 200,
          }}
        >
          <Title style={{ marginBottom: 10 }} level={2}>
            Login to start.
          </Title>
          <p
            style={{
              fontWeight: 500,
              marginTop: 0,
              marginBottom: 30,
              textAlign: "center",
            }}
          >
            Choose one of the solution below to connect :
          </p>

          <Button
            style={{
              height: 50,
              borderRadius: 30,
              fontWeight: "bold",
              background: style.colors.color,
            }}
            onClick={() => handleGoogleLoginClick()}
          >
            <GoogleOutlined></GoogleOutlined>Sign in with Google
          </Button>
        </div>
      </Modal>
      <Row justify="space-between" align="middle" style={{ height: "44px" }}>
        <Col>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              height: "100%",
              alignContent: "center",
            }}
          >
            <img
              src="/logotonalpixel.png"
              alt="TonalPixel Logo"
              style={{
                paddingTop: 5,
                maxHeight: "40px", // Adjust the size as needed
                cursor: "pointer",
              }}
              onClick={() => handleNavClick("/")}
            />
          </div>
          {/* <Typography.Title
            level={3}
            style={{
              marginTop: 0,
              color: style.colors.text,
              lineHeight: "inherit",

              marginBottom: 0,
            }} // Use inherit to align text vertically
            onClick={() => handleNavClick("/")}
          >
            TonalPixel
          </Typography.Title>*/}
        </Col>
        <Col>
          <div className="navbar-desktop" style={{ color: style.colors.text }}>
            {navigationLinks}
          </div>
          <Button
            className="navbar-mobile"
            type="primary"
            onClick={toggleDrawer}
          >
            <MenuOutlined />
          </Button>
        </Col>
      </Row>
      <Drawer
        title="Menu"
        placement="right"
        closable={true}
        onClose={toggleDrawer}
        style={{ backgroundColor: style.colors.background0 }}
        visible={drawerVisible}
        closeIcon={<CloseOutlined />}
        headerStyle={{ padding: "16px" }} // Adjust padding for the drawer header as needed
      >
        <div
          style={{
            backgroundColor: style.colors.background0,
            color: style.colors.text,
            display: "flex",
            flexDirection: "column",
          }}
        >
          {navigationLinks}
        </div>
      </Drawer>
    </Header>
  );
};
