import React, { useState, useEffect, useRef } from "react";
import {
  Layout,
  Modal,
  Typography,
  Input,
  Button,
  Card,
  Skeleton,
  List,
  Space,
  Tag,
  Divider,
  Row,
  Col,
} from "antd";
import {
  PlusOutlined,
  CloseOutlined,
  CheckOutlined,
  DownloadOutlined,
  FileTextOutlined,
  EditOutlined,
} from "@ant-design/icons";
import { motion } from "framer-motion"; // Ensure you have 'framer-motion' installed
import style from "../Styles/style";
import { useGenerateIdeasService } from "../services/projectService";
import { useStore } from "../store/useStore";
import useProjectStore from "../store/useProjectStore";
import ImageDescriptionCarousel from "./ProjectComponents/ImageDescriptionsCarousel";
import TextArea from "antd/es/input/TextArea";
import ThumbnailCard from "./ProjectComponents/ThumbnailCard";
import ThumbnailEditor from "./ProjectComponents/ThumbnailEditor";
import DraggableList from "./ProjectComponents/DraggableList";

const { Content } = Layout;
const { Title, Paragraph } = Typography;
const { CheckableTag } = Tag;

const ProjectComponent = () => {
  const { showModal } = useStore();
  const tagsData = ["Cartoon", "Realistic", "Cinematic", "Sports"];
  const [selectedTags, setSelectedTags] = useState([]);
  const scriptValue = useProjectStore((state) => state.scriptValue);
  const setScriptValue = useProjectStore((state) => state.setScriptValue);
  const selectedProject = useProjectStore((state) => state.selectedProject);
  const [isGeneratingIdeas, setisGeneratingIdeas] = useState(false);
  const isModifyingScript = useProjectStore((state) => state.isModifyingScript);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentImage, setCurrentImage] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const setIsModifyingScript = useProjectStore(
    (state) => state.setIsModifyingScript
  );
  const [isLoadingAnIdea, setisLoadingAnIdea] = useState(false);
  const { generateIdeas, deleteIdeaById, generateImages } =
    useGenerateIdeasService();
  const handleTagChange = (tag, checked) => {
    const nextSelectedTags = checked
      ? [...selectedTags, tag]
      : selectedTags.filter((t) => t !== tag);
    console.log("You are interested in: ", nextSelectedTags);
    setSelectedTags(nextSelectedTags);
  };
  const handleChange = (e) => {
    setScriptValue(e.target.value);
  };

  const selectImage = (imgUrl) => {
    setSelectedImage(imgUrl);
  };

  const isImageSelected = (imgUrl) => {
    return selectedImage === imgUrl;
  };
  const onClickGenerateIdeas = () => {
    if (scriptValue === null || scriptValue === "") {
      showModal(
        <p>
          In order to generate thumbnail ideas, you have to enter a script for
          your video
        </p>,
        {
          title: "Script is empty",
          isInfoModal: true,
          okText: "Ok",
          onOk: () => console.log("Info OK clicked"),
        }
      );
      return;
    }
    setisGeneratingIdeas(true);
    setisLoadingAnIdea(true);
    generateIdeas(selectedProject, scriptValue, () => {
      setisLoadingAnIdea(false);
      setisGeneratingIdeas(false);
    });
  };
  const showImageModal = (img) => {
    setCurrentImage(img.url);
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const validateScript = () => {
    if (isModifyingScript && (scriptValue === null || scriptValue === "")) {
      showModal(
        <p>
          In order to generate thumbnail ideas, you have to enter a script for
          your video
        </p>,
        {
          title: "Script is empty",
          isInfoModal: true,
          okText: "Ok",
          onOk: () => console.log("Info OK clicked"),
        }
      );
      return;
    }
    setIsModifyingScript(false);
    setisGeneratingIdeas(true);
    setisLoadingAnIdea(true);
    generateIdeas(selectedProject, scriptValue, () => {
      setisLoadingAnIdea(false);
      setisGeneratingIdeas(false);
    });
  };

  const modifyScript = () => {
    setIsModifyingScript(true);
  };

  const handleDelete = (id) => {
    // Implement your delete logic here
    showModal(<p>Are you sure you want to delete this idea of thumbnail ?</p>, {
      title: "Detele",
      isInfoModal: false,
      okText: "Yes, delete",

      onOk: () => {
        deleteIdeaById(selectedProject._id, id);
      },
    });
    console.log(`Deleting item with id: ${id}`);
    // For example, update state or make an API call to delete the item
  };

  const onClickGenerateImag = (id) => {
    generateImages(selectedProject._id, id, () => {});
  };
  function downloadImage(url) {
    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        const blobUrl = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = blobUrl;
        link.download = "Download.jpg";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(blobUrl);
      })
      .catch((e) => console.error("Download failed", e));
  }

  return (
    <Layout
      style={{
        backgroundColor: style.colors.background0,
      }}
    >
      <Content
        style={{
          padding: "24px",

          minHeight: "280px",
        }}
      >
        <Layout
          style={{
            backgroundColor: style.colors.background0,
            paddingLeft: "350px",

            paddingRight: 150,
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <p
              style={{
                color: style.colors.textInput,
                marginBottom: 0,
                marginRight: 8, // Adjust space between titles
                alignSelf: "center", // Align self center in flex container
              }}
            >
              Projects /
            </p>
            <p
              style={{
                color: style.colors.color,
                marginBottom: 0,
                marginRight: 0, // Adjust space between titles
                alignSelf: "center", // Align self center in flex container
              }}
            >
              {selectedProject ? `${selectedProject.name}` : "Select a project"}
            </p>
          </div>
          <Layout
            style={{
              borderColor: style.colors.background3,
              backgroundColor: style.colors.background0,
              marginTop: 20,
              borderRadius: 10,
              paddingTop: 30,
              paddingBottom: 30,
            }}
          >
            <Divider orientation="left">
              <Title level={3} style={{ marginTop: 0, fontSize: "1.5em" }}>
                What is your video about?
              </Title>
            </Divider>
            <div
              style={{
                display: "flex",
                justifyContent: "center", // Center children horizontally
                alignItems: "center", // Center children vertically
                height: "100%", // Set to the desired height, 100% to fill the parent container
              }}
            >
              {/* Inner container that needs to be centered */}
              <div
                style={{
                  position: "relative",
                  width: "100%",
                  display: "flex",
                }}
              >
                <Input.TextArea
                  autoFocus={true}
                  value={scriptValue}
                  readOnly={!isModifyingScript}
                  onChange={handleChange}
                  placeholder="Add a summary of your video here"
                  autoSize={{ minRows: 1, maxRows: 6 }}
                  style={{
                    color: style.colors,
                    backgroundColor: style.colors.background1,
                    borderColor: isModifyingScript
                      ? style.colors.color
                      : style.colors.background2,
                    boxShadow: "5px 8px 24px 5px rgba(0, 0,0, 0.6)",
                    marginTop: 16,
                    paddingTop: 27,
                    paddingBottom: 20,
                    paddingLeft: 15,
                    paddingRight: 215, // Make room for the button
                    width: "100%", // Ensure it takes the full width of its parent
                  }}
                />
                <Paragraph
                  style={{
                    fontSize: 11,
                    color: style.colors.textSoft,
                    position: "absolute",
                    color: style.colors.color,
                    top: 27,
                    left: 15,
                    zIndex: 1,
                  }}
                >
                  Script :
                </Paragraph>

                {isModifyingScript ? (
                  <Button
                    onClick={validateScript}
                    type="primary"
                    style={{
                      position: "absolute",
                      backgroundColor: isModifyingScript
                        ? style.colors.color
                        : style.colors.background3,
                      marginTop: 37,
                      right: 10,
                      width: 150,
                      zIndex: 1,
                    }}
                  >
                    <div>
                      <span>Validate Script</span>
                    </div>
                  </Button>
                ) : (
                  <Button
                    onClick={modifyScript}
                    type="primary"
                    style={{
                      position: "absolute",

                      backgroundColor: isModifyingScript
                        ? style.colors.color
                        : style.colors.background3,
                      marginTop: 37,
                      right: 10,
                      width: 100,
                      zIndex: 1,
                    }}
                  >
                    <div>
                      <span>Modify</span>
                    </div>
                  </Button>
                )}
              </div>
            </div>
          </Layout>

          {selectedProject &&
            selectedProject.imageDescriptions &&
            selectedProject.imageDescriptions.length > 0 && (
              <div style={{ marginTop: 15 }}>
                <div
                  style={{
                    display: "flex",

                    alignItems: "center",
                  }}
                >
                  <Divider orientation="left">
                    <Title
                      level={3}
                      style={{
                        marginTop: 0,
                        fontSize: "1.5em",
                        marginBottom: 0,
                      }} // Removed marginTop and added marginBottom
                    >
                      Ideas of thumbnail
                    </Title>
                  </Divider>
                </div>
              </div>
            )}

          {selectedProject && selectedProject.imageDescriptions && (
            <ImageDescriptionCarousel
              imageDescriptions={selectedProject.imageDescriptions}
              onClickGenerateImag={onClickGenerateImag}
            />
          )}

          {selectedProject &&
            selectedProject.images &&
            selectedProject.images.length > 0 && (
              <Divider orientation="left">
                <Title
                  level={3}
                  style={{
                    marginTop: 0,
                    fontSize: "1.5em",
                    marginBottom: 0,
                  }} // Removed marginTop and added marginBottom
                >
                  Background images
                </Title>
              </Divider>
            )}
          <Row gutter={24}>
            {selectedProject?.images?.map((img) => (
              <div
                style={{
                  width: 300,
                  margin: "10px",
                  position: "relative", // For absolute positioning
                }}
              >
                <img
                  src={img.url}
                  alt={`Image ${img.id}`}
                  style={{
                    borderRadius: 10,
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                  }}
                  onClick={() => selectImage(img.url)}
                />
                <div style={{ position: "absolute", top: 10, right: 10 }}>
                  <Button
                    shape="circle"
                    icon={<DownloadOutlined />}
                    onClick={(e) => {
                      downloadImage(img.url);
                    }}
                    style={{ border: "none" }}
                  />
                </div>
                <div
                  style={{
                    position: "absolute",
                    bottom: 10,
                    left: 10,
                  }}
                >
                  <Button
                    onClick={(e) => {
                      selectImage(img.url);
                    }}
                    type={isImageSelected(img.url) ? "default" : "primary"}
                  >
                    {isImageSelected(img.url) ? "Selected" : "Select"}
                  </Button>
                </div>
              </div>
            ))}
          </Row>
          <Modal
            visible={isModalVisible}
            footer={null} // No footer buttons needed
            onCancel={handleCancel}
            bodyStyle={{ padding: 0 }} // Remove default padding from modal body
          >
            {currentImage && (
              <img
                src={currentImage}
                alt="Full size"
                style={{
                  width: "100%",
                  height: "auto", // Maintain aspect ratio
                  maxHeight: "90vh", // Limit the height to 90% of the viewport height
                }}
              />
            )}
          </Modal>
        </Layout>
        <Layout
          style={{
            marginTop: 20,
            paddingLeft: 200,
            backgroundColor: style.colors.background0,
          }}
        >
          <Card
            title="Thumbnail Editor"
            style={{
              textAlign: "center",
              backgroundColor: style.colors.background1,
            }}
          >
            {/*<div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Divider>
                <Title
                  level={3}
                  style={{
                    marginTop: 0,
                    fontSize: "1.5em",
                    marginBottom: 0,
                  }} // Removed marginTop and added marginBottom
                >
                  Thumbnail Editor
                </Title>
              </Divider>
                </div>*/}
            <ThumbnailEditor url={selectedImage}></ThumbnailEditor>
          </Card>
        </Layout>
      </Content>
    </Layout>
  );
};

export default ProjectComponent;
