import React, { useState, useEffect, useRef } from "react";
import { Layout, Typography, Input, Button, Avatar } from "antd";
const { Title } = Typography;
export const TypingText = ({ text, onComplete, style, className }) => {
  const [index, setIndex] = useState(0);
  const [blink, setBlink] = useState(true);
  const [showInput, setShowInput] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const updateDimensions = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    if (index < text.length) {
      const timeoutId = setTimeout(
        () => setIndex((prevIndex) => prevIndex + 1),
        1100 / text.length
      );
      return () => clearTimeout(timeoutId);
    } else {
      if (index === text.length) {
        onComplete();
      }
      const timeoutId = setTimeout(() => {
        setBlink((prev) => !prev);
      }, 500);
      return () => clearTimeout(timeoutId);
    }
  }, [index, text.length, onComplete]);

  useEffect(() => {
    if (index === text.length) {
      setTimeout(() => setShowInput(true), 1000); // Delay the input field reveal
    }
  }, [index, text.length]);
  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);
  const getResponsiveStyle = () => {
    if (windowWidth < 600) {
      // Mobile styles
      return { fontSize: "16px", lineHeight: "24px" };
    } else if (windowWidth < 1024) {
      // Tablet styles
      return { fontSize: "18px", lineHeight: "24px" };
    } else {
      // Desktop styles
      return { fontSize: "22px", lineHeight: "28px" };
    }
  };
  return (
    <div className={className} style={style}>
      <Title
        level={4}
        style={{
          whiteSpace: "pre",
          width: "100%",
          ...getResponsiveStyle(),
          marginTop: 0,
          paddingTop: 0,
        }}
      >
        {text.substring(0, index)}
        <span style={{ opacity: blink ? 1 : 0, fontFamily: "monospace" }}>
          |
        </span>
      </Title>
    </div>
  );
};
