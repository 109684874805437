// useAuth.js
import React, { createContext, useContext, useMemo } from "react";
import { useNavigate } from "react-router-dom";

import { loginService, loginCustomService } from "../services/loginService";
import { useAuthStore } from "../store/useAuthStore";
import { useStore } from "../store/useStore";
import { message } from "antd";
import mixpanel from "mixpanel-browser";
const AuthContext = createContext();

export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
  const { user, setUser, clearUser } = useAuthStore();
  const { hideLoginModal, showLoginModal, showLoading, hideLoading } =
    useStore();
  const navigate = useNavigate();

  const login = async (credentials) => {
    try {
      hideLoginModal();
      showLoading();
      // Assuming "loginService" is an async function that handles the login
      const userFromLogin = await loginService(credentials);

      setUser(userFromLogin);
      hideLoginModal();
      navigate("/dashboard/project", { replace: true });
    } catch (error) {
      // Handle login error (e.g., show a message to the user)
    }
  };
  const loginCustom = async (access_token) => {
    try {
      console.log("loginCustom");
      hideLoginModal();
      showLoading();
      // Assuming "loginService" is an async function that handles the login
      const userFromLogin = await loginCustomService(access_token);

      setUser(userFromLogin);

      navigate("/dashboard/project", { replace: true });
      hideLoading();
    } catch (error) {
      // Handle login error (e.g., show a message to the user)
    }
  };

  const logout = () => {
    mixpanel.track("Logout");
    clearUser(); // Zustand store's clearUser
    navigate("/", { replace: true });
  };

  const value = useMemo(
    () => ({
      user,
      login,
      loginCustom,
      logout,
    }),
    [user]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
