import React, { useEffect } from "react";
import { Card, Button, Typography, Row, Col, Layout, Image } from "antd";
import "../Styles/pricingpage.css"; // Make sure to create a corresponding CSS file
import style from "../Styles/style";
import Paragraph from "antd/es/skeleton/Paragraph";
import { useAuthStore } from "../store/useAuthStore";
import {
  PlusOutlined,
  CheckCircleOutlined,
  CloseOutlined,
  GlobalOutlined,
  RocketOutlined,
  CustomerServiceOutlined,
} from "@ant-design/icons";
import mixpanel from "mixpanel-browser";

import { loadStripe } from "@stripe/stripe-js";

import { createCheckoutSession } from "../services/creditService";
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE);

const { Title, Text } = Typography;

const PricingPage = () => {
  // Dummy data for the pricing options
  const { user } = useAuthStore();

  useEffect(() => {
    mixpanel.track("Credit Page");
  }, []);

  const buyCredits = async (number) => {
    mixpanel.track("Click Buy Credit", { numberOfCredit: number });
    try {
      const session = await createCheckoutSession({
        credits: number,
        email: user.email,
        userid: user._id,
      });

      const stripe = await stripePromise;
      const result = await stripe.redirectToCheckout({ sessionId: session.id });

      if (result.error) {
        alert(result.error.message);
      }
    } catch (error) {
      mixpanel.track("Error checkout", { numberOfCredit: number });
      console.error("Error during the checkout process:", error);
      // Handle errors here
    }
  };
  return (
    <Layout
      style={{
        backgroundColor: style.colors.background0,
      }}
    >
      <div
        style={{
          width: "100%",

          background: `url(/sphere-blur.png)`, // Use backgroundImage with url()
          backgroundSize: "cover", // Ensures the image covers the entire Layout
          backgroundPosition: "center", // Centers the background image
          backgroundRepeat: "no-repeat",
          backgroundAttachment: "fixed", // Prevents the image from repeating
        }}
      >
        <div
          style={{
            width: "100%",
            margin: 0,
            marginTop: 80,
            fontSize: 32,
            textAlign: "center",
            fontWeight: "bolder",
            color: style.colors.text,
          }}
        >
          <span style={{ color: style.colors.color }}> Elevate</span> Your
          Music.
        </div>

        <div
          style={{
            marginTop: 20,
            fontSize: 18,
            textAlign: "center",
            fontWeight: "initial",
            color: style.colors.text,
          }}
        >
          {" "}
          You have
          <span style={{ color: style.colors.color }}>
            {" "}
            {user.credits} credits.
          </span>{" "}
          Join the elite circle of artists transforming their music into visual
          masterpieces
        </div>

        <Row style={{ margin: 0, marginTop: 60, marginBottom: 80 }} gutter={24}>
          <Col xs={24} sm={24} md={24} lg={24} xl={8}>
            <Card
              style={{
                margin: 30,

                backgroundColor: style.colors.background1,
                borderRadius: 20,
              }}
              bordered={false}
            >
              <Title
                style={{
                  borderRadius: 15,
                  backgroundColor: style.colors.background2,
                  textAlign: "center",
                }}
                level={2}
              >
                50 credits
              </Title>
              <div style={{ padding: "0 16px", marginTop: 20 }}>
                {" "}
                {/* Add padding for the text content */}
                <Text style={{ display: "block", margin: "8px 0" }}>
                  {" "}
                  {/* Display block for each Text */}
                  <CheckCircleOutlined
                    style={{ color: style.colors.color, marginRight: 20 }}
                  />
                  Image Generation
                </Text>
                <Text style={{ display: "block", margin: "8px 0" }}>
                  <CheckCircleOutlined
                    style={{ color: style.colors.color, marginRight: 20 }}
                  />{" "}
                  YouTube & Instagram Visual
                </Text>
                <Text style={{ display: "block", margin: "8px 0" }}>
                  <CheckCircleOutlined
                    style={{ color: style.colors.color, marginRight: 20 }}
                  />{" "}
                  24/7 Support
                </Text>
                <Text style={{ display: "block", margin: "8px 0" }}>
                  <CloseOutlined
                    style={{ color: style.colors.color, marginRight: 20 }}
                  />{" "}
                  Access to future features first
                </Text>
              </div>
              <Title
                level={3}
                className="price"
                style={{ textAlign: "center" }}
              >
                <span style={{ color: style.colors.textSoft }}>US</span> $10
              </Title>
              <Button
                size="big"
                style={{
                  borderRadius: 20,
                  height: 50,
                  width: "100%",
                  alignSelf: "center",
                  backgroundColor: style.colors.color,
                }}
                onClick={() => {
                  buyCredits(50);
                  //getCredits();
                }}
              >
                Pay now
              </Button>
            </Card>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={8}>
            <Card
              style={{
                border: "2px solid #21B3CD",
                margin: 30,
                borderRadius: 20,
                borderColor: style.colors.color,
                backgroundColor: style.colors.background1,
              }}
              bordered={false}
            >
              <div
                className="popular-badge"
                style={{ backgroundColor: style.colors.color }}
              >
                MOST POPULAR
              </div>
              <Title
                style={{
                  borderRadius: 15,
                  backgroundColor: style.colors.background2,
                  textAlign: "center",
                }}
                level={2}
              >
                100 credits
              </Title>
              <div style={{ padding: "0 16px", marginTop: 20 }}>
                {" "}
                {/* Add padding for the text content */}
                <Text style={{ display: "block", margin: "8px 0" }}>
                  {" "}
                  {/* Display block for each Text */}
                  <CheckCircleOutlined
                    style={{ color: style.colors.color, marginRight: 20 }}
                  />
                  Image Generation
                </Text>
                <Text style={{ display: "block", margin: "8px 0" }}>
                  <CheckCircleOutlined
                    style={{ color: style.colors.color, marginRight: 20 }}
                  />{" "}
                  YouTube & Instagram Visual
                </Text>
                <Text style={{ display: "block", margin: "8px 0" }}>
                  <CheckCircleOutlined
                    style={{ color: style.colors.color, marginRight: 20 }}
                  />{" "}
                  VIP Support
                </Text>
                <Text style={{ display: "block", margin: "8px 0" }}>
                  <CheckCircleOutlined
                    style={{ color: style.colors.color, marginRight: 20 }}
                  />{" "}
                  Access to future features first
                </Text>
              </div>
              <Title
                level={3}
                className="price"
                style={{ textAlign: "center" }}
              >
                <span style={{ color: style.colors.textSoft }}>US</span> $15
              </Title>
              <Button
                size="big"
                style={{
                  height: 50,
                  borderRadius: 20,
                  width: "100%",
                  alignSelf: "center",
                  backgroundColor: style.colors.color,
                }}
                onClick={() => {
                  buyCredits(100);
                }}
              >
                Pay now
              </Button>
            </Card>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={8}>
            <Card
              style={{
                margin: 30,
                borderRadius: 20,
                backgroundColor: style.colors.background1,
              }}
              bordered={false}
            >
              <Title
                style={{
                  borderRadius: 15,
                  backgroundColor: style.colors.background2,
                  textAlign: "center",
                }}
                level={2}
              >
                150 credits
              </Title>
              <div style={{ padding: "0 16px", marginTop: 20 }}>
                {" "}
                {/* Add padding for the text content */}
                <Text style={{ display: "block", margin: "8px 0" }}>
                  {" "}
                  {/* Display block for each Text */}
                  <CheckCircleOutlined
                    style={{ color: style.colors.color, marginRight: 20 }}
                  />
                  Image Generation
                </Text>
                <Text style={{ display: "block", margin: "8px 0" }}>
                  <CheckCircleOutlined
                    style={{ color: style.colors.color, marginRight: 20 }}
                  />{" "}
                  YouTube & Instagram Visual
                </Text>
                <Text style={{ display: "block", margin: "8px 0" }}>
                  <CheckCircleOutlined
                    style={{ color: style.colors.color, marginRight: 20 }}
                  />{" "}
                  VIP Support
                </Text>
                <Text style={{ display: "block", margin: "8px 0" }}>
                  <CheckCircleOutlined
                    style={{ color: style.colors.color, marginRight: 20 }}
                  />{" "}
                  Access to future features first
                </Text>
              </div>
              <Title
                level={3}
                className="price"
                style={{ textAlign: "center" }}
              >
                <span style={{ color: style.colors.textSoft }}>US</span> $20
              </Title>
              <Button
                size="big"
                style={{
                  borderRadius: 20,
                  height: 50,
                  width: "100%",
                  alignSelf: "center",
                  backgroundColor: style.colors.color,
                }}
                onClick={() => {
                  buyCredits(150);
                }}
              >
                Pay now
              </Button>
            </Card>
          </Col>
        </Row>
      </div>
      <Row
        style={{
          backgroundColor: style.colors.background1,
        }}
      >
        <div
          style={{
            backgroundColor: style.colors.background1,
            marginTop: 40,
            marginBottom: 40,
            fontSize: 25,
            width: "100%",
            textAlign: "center",
            fontWeight: "bold",
            color: style.colors.text,
          }}
        >
          Made
          <span style={{ color: style.colors.color }}> by Artists,</span> For
          <span style={{ color: style.colors.color }}> Artists.</span>{" "}
        </div>
      </Row>
      <Row
        style={{
          marginTop: 50,
          padding: 40,
        }}
      >
        <Col xs={24} sm={24} md={24} lg={24} xl={12} style={{ padding: 30 }}>
          <div
            style={{
              height: "100%",
              minHeight: 150, // Adjust the height as needed
              backgroundImage: "url(/dashboard.png)",
              backgroundSize: "cover", // This will ensure the image covers the entire div and is cropped accordingly
              backgroundPosition: "center", // This will center the image
              borderRadius: "20px", // Optional: to match the container's border-radius
            }}
          />
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={12} style={{ padding: 40 }}>
          {/*  <GlobalOutlined
            style={{
              fontSize: 32,
              marginBottom: 20,
              color: style.colors.textInputVisible,
            }}
          ></GlobalOutlined>*/}
          <RocketOutlined
            style={{
              fontSize: 42,
              marginBottom: 20,
              color: style.colors.color,
            }}
          ></RocketOutlined>{" "}
          <Title style={{ marginTop: 0 }} level={2}>
            Make Your Music Stand Out!
          </Title>
          <div
            style={{
              fontSize: 18,
              marginTop: 20,

              fontWeight: "normal",
              color: style.colors.textInputVisible,
            }}
          >
            Your cover is the first element seen by potential listeners, making
            the first impression crucial.
          </div>
          <div
            style={{
              fontSize: 18,

              fontWeight: "normal",
              color: style.colors.textInputVisible,
            }}
          >
            <br></br>
            By creating professional-looking artwork that truly represents you,
            you increase the chances of your song going viral.
          </div>
        </Col>
      </Row>
      <Row
        style={{
          padding: 40,
        }}
      >
        <Col xs={24} sm={24} md={24} lg={24} xl={12} style={{ padding: 40 }}>
          {/*  <GlobalOutlined
            style={{
              fontSize: 32,
              marginBottom: 20,
              color: style.colors.textInputVisible,
            }}
          ></GlobalOutlined>*/}
          <CustomerServiceOutlined
            style={{
              fontSize: 52,
              marginBottom: 20,
              color: style.colors.color,
            }}
          ></CustomerServiceOutlined>{" "}
          <Title style={{ marginTop: 0 }} level={2}>
            Save Time for What You Love
          </Title>
          <div
            style={{
              fontSize: 18,
              marginTop: 20,

              fontWeight: "normal",
              color: style.colors.textInputVisible,
            }}
          >
            Spend more time in the studio and less time worrying about graphics.
            Today you have to produce high quality content, high quality video,
            etc. We know it is time consuming.
            <br></br>
            <br></br>We've got your visual needs covered, so you can focus on
            what you love: your music.
          </div>
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={12} style={{ padding: 30 }}>
          <div
            style={{
              height: "100%",
              minHeight: 150, // Adjust the height as needed
              backgroundImage: "url(/time.png)",
              backgroundSize: "cover", // This will ensure the image covers the entire div and is cropped accordingly
              backgroundPosition: "center", // This will center the image
              borderRadius: "20px", // Optional: to match the container's border-radius
            }}
          />
        </Col>
      </Row>
      {/* 
      <Row
        style={{
          marginTop: 50,
          padding: 40,
        }}
      >
        <Col xs={24} sm={24} md={24} lg={24} xl={12} style={{ padding: 20 }}>
          <Title style={{ marginTop: 0 }} level={3}>
            Save time for what you love
          </Title>
          <div
            style={{
              fontSize: 14,
              marginTop: 20,
              fontWeight: "normal",
              color: style.colors.textInputVisible,
            }}
          >
            Spend more time in the studio and less time worrying about graphics.
            Today you have to produce high quality content, high quality video,
            etc. We know it is time consuming.
            <br></br>
            <br></br>We've got your visual needs covered, so you can focus on
            what you love : your music.
          </div>
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={12} style={{ padding: 30 }}>
          <div
            style={{
              height: "100%", // Adjust the height as needed
              backgroundImage: "url(/time.png)",
              backgroundSize: "cover", // This will ensure the image covers the entire div and is cropped accordingly
              backgroundPosition: "center", // This will center the image
              borderRadius: "20px", // Optional: to match the container's border-radius
            }}
          />
        </Col>
      </Row>
          */}
    </Layout>
  );
};

export default PricingPage;
