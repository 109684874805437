import React, { useEffect, useState } from "react";
import { TypingText } from "../fx/TypingText";
import { motion } from "framer-motion";
import {
  Layout,
  Typography,
  Input,
  Button,
  Avatar,
  Card,
  Row,
  Image,
  Col,
  Progress,
} from "antd";
import style from "../../Styles/style";
import { useStore } from "../../store/useStore";
import useProjectStore from "../../store/useProjectStore";
import {
  useGenerateIdeasService,
  useProjectService,
} from "../../services/projectService";
import { useAuthStore } from "../../store/useAuthStore";
import { ImageGallery } from "./SubComponents/ImageGallery";
import ThumbnailEditor from "../ProjectComponents/ThumbnailEditor";
import { HorizontalGallery } from "./SubComponents/HorizontalGallery";
import mixpanel from "mixpanel-browser";
const { Title, Paragraph } = Typography;
export const GenerateImageCreatorComponent = ({ onValidated }) => {
  const [nameInput, setnameInput] = useState("");
  const { showModal } = useStore();
  const {
    selectedImage,
    setSelectedImage,
    tempSelectedImage,
    setTempSelectedImage,
    setIsCreatingCover,
  } = useProjectStore();
  const { isGeneratingCover } = useAuthStore();
  const { generateImages } = useGenerateIdeasService();
  const { actualPrompt, setActualPrompt, selectedProject } = useProjectStore();
  const [saveDisabled, setSaveDisabled] = useState(false);
  const [isGeneratingImage, setIsGeneratingImage] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 992);
  const [percent, setPercent] = useState(0);

  const startProgress = () => {
    let progressInterval;
    setPercent(0); // Reset progress to 0

    progressInterval = setInterval(() => {
      setPercent((prevPercent) => {
        if (prevPercent >= 95) {
          clearInterval(progressInterval);
          return 95;
        }
        return prevPercent + 1;
      });
    }, 200); // 15 seconds / 95 increments ≈ 158 milliseconds per increment
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 992);
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Call it once to set initial state based on current window size

    return () => window.removeEventListener("resize", handleResize);
  }, []);
  // State for the selected image
  const [generatedImages, setGeneratedImages] = useState([]);
  const handlePromptChange = (e) => {
    setActualPrompt(e.target.value);
  };

  const handleSelectImage = (image) => {
    mixpanel.track("Click Select Image");
    setTempSelectedImage(image);
  };
  const handleSubmit = () => {
    mixpanel.track("Validate Image", {
      selectedImageUrl: tempSelectedImage.url,
    });
    setSelectedImage(tempSelectedImage);
    setIsCreatingCover(true);
    // Add validation logic here if needed
    // If validation passes, call onValidated to go to the next step
    onValidated();
  };
  const generateImage = () => {
    if (actualPrompt === null || actualPrompt === "") {
      showModal(
        <p>
          In order to generate thumbnail ideas, you have to enter a script for
          your video
        </p>,
        {
          title: "Script is empty",
          isInfoModal: true,
          okText: "Ok",
          onOk: () => console.log("Info OK clicked"),
        }
      );
      return;
    }
    mixpanel.track("Click Generate Image", {
      prompt: actualPrompt,
    });
    setIsCreatingCover(true);
    generateImages(
      selectedProject._id,
      actualPrompt,
      (image) => {
        setTempSelectedImage(image);
      },
      () => {}
    );
    startProgress();
  };
  const projectVariants = {
    hidden: { opacity: 0, y: 50 },
    transition: {
      y: { stiffness: 1000, velocity: -10 },
    },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        staggerChildren: 0.2, // 100ms stagger
      },
    },
    exit: { opacity: 0, y: -20 },
  };
  function getDispositionOfCard() {}
  useEffect(() => {
    console.log("Geneate Image Visible");
  }, []);
  return (
    <div
      style={{
        width: "100%",
        backgroundColor: style.colors.background0,
        padding: 40,
        display: "flex",
        justifyContent: "center", // Centers horizontally
        minHeight: "100vh",
        backgroundImage: "url('/nameback.png')", // Add your image path here
        backgroundSize: "cover", // Ensure the image covers the whole div
        backgroundRepeat: "no-repeat", // Prevent the image from repeating
        backgroundPosition: "bottom", // Takes full viewport height
      }}
    >
      <motion.div
        initial="hidden"
        animate="visible"
        exit="exit" // Define the exit animation
        variants={projectVariants}
        transition={{ duration: 2.0 }} // Adjust the timing as needed
        style={{
          marginTop: isMobile ? "10px" : "50px",
          width: "100%", // Set the width to 100% instead of minWidth
          // Use maxWidth for responsiveness
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Row
          style={{
            width: "100%",
          }}
        >
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={
              selectedProject.images && selectedProject.images.length > 0
                ? 10
                : 24
            }
            xl={
              selectedProject.images && selectedProject.images.length > 0
                ? 10
                : 24
            }
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                maxWidth: 800,
              }}
            >
              <TypingText
                className="noneOnMobile"
                text={`Generate the image of your cover.`}
                onComplete={() => {}}
              />
              <Card
                style={{
                  width: "100%",
                  margin: 0,
                  backgroundColor: style.colors.background1,
                }}
              >
                <Title style={{ marginTop: 5 }} level={5}>
                  Describe what you want.
                </Title>
                <Paragraph style={{ color: style.colors.textSoft }}>
                  Define the subject, the elements, the mood of your image.
                </Paragraph>
                <Input.TextArea
                  autoFocus={!isMobile}
                  value={actualPrompt}
                  onChange={handlePromptChange}
                  type="text"
                  autoSize={{ minRows: 2, maxRows: 6 }}
                  placeholder="type here the description"
                  style={{ height: 50, marginTop: 5, fontSize: 16 }}
                />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginTop: 20,
                  }}
                >
                  <Button
                    onClick={handleSubmit}
                    style={{
                      borderColor: style.colors.color,
                      paddingLeft: 30,
                      paddingRight: 30,
                      display: "none",
                    }}
                    disabled={saveDisabled}
                  >
                    Enhance with IA
                  </Button>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      alignContent: "center",
                      width: 200,
                    }}
                  >
                    <Button
                      onClick={generateImage}
                      style={{
                        width: "100%",

                        paddingLeft: 30,
                        paddingRight: 30,
                      }} // added marginLeft for spacing between buttons
                      type="primary"
                      loading={isGeneratingCover}
                      disabled={saveDisabled}
                    >
                      Generate an image
                    </Button>
                    <Progress
                      visible={false}
                      style={{
                        display: isGeneratingCover ? "block" : "none",
                        marginInlineEnd: 0,
                        width: "100%",
                      }}
                      percent={percent}
                    />
                  </div>
                </div>
              </Card>
            </div>
          </Col>

          <Col xs={24} sm={24} md={24} lg={14} xl={14}>
            {selectedProject.images && selectedProject.images.length > 0 && (
              <Row style={{ padding: 10, width: "100%" }}>
                {/* Full-width image display */}

                <Col xs={24} sm={24} md={24} lg={18} xl={18}>
                  <div
                    style={{
                      position: "relative", // Add this line
                      width: "100%",
                      minHeight: 400,
                      maxHeight: 500,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center", // This centers the image vertically if needed
                    }}
                  >
                    <Image
                      placeholder={true}
                      src={tempSelectedImage?.url}
                      style={{
                        marginTop: isMobile ? 30 : 0,
                        minHeight: 400,
                        maxHeight: 500,
                        maxWidth: "100%", // Changed to maxWidth to maintain aspect ratio
                        objectFit: "cover", // This makes the image cover the area without stretching
                        marginBottom: 16,
                        border: "0px solid white",
                      }}
                    />
                    <Button
                      onClick={handleSubmit}
                      type="primary"
                      size="big"
                      style={{
                        boxShadow: "15px 18px 24px 5px rgba(0, 0,0, 0.6)",
                        position: "absolute",
                        height: 50,
                        backgroundColor: style.colors.color,
                        bottom: "30px", // Adjust as necessary to move the button up from the bottom
                        left: "50%", // Center the button horizontally
                        transform: "translateX(-50%)", // This centers the button with respect to its width
                        zIndex: 1,
                      }}
                    >
                      Validate this image
                    </Button>
                  </div>
                </Col>
                <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    {isMobile ? (
                      <HorizontalGallery
                        selectedProject={selectedProject}
                        handleSelectImage={handleSelectImage}
                      ></HorizontalGallery>
                    ) : (
                      <ImageGallery
                        selectedProject={selectedProject}
                        handleSelectImage={handleSelectImage}
                      ></ImageGallery>
                    )}
                  </div>
                </Col>
                {/* Gallery of generated images */}
              </Row>
            )}
          </Col>
        </Row>
      </motion.div>
    </div>
  );
};
