import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import { Layout, ConfigProvider, theme } from "antd";
import "./Styles/global.css";
import { Typography } from "antd";
import { HomeLayout } from "./components/HomeLayout";
import { HomePage } from "./pages/Home";
import { LoginPage } from "./pages/Login";
import { ProtectedLayout } from "./components/ProtectedLayout";
import PricingPage from "./pages/BuyCredits";
import style from "./Styles/style";
import FirstConnection from "./components/FirstConnection";
import { WelcomePage } from "./pages/WelcomePage";
import LoadingPopup from "./tools/LoadingPopup";
import { ProjectPage } from "./pages/ProjectPage";
import { GlobalModal } from "./store/useStore";
import { SuccessPage } from "./pages/SuccessPage";
import { CancelPage } from "./pages/CancelPage";
import { ProjectCreatorPage } from "./pages/ProjectCreatorPage";
import { BasicPage } from "./components/BasicPage";
import { MyProjectsPage } from "./pages/MyProjectsPage";
import TestComponent from "./components/CreatorComponents/testComponent";
import { IntroPage } from "./pages/introPage";
import { PrivacyPolicyPage } from "./pages/PrivacyPolicyPage";
import { TermsOfService } from "./pages/TermsAndServicesPage";
import CreditModal from "./components/Modal/CreditModal";
import IntroModal from "./components/Modal/IntroModal";
import mixpanel from "mixpanel-browser";
function App() {
  const { Content } = Layout;
  console.log("REACT_APP_PRODUCTION:", process.env.REACT_APP_PRODUCTION);
  mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN, {
    debug: process.env.REACT_APP_PRODUCTION === "true",
  });
  return (
    <div>
      <ConfigProvider
        theme={{
          // 1. Use dark algorithm
          algorithm: theme.darkAlgorithm,
          token: {
            // Seed Token

            borderRadius: 5,
            boxShadow: "none",
            fontFamily: "montserrat,sans-serif", // Set the font family
            // Alias Token
            colorPrimary: style.colors.color,
            colorBgContainer: style.colors.background0,
          },
        }}
      >
        <LoadingPopup />
        <GlobalModal></GlobalModal>
        <CreditModal></CreditModal>
        <Routes>
          {/*VITRINE*/}
          <Route element={<HomeLayout />}>
            <Route path="/" element={<HomePage />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/privacy" element={<PrivacyPolicyPage />} />
            <Route path="/termsandservices" element={<TermsOfService />} />
          </Route>
          {/*LOGIN*/}
          <Route element={<FirstConnection />}>
            <Route path="/intro" element={<IntroPage />} />
          </Route>

          {/*DASHBOARD*/}
          <Route path="/dashboard" element={<ProtectedLayout />}>
            <Route path="cancel" element={<CancelPage />} />
            <Route path="success" element={<SuccessPage />} />
          </Route>

          <Route path="/dashboard" element={<ProtectedLayout />}>
            <Route path="project/create" element={<ProjectCreatorPage />} />
            <Route path="project" element={<MyProjectsPage />} />
            <Route path="test" element={<TestComponent />} />
            <Route path="buy-credits" element={<PricingPage />} />
          </Route>
        </Routes>
      </ConfigProvider>
    </div>
  );
}

export default App;
