import React, { useState, useEffect, useRef } from "react";
import { Layout, Typography, Input, Button, Avatar, Card } from "antd";
import { motion, AnimatePresence } from "framer-motion";
import { useAuthStore } from "../store/useAuthStore";
import style from "../Styles/style";
import { UserOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { useStore } from "../store/useStore";
import { useProjectService } from "../services/projectService";
import { TypingText } from "../components/fx/TypingText";
import mixpanel from "mixpanel-browser";
const { Content } = Layout;
const { Title, Paragraph } = Typography;

export const IntroPage = () => {
  const { user } = useAuthStore();
  const { addproject } = useAuthStore();
  const { showIntroModal, showTutorial } = useStore();
  const [typingComplete, setTypingComplete] = useState(false);
  const navigate = useNavigate();
  const [isIntroShown, setIsIntroShown] = useState(true);
  const [saveDisabled, setSaveDisabled] = useState(true);
  const [nameInput, setnameInput] = useState("");
  const showLoading = useStore((state) => state.showLoading);
  const { finishSetup } = useProjectService();
  const inputRef = useRef();
  const focusInput = () => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };
  const containerVariants = {
    hidden: {},
    show: {
      transition: {
        staggerChildren: 0.2, // 100ms stagger
      },
    },
  };
  const parentVariants = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        delay: 0, // Delay should be long enough for all child animations to complete
        duration: 0.01, // A very short dummy animation
      },
    },
  };
  const variants = {
    hidden: { opacity: 0, y: -20 },
    visible: { opacity: 1, y: 0 },
    exit: { opacity: 0, y: -20 }, // Fade out and move up
  };

  const handleInputChange = (event) => {
    setnameInput(event.target.value);
    if (!event.target.value || event.target.value === "") {
      setSaveDisabled(true);
    } else {
      setSaveDisabled(false);
    }
  };
  const showProjectName = () => {
    setIsIntroShown(false);
    focusInput();
  };
  const projectVariants = {
    hidden: { opacity: 0, y: 50 },
    transition: {
      y: { stiffness: 1000, velocity: -10 },
    },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        staggerChildren: 0.2, // 100ms stagger
      },
    },
    exit: { opacity: 0, y: -20 },
  };
  const handleTypingComplete = () => {
    setTypingComplete(true);
  };
  const saveArtistName = () => {
    if (!nameInput || nameInput === "") {
      return;
    }
    mixpanel.track("Username added");
    //showIntroModal();
    showTutorial();
    setSaveDisabled(true);
    //createProject();
    finishSetup(nameInput, () => {
      navigate("/dashboard/project/create");
    });

    //navigate("/dashboard/profile");
  };

  return (
    <div
      style={{
        width: "100%",
        backgroundColor: style.colors.background0,
        padding: 20,
        display: "flex",
        justifyContent: "center",
        alignItems: "center", // Centers horizontally
        minHeight: "100vh", // Takes full viewport height
      }}
    >
      <motion.div
        initial="hidden"
        animate="visible"
        exit="exit" // Define the exit animation
        variants={projectVariants}
        transition={{ duration: 2.0 }} // Adjust the timing as needed
        style={{
          marginTop: -100,
          width: "100%", // Set the width to 100% instead of minWidth
          maxWidth: 700, // Use maxWidth for responsiveness
          display: "flex",
          flexDirection: "column",
        }}
      >
        {/*<UserOutlined style={{fontSize:20,color:style.colors.text}}></UserOutlined>*/}
        <TypingText
          style={{ width: "100%" }}
          text={`Nice to meet you, what's your artist name ? `}
          onComplete={() => {
            handleTypingComplete();
          }}
        />
        <motion.div
          initial="hidden"
          animate={typingComplete ? "visible" : "hidden"}
          exit="exit" // Define the exit animation
          variants={projectVariants}
          transition={{ duration: 2.0 }} // Adjust the timing as needed
          style={{
            marginTop: 20,
            width: "100%", // Set the width to 100% instead of minWidth
            maxWidth: 700, // Use maxWidth for responsiveness
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Card
            style={{
              maxWidth: 1000,
              width: "100%",
              margin: 0,

              backgroundColor: style.colors.background1,
            }}
          >
            <Input
              autoFocus
              value={nameInput}
              onChange={handleInputChange}
              type="text"
              placeholder="Enter your artist name here"
              style={{ height: 50, marginTop: 5 }}
            />

            <Button
              onClick={saveArtistName}
              type="primary"
              disabled={saveDisabled}
              style={{
                position: "absolute",

                backgroundColor: style.colors.color,
                marginTop: 15,
                right: 30,
                width: 100,

                zIndex: 1,
              }}
            >
              <div>
                <span>Validate</span>
              </div>
            </Button>
          </Card>
        </motion.div>
      </motion.div>
    </div>
  );
};
