import React, { useState, useEffect } from "react";
import { Button, Layout, Menu, Steps, Typography, Card } from "antd";
import { NameCreatorComponent } from "../components/CreatorComponents/NameCreatorComponent";
import style from "../Styles/style";
import { GenerateImageCreatorComponent } from "../components/CreatorComponents/GenerateImageCreatorComponent";
import useProjectStore from "../store/useProjectStore";
import { EditorCreatorComponent } from "../components/CreatorComponents/EditorCreatorComponent";
import { CoverCreatorComponent } from "../components/CreatorComponents/CoverCreatorComponent";
import { useStore } from "../store/useStore";
import { useNavigate } from "react-router-dom";
import {
  MenuOutlined,
  CloseOutlined,
  ProjectOutlined,
  FileImageOutlined,
  EditOutlined,
  PictureOutlined,
  ExpandAltOutlined,
  ImageOutlined,
  RightOutlined,
  LeftOutlined,
  MenuFoldOutlined,
  CheckCircleOutlined,
  MenuUnfoldOutlined,
} from "@ant-design/icons";
import RightArrowIcon from "../icons/RightArrowIcon";
import IntroModal from "../components/Modal/IntroModal";
import TutoModal from "../components/Modal/TutoModal";
import mixpanel from "mixpanel-browser";
const { Title, Text, Paragraph } = Typography;
const { Sider, Content } = Layout;
const { Step } = Steps;

export const ProjectCreatorPage = () => {
  const {
    showModal,
    tutorialStep,
    showTutoModal,
    isShowingTutorial,
    incrementTutorialStep,
  } = useStore();
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState(0);
  const [contentTuto, setContentTuto] = useState(
    <Text style={{ fontWeight: 400, fontSize: 16, textAlign: "center" }}>
      Here are a few credits for generating images and creating promotional
      content.
    </Text>
  );
  const [titleTuto, setTitleTuto] = useState("Title");
  const {
    projectName,
    selectedProject,
    setSelectedImage,
    selectedImage,
    isEditingCover,
    setIsEditingCover,
  } = useProjectStore();
  const bulletPointStyle = {
    display: "flex",
    alignItems: "center",
    marginBottom: "10px", // space between bullet points
  };

  const numberStyle = {
    height: "30px",
    width: "30px",
    minHeight: "30px",
    minWidth: "30px",
    backgroundColor: style.colors.color, // choose your color
    borderRadius: "50%",
    color: "white",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginRight: "10px",
  };
  const [steps, setSteps] = useState([
    {
      id: 1,
      title: "Name",
      mobile: "Change name",
      component: NameCreatorComponent,
      tutoTitle: "Create your first cover",
      tutoContent: (
        <div
          style={{
            width: "100%",
            display: "flex",
            marginTop: 0,
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <p style={{ fontWeight: 400, fontSize: 16, textAlign: "center" }}>
            Here are a few credits for generating images and creating
            promotional content.
          </p>
          <Card
            style={{
              border: "2px solid #21B3CD",
              margin: 30,
              marginBottom: 15,
              borderRadius: 20,
              borderColor: style.colors.color,
              backgroundColor: style.colors.background1,
            }}
            bordered={false}
          >
            {/* <div
            className="popular-badge"
            style={{ backgroundColor: style.colors.color }}
          >
            GIFT
          </div>
          */}
            <Title
              style={{
                borderRadius: 15,
                backgroundColor: style.colors.background2,
                textAlign: "center",
                fontSize: 14,
                paddingTop: 10,
                paddingBottom: 10,
              }}
            >
              5 credits (free)
            </Title>
            <div style={{ padding: "0 16px", marginTop: 20 }}>
              {" "}
              {/* Add padding for the text content */}
              <Text style={{ display: "block", margin: "8px 0" }}>
                {" "}
                {/* Display block for each Text */}
                <CheckCircleOutlined
                  style={{ color: style.colors.color, marginRight: 20 }}
                />
                Image Generation (1024x1024 and upscaling)
              </Text>
              <Text style={{ display: "block", margin: "8px 0" }}>
                <CheckCircleOutlined
                  style={{ color: style.colors.color, marginRight: 20 }}
                />
                Youtube Visual (Horizontal format)
              </Text>
              <Text style={{ display: "block", margin: "8px 0" }}>
                <CheckCircleOutlined
                  style={{ color: style.colors.color, marginRight: 20 }}
                />
                Instagram Visual (Vertical format)
              </Text>
            </div>
          </Card>
        </div>
      ),
      icon: <PictureOutlined />,
    },
    {
      id: 2,
      mobile: "Generate image",
      title: "Image",
      component: GenerateImageCreatorComponent,
      icon: <PictureOutlined />,
      tutoTitle: "Generate your first image",
      tutoContent: (
        <div
          style={{
            textAlign: "left",
            display: "flex",
            padding: "0 10%",
            paddingTop: 20,
            flexDirection: "column",
          }}
        >
          <div style={bulletPointStyle}>
            <div style={numberStyle}>1</div>
            <Text style={{ fontWeight: 400, fontSize: 16 }}>
              Enter a prompt to generate an image
            </Text>
          </div>
          <div style={bulletPointStyle}>
            <div style={numberStyle}>2</div>
            <Text style={{ fontWeight: 400, fontSize: 16 }}>
              Press 'Generate' again if you want to create a variation of the
              same prompt.
            </Text>
          </div>
          <div style={bulletPointStyle}>
            <div style={numberStyle}>3</div>
            <Text style={{ fontWeight: 400, fontSize: 16 }}>
              Click 'Validate' to select the image and proceed to editing.
            </Text>
          </div>
          <Text style={{ fontWeight: 600, fontSize: 12, textAlign: "center" }}>
            (Each generation costs one credit)
          </Text>
        </div>
      ),
    },
    {
      id: 3,
      title: "Editor",
      mobile: "Modify image",
      component: EditorCreatorComponent,
      icon: <FileImageOutlined />,
      tutoTitle: "Add text and elements",
      tutoContent: (
        <div
          style={{
            textAlign: "left",
            display: "flex",
            padding: "0 10%",
            paddingTop: 20,
            flexDirection: "column",
          }}
        >
          <div style={bulletPointStyle}>
            <div style={numberStyle}>1</div>
            <Text style={{ fontWeight: 400, fontSize: 16 }}>
              Click the "+" to add as many text elements as you want
            </Text>
          </div>
          <div style={bulletPointStyle}>
            <div style={numberStyle}>2</div>
            <Text style={{ fontWeight: 400, fontSize: 16 }}>
              Click on 'Parental Advisory' to add the Parental Advisory logo.
            </Text>
          </div>
          <div style={bulletPointStyle}>
            <div style={numberStyle}>3</div>
            <Text style={{ fontWeight: 400, fontSize: 16 }}>
              Click 'Validate Cover' to prepare it for streaming platforms.
            </Text>
          </div>
        </div>
      ),
    },
    {
      id: 4,
      mobile: "Final Cover",
      title: "Cover",
      component: CoverCreatorComponent,
      icon: <ProjectOutlined />,
      tutoTitle: "Congratulations on Creating Your First Cover",
      tutoContent: (
        <div
          style={{
            textAlign: "left",
            display: "flex",
            padding: "0 10%",
            paddingTop: 20,
            flexDirection: "column",
          }}
        >
          <div style={bulletPointStyle}>
            <div style={numberStyle}>1</div>
            <Text style={{ fontWeight: 400, fontSize: 16 }}>
              Upscale your cover to ensure it's ready for streaming platforms.
            </Text>
          </div>
          <div style={bulletPointStyle}>
            <div style={numberStyle}>2</div>
            <Text style={{ fontWeight: 400, fontSize: 16 }}>
              Customize the text for your promotional content.
            </Text>
          </div>
          <div style={bulletPointStyle}>
            <div style={numberStyle}>3</div>
            <Text style={{ fontWeight: 400, fontSize: 16 }}>
              Generate promotional visuals for Instagram and YouTube.
            </Text>
          </div>
        </div>
      ),
    },
    // ... other initial steps
  ]);

  const renderMobileSteps = () => (
    <Steps
      current={currentStep}
      onChange={clickOnMenu} // Use your existing clickOnMenu function
      items={steps}
      style={{ padding: "0 16px" }} // Add any necessary styling
    />
  );
  useEffect(() => {
    window.scrollTo(0, -100);
  }, []);
  function canClick(stepIndex) {
    console.log("index step", stepIndex);
    if (stepIndex > steps.length - 1) {
      return false;
    }
    if (stepIndex < 0) {
      return false;
    }
    return !isDisabled(steps[stepIndex]);
  }
  const renderNavigations = () => {
    return (
      <div
        style={{
          width: "100%",
          padding: "30px 20px",
          height: 40,
          display: "flex",
          backgroundColor: style.colors.background1,
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        {canClick(currentStep - 1) ? (
          <div
            style={{
              color: style.colors.text,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: 90,
            }}
            onClick={() => {
              goToPreviousStep();
            }}
          >
            <LeftOutlined />
            <span style={{ marginLeft: "8px" }}>
              {steps[currentStep - 1]?.title}
            </span>
          </div>
        ) : (
          <div style={{ width: 90, visibility: "hidden" }}>Placeholder</div>
        )}

        <Typography.Title
          level={5}
          style={{
            fontSize: 15,
            margin: 0,
            padding: 0,
            color: style.colors.text,
            textAlign: "center",
            flex: 1,
          }}
        >
          {steps[currentStep]?.mobile}
        </Typography.Title>

        {canClick(currentStep + 1) ? (
          <div
            style={{
              color: style.colors.text,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: 90,
            }}
            onClick={() => {
              goToNextStep();
            }}
          >
            <span style={{ marginRight: "8px" }}>
              {steps[currentStep + 1]?.title}
            </span>
            <RightOutlined />
          </div>
        ) : (
          <div style={{ width: 90, visibility: "hidden" }}>Placeholder</div>
        )}
      </div>
    );
  };
  const renderDotIndicators = () => {
    return (
      <div
        className="dot-step-indicator"
        style={{ paddingTop: 20, height: 120, backgroundColor: "black" }}
      >
        {steps.map((step, index) => {
          if (currentStep === index) {
            return (
              <div
                style={{
                  backgroundColor: style.colors.background0,
                  zIndex: 2,
                  height: 90,
                  width: 100,
                  borderRadius: "20px 20px 0 0",
                  paddingTop: 15,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  alignSelf: "flex-end",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    color: "white",
                    width: 40,
                    height: 40,
                    marginLeft: 20,
                    marginRight: 20,
                    borderRadius: "50%",
                    backgroundColor: style.colors.color,
                  }}
                  onClick={() => clickOnMenu(index)}
                >
                  {" "}
                  {step.id}
                </div>
                <div
                  style={{
                    marginTop: 10,
                    display: "flex",
                    justifyContent: "center",
                    color: "white",
                    fontWeight: "bold",
                    width: "100%",
                  }}
                >
                  <div>{step.title}</div>
                </div>
              </div>
            );
          }
          return (
            <div
              style={{
                backgroundColor: style.colors.background0,
                zIndex: 2,
                boxShadow: "inset 0 10px 10px 10px rgba(0, 0, 0, 0.5)",
                borderRadius: "20px 20px 0 0",
                minWidth: 80,
                height: 90,
                paddingTop: 15,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  zIndex: 2,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: "white",
                  width: 25,
                  marginLeft: 10,
                  marginRight: 10,
                  height: 25,
                  borderColor: "white",
                  border: "1px solid white",
                  borderRadius: "50%",
                  backgroundColor: style.colors.background0,
                }}
                onClick={() => clickOnMenu(index)}
              >
                {" "}
                {step.id}
              </div>
              <div
                style={{
                  marginTop: 5,
                  display: "flex",
                  justifyContent: "center",
                  color: "white",
                  fontSize: 12,
                  width: "100%",
                }}
              >
                <div>{step.title}</div>
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  function isDisabled(step) {
    if (!step) {
      return false;
    }
    /* if (step.id == 2 && selectedProject?.images.length < 1) {
      return true;
    }*/
    if (step.id == 3 && !selectedImage) {
      return true;
    }
    if (step.id == 4 && !selectedProject?.hasCover) {
      return true;
    }
    return false;
  }
  function clickOnMenu(index) {
    mixpanel.track("Click on menu creator");
    window.scrollTo(0, -100);
    actualizeAndShowTutorialPopups(index);
    setCurrentStep(index);
    if (isMobile) {
      setCollapsed(true);
    }
    return;
    if (isEditingCover) {
      showModal(<p>if you have edit the image, you changes will be lost</p>, {
        title: "Attention",
        isInfoModal: false,
        okText: "Yes, delete",
        cancelText: "No,stay",
        onOk: () => {
          setIsEditingCover(false);
          setCurrentStep(index);
        },
      });
      return;
    }
    if (selectedProject.hasCover && !isEditingCover) {
      showModal(
        <p>
          Are you sure you want to edit your cover ? the communication assets
          will be deleted.
        </p>,
        {
          title: "Attention",
          isInfoModal: false,
          okText: "Yes, edit",
          cancelText: "No, stay",
          onOk: () => {
            setIsEditingCover(true);
            setCurrentStep(index);
          },
        }
      );
      return;
    }
    setCurrentStep(index);
  }
  function actualizeAndShowTutorialPopups(index) {
    const tutoTitle = steps[index]?.tutoTitle;
    setTitleTuto(tutoTitle);
    setContentTuto(steps[index]?.tutoContent);
    if (tutorialStep <= index) {
      if (isShowingTutorial) {
        showTutoModal();
        mixpanel.track("Tutorial Popup", { title: tutoTitle });
      }
    }
  }
  const goToNextStep = () => {
    window.scrollTo(0, -200);

    if (currentStep < steps.length - 1) {
      setCurrentStep(currentStep + 1);
      actualizeAndShowTutorialPopups(currentStep + 1);
    }
  };

  const goToPreviousStep = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  const updateSteps = (newSteps) => {
    setSteps(newSteps);
  };

  const getCurrentStepComponent = () => {
    const StepComponent = steps[currentStep]?.component;
    // setTitleTuto(steps[currentStep]?.component.tutoTitle);
    // setContentTuto(steps[currentStep]?.component.tutoContent);
    return StepComponent ? (
      <StepComponent onValidated={goToNextStep} updateSteps={updateSteps} />
    ) : null;
  };

  useEffect(() => {
    actualizeAndShowTutorialPopups(0);
    // console.log("showintroModal", isShowingIntroModal);
    if (selectedProject) {
    } else {
      navigate("/dashboard/project", { replace: true });
      return;
    }
    // Reset to first step if steps array changes
    if (selectedProject.hasCover) {
      setCurrentStep(3);
      setSelectedImage(selectedProject.cover.imageOfCover);
    } else {
      if (selectedProject.images?.length > 0) {
        setCurrentStep(1);
        return;
      }
      setCurrentStep(0);
    }
  }, [steps]);
  useEffect(() => {}, [projectName]);
  const [collapsed, setCollapsed] = useState(true);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
      setCollapsed(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Call it once to set initial state based on current window size

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const toggleSider = () => {
    setCollapsed(!collapsed);
  };
  function getPaddingLeft() {
    if (isMobile) {
      return { paddingLeft: 0 };
    }
    if (collapsed) {
      return { paddingLeft: 60 };
    }
    return { paddingLeft: 250 };
  }

  return (
    <>
      <TutoModal
        title={titleTuto}
        content={contentTuto}
        onCancel={() => {
          incrementTutorialStep();
          console.log("new step", tutorialStep);
        }}
      />
      <Layout style={{ height: "100%" }}>
        {isMobile ? (
          renderNavigations()
        ) : (
          <Sider
            collapsed={collapsed}
            onCollapse={setCollapsed}
            className="sider"
            trigger={null}
            width={250}
            style={{
              paddingTop: 10,
              overflow: "auto",
              height: "100vh",
              position: "fixed",
              paddingLeft: 15,
              paddingRight: 15,
              backgroundColor: style.colors.background0,
              boxShadow: "rgba(0, 0, 0, 0.2) 10px 0px 10px 0px",
              left: 0,
              zIndex: 2, // Ensure it's above other content
            }}
          >
            <Menu
              mode="inline"
              selectedKeys={[`step-${currentStep}`]}
              style={{
                marginTop: 10,

                borderRight: 0,
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                }}
              >
                <Button
                  style={{
                    color: style.colors.text,
                    display: "flex",
                    justifyContent: "flex-start",
                    marginLeft: 20,
                    marginTop: 10,
                    marginBottom: 10,
                    alignItems: "center",
                    width: "100%",
                  }}
                  onClick={() => {
                    mixpanel.track("Click Back to projects");
                    navigate("/dashboard/project", { replace: true });
                  }}
                >
                  <LeftOutlined />
                  <span style={{ marginLeft: "8px", fontWeight: "bold" }}>
                    Go back to covers
                  </span>
                </Button>
                {/*<Button
                className="collapse"
                onClick={toggleSider}
                style={{
                  width: "100%",

                  zIndex: 1,
                  marginBottom: 10,
                  backgroundColor: style.colors.background0,
                  background: style.colors.background2,
                  bottom: "auto",
                }}
              >
                {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
              </Button>*/}
              </div>
              {steps.map((step, index) => (
                <Menu.Item
                  disabled={isDisabled(step)}
                  icon={step.icon}
                  key={`step-${index}`}
                  onClick={() => clickOnMenu(index)}
                >
                  {step.title}
                </Menu.Item>
              ))}
            </Menu>
          </Sider>
        )}
        <Layout style={{}}>
          {" "}
          {/* Add marginLeft equal to the Sider width */}
          <Content
            className="content"
            style={{
              backgroundColor: style.colors.background0,
              margin: 0,

              minHeight: "100%",
              padding: isMobile ? "10px 0px" : "0px 0px",
              ...getPaddingLeft(),
              // Set minimum height for content
            }}
          >
            {getCurrentStepComponent()}
          </Content>
        </Layout>
      </Layout>
    </>
  );
};
