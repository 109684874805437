import React from "react";
import { Layout, Typography } from "antd";
import style from "../Styles/style";

const { Content } = Layout;
const { Title, Paragraph } = Typography;

export const TermsOfService = () => {
  return (
    <Layout>
      <Content style={{}}>
        <div
          style={{
            background: style.colors.background0,
            padding: "15%",
            paddingTop: 100,
            minHeight: 280,
          }}
        >
          <Typography>
            <Title>Terms of Service for TonalPixel</Title>
            <Paragraph>Last Updated: 22/12/2023</Paragraph>

            <Title level={2}>1. Acceptance of Terms</Title>
            <Paragraph>
              By accessing and using TonalPixel, you agree to be bound by these
              Terms of Service ("Terms"). If you do not agree to these Terms,
              you may not use the TonalPixel service.
            </Paragraph>

            <Title level={2}>2. Service Description</Title>
            <Paragraph>
              TonalPixel is a SaaS platform providing AI-generated artwork and
              promotional materials for independent artists and those on a
              budget.
            </Paragraph>

            <Title level={2}>3. User Registration</Title>
            <Paragraph>
              To access certain features of TonalPixel, you may be required to
              register and provide certain information, including a valid email
              address and password.
            </Paragraph>

            <Title level={2}>4. Privacy Policy</Title>
            <Paragraph>
              Your privacy is important to us. Our Privacy Policy, which is part
              of these Terms, describes how we handle the personal information
              you provide to us when you use our service.
            </Paragraph>

            <Title level={2}>5. User Conduct</Title>
            <Paragraph>
              You agree to use TonalPixel only for lawful purposes and in a way
              that does not infringe the rights of, restrict, or inhibit anyone
              else's use and enjoyment of TonalPixel.
            </Paragraph>

            <Title level={2}>6. Intellectual Property Rights</Title>
            <Paragraph>
              The content and materials provided through TonalPixel are
              protected by intellectual property laws. You may not use, copy, or
              distribute any content without proper authorization.
            </Paragraph>

            <Title level={2}>7. User-Generated Content and Usage Rights</Title>
            <Paragraph>
              <ul>
                <li>
                  Ownership: Content generated by users on TonalPixel, including
                  AI-generated artwork, remains the intellectual property of the
                  user who created it. TonalPixel claims no ownership rights
                  over such content.
                </li>
                <li>
                  Restrictions on Resale: While users retain ownership of their
                  generated content, it is strictly prohibited to resell or use
                  this content for commercial resale purposes.
                </li>
                <li>
                  Compliance with Laws: Users are responsible for ensuring that
                  the content they generate complies with all applicable laws
                  and regulations.
                </li>
                <li>
                  Monitoring and Enforcement: TonalPixel reserves the right to
                  review and monitor content generated on the platform to ensure
                  legal compliance.
                </li>
                <li>
                  Consequences of Illegal Activity: Attempts to generate illegal
                  or prohibited content will result in immediate and severe
                  actions, including potential banning and legal action against
                  the user.
                </li>
              </ul>
            </Paragraph>

            <Title level={2}>8. Service Modification and Availability</Title>
            <Paragraph>
              TonalPixel reserves the right to modify or discontinue,
              temporarily or permanently, the service (or any part thereof) with
              or without notice.
            </Paragraph>

            <Title level={2}>9. Limitation of Liability</Title>
            <Paragraph>
              TonalPixel shall not be liable for any indirect, incidental,
              special, consequential, or punitive damages resulting from your
              use of, or inability to use, the service.
            </Paragraph>

            <Title level={2}>10. Indemnification</Title>
            <Paragraph>
              You agree to indemnify and hold harmless TonalPixel and its
              affiliates, officers, agents, and employees from any claim or
              demand made by any third party due to or arising out of your use
              of the service.
            </Paragraph>

            <Title level={2}>11. Changes to Terms</Title>
            <Paragraph>
              TonalPixel reserves the right to change these Terms at any time.
              Your continued use of the service following any changes indicates
              your acceptance of the new Terms.
            </Paragraph>

            <Title level={2}>12. Governing Law</Title>
            <Paragraph>
              These Terms shall be governed by the laws of the jurisdiction in
              which TonalPixel is based, without regard to its conflict of law
              provisions.
            </Paragraph>

            <Title level={2}>13. Contact Information</Title>
            <Paragraph>
              For any questions about these Terms, please contact us at
              tonalpixel@gmail.com.
            </Paragraph>
          </Typography>
        </div>
      </Content>
    </Layout>
  );
};
