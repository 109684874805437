// useStore.js
import { Button, Modal, Input } from "antd";
import create from "zustand";
import mixpanel from "mixpanel-browser";
const useStore = create((set) => ({
  isLoginModalVisible: false,
  showLoginModal: () => set({ isLoginModalVisible: true }),
  hideLoginModal: () => set({ isLoginModalVisible: false }),
  // Loading state management
  isLoading: false,
  showLoading: () => set({ isLoading: true }),
  hideLoading: () => setTimeout(() => set({ isLoading: false }), 300), // Delay of 1 second
  // Modal state management

  isShowingTutorial: false,
  tutorialStep: 0,
  showTutorial: () => set({ isShowingTutorial: true }),
  incrementTutorialStep: () =>
    set((state) => ({ tutorialStep: state.tutorialStep + 1 })),

  isShowingIntroModal: false,
  showIntroModal: () => set({ isShowingIntroModal: true }),
  hideIntroModal: () => set({ isShowingIntroModal: false }),
  isShowingTutoModal: false,
  showTutoModal: () => set({ isShowingTutoModal: true }),
  hideTutoModal: () => set({ isShowingTutoModal: false }),

  isShowingModalCredit: false,
  showCreditModal: () => set({ isShowingModalCredit: true }),
  hideCreditModal: () => set({ isShowingModalCredit: false }),
  isModalVisible: false,
  modalConfig: {},
  inputValue: "",
  showModal: (content, config = {}) => {
    set({
      isModalVisible: true,
      modalConfig: { content, ...config },
      inputValue: config.initialInputValue || "",
    });
  },
  handleInputChange: (e) => {
    set({ inputValue: e.target.value });
  },
  hideModal: () => {
    set({
      isModalVisible: false,
      modalConfig: {},
      inputValue: "",
    });
  },
}));

const GlobalModal = () => {
  const {
    isModalVisible,
    modalConfig,
    hideModal,
    inputValue,
    handleInputChange,
  } = useStore();

  const handleOk = () => {
    if (modalConfig.onOk) {
      modalConfig.onOk(inputValue);
    }
    hideModal();
  };

  const handleCancel = () => {
    if (modalConfig.onCancel) {
      modalConfig.onCancel();
    }
    hideModal();
  };

  return (
    <Modal
      visible={isModalVisible}
      onCancel={handleCancel}
      footer={
        modalConfig.isInfoModal
          ? [
              <Button key="ok" type="primary" onClick={handleOk}>
                {modalConfig.okText || "OK"}
              </Button>,
            ]
          : [
              <Button key="cancel" onClick={handleCancel}>
                {modalConfig.cancelText || "Cancel"}
              </Button>,
              <Button key="ok" type="primary" onClick={handleOk}>
                {modalConfig.okText || "OK"}
              </Button>,
            ]
      }
      {...modalConfig}
    >
      {modalConfig.content}
      {modalConfig.input && (
        <Input
          style={{ marginTop: 10 }}
          autoFocus={true}
          value={inputValue}
          onChange={handleInputChange}
          placeholder={modalConfig.inputPlaceholder || "Enter text"}
        />
      )}
    </Modal>
  );
};

export { useStore, GlobalModal };
