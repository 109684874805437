import React, { useEffect } from "react";

const TestComponent = () => {
  useEffect(() => {
    const handleBeforeUnload = (e) => {
      e.preventDefault(); // Prevents the default browser action
      e.returnValue = ""; // Chrome requires returnValue to be set
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    // Cleanup function
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []); // Empty dependency array ensures this runs once on mount

  return (
    <div>
      <h1>Test Component</h1>
      <p>Try to leave or refresh the page.</p>
    </div>
  );
};

export default TestComponent;
