// authService.js
import axios from "axios";
import api from "./apiInfo";
import { axiosInstance, setAccessAndRefreshTokens } from "./AxiosSetting";
import { message } from "antd";
import mixpanel from "mixpanel-browser";
export const loginService = async (credentials) => {
  try {
    console.log("credentials", credentials);
    let url = "login/";
    console.log("url", url);

    const response = await axiosInstance.post(url, credentials);
    let accessToken = response.data.accessToken;
    let refreshToken = response.data.refreshToken;
    setAccessAndRefreshTokens(accessToken, refreshToken);
    console.log("Logged In");
    let responseUser = await getUserInfoWithEmail(response.data.email);

    // No need to check for response.ok or call .json() with axios
    return responseUser.user;
  } catch (error) {
    console.error("Failed to login:", error);
    // With axios, the error.response object contains the server response
    const errorMessage = error.response?.data?.message || error.message;
    throw new Error(`Error: ${errorMessage}`);
  }
};
export const loginCustomService = async (access_token) => {
  try {
    console.log("access_token", access_token);
    let url = "logincustom/";
    console.log("url", url);

    const response = await axiosInstance.post(url, access_token);
    let accessToken = response.data.accessToken;
    let refreshToken = response.data.refreshToken;
    setAccessAndRefreshTokens(accessToken, refreshToken);
    console.log("Logged In");
    let responseUser = await getUserInfoWithEmail(response.data.email);
    mixpanel.identify(response.data.email);
    // No need to check for response.ok or call .json() with axios
    return responseUser.user;
  } catch (error) {
    console.error("Failed to login:", error);
    // With axios, the error.response object contains the server response
    const errorMessage = error.response?.data?.message || error.message;
    throw new Error(`Error: ${errorMessage}`);
  }
};
export const fakeloginTemp = async () => {
  try {
    let url = "fakelogin/";
    console.log("url", url);
    message.info(url);
    const response = await axiosInstance.post(url);

    let accessToken = response.data.accessToken;
    let refreshToken = response.data.refreshToken;
    setAccessAndRefreshTokens(accessToken, refreshToken);
    console.log("Logged In");
    let responseUser = await getUserInfoWithEmail(response.data.email);

    // No need to check for response.ok or call .json() with axios
    return responseUser.user;
  } catch (error) {
    console.error("Failed to login:", error);
    // With axios, the error.response object contains the server response
    const errorMessage = error.response?.data?.message || error.message;
    throw new Error(`Error: ${errorMessage}`);
  }
};

const getUserInfoWithEmail = async (email) => {
  try {
    let url = "user/";
    let obj = {};
    let response = await axiosInstance.get(url, {
      params: {
        email: email,
      },
    });
    //console.log(response.data);
    return response.data;
    // No need to check for response.ok or call .json() with axios
  } catch (error) {
    console.error("Failed to login:", error);
    // With axios, the error.response object contains the server response
    const errorMessage = error.response?.data?.message || error.message;
    throw new Error(`Error: ${errorMessage}`);
  }
};
export const refetchUser = async (email) => {
  try {
    let url = "user/";
    let obj = {};
    let response = await axiosInstance.get(url, {
      params: {
        email: email,
      },
    });
    //console.log(response.data);
    return response.data;
    // No need to check for response.ok or call .json() with axios
  } catch (error) {
    console.error("Failed to login:", error);
    // With axios, the error.response object contains the server response
    const errorMessage = error.response?.data?.message || error.message;
    throw new Error(`Error: ${errorMessage}`);
  }
};
