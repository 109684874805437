import React from "react";
import { Layout, Typography, Avatar } from "antd";
const { Content } = Layout;

export const BasicPage = () => {
  return (
    <Content style={{ padding: "150px" }}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography.Title level={4}>{"HEHEHEHEHE"}</Typography.Title>
      </div>
    </Content>
  );
};
