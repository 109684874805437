import create from "zustand";
import { persist } from "zustand/middleware";
import { useStore } from "./useStore";
const { showModal } = useStore.getState();

export const useAuthStore = create(
  persist(
    (set, get) => ({
      user: null,
      accessToken: null,
      refreshToken: null,
      isGeneratingCover: false,
      setIsGeneratingCover: (generating) => {
        set({ isGeneratingCover: generating });
      },
      setArtistName: (artistName) => {
        set((state) => {
          return {
            user: {
              ...state.user,
              artistName: artistName,
            },
          };
        });
      },
      decrementCredits: () => {
        set((state) => {
          if (state.user && state.user.credits > 0) {
            // Decrement credits and trigger animation
            return {
              user: {
                ...state.user,
                credits: state.user.credits - 1,
              },
              shouldAnimateCredits: true,
            };
          }
          showModal(
            <p>
              Sorry you have no credit left, purchase some credits if you want
              to continue
            </p>,
            {
              title: "Oops, you have no credit left",
              isInfoModal: true,
              okText: "Ok",
              onOk: () => console.log("Info OK clicked"),
            }
          );
          return {};
        });
      },
      shouldAnimateCredits: false,
      clearAnimation: () => {
        set({ shouldAnimateCredits: false });
      },
      setTokens: (accessToken, refreshToken) =>
        set({ accessToken, refreshToken }),
      clearTokens: () => set({ accessToken: null, refreshToken: null }),
      setUser: (user) => set({ user }),
      clearUser: () => {
        set({ isGeneratingCover: false });
        set({ user: null });
        set({ accessToken: null, refreshToken: null });
      },
      addProjectToUser: (newProject) =>
        set((state) => {
          const updatedProjects = state.user?.projects
            ? [newProject, ...state.user.projects]
            : [newProject];

          return { user: { ...state.user, projects: updatedProjects } };
        }),
      addNewProjectToUser: (newProject) =>
        set((state) => {
          const updatedProjects = state.user?.projects
            ? [newProject, ...state.user.projects]
            : [newProject];

          return { user: { ...state.user, projects: updatedProjects } };
        }),
      updateProject: (updatedProject) => {
        set((state) => {
          if (state.user && state.user.projects) {
            // Find the index of the project to be updated
            const projectIndex = state.user.projects.findIndex(
              (project) => project._id === updatedProject._id
            );

            if (projectIndex !== -1) {
              // Update the project at the found index
              const updatedProjects = [...state.user.projects];
              updatedProjects[projectIndex] = updatedProject;

              // Set the updated projects array back into the state
              return {
                user: {
                  ...state.user,
                  projects: updatedProjects,
                },
              };
            }
          }
          // In case no project is found, return the existing state
          return state;
        });
      },
      updateCover: (updatedCover, project_id) => {
        set((state) => {
          if (state.user && state.user.projects) {
            // Find the index of the project to be updated
            const projectIndex = state.user.projects.findIndex(
              (project) => project._id === project_id
            );

            if (projectIndex !== -1) {
              // Update the project at the found index
              const updatedProjects = [...state.user.projects];
              let projectToUpdate = updatedProjects[projectIndex];
              projectToUpdate.cover = updatedCover;
              updatedProjects[projectIndex] = projectToUpdate;

              // Set the updated projects array back into the state
              return {
                user: {
                  ...state.user,
                  projects: updatedProjects,
                },
              };
            }
          }
          // In case no project is found, return the existing state
          return state;
        });
      },
      /*generatingImage: (project_id, imageDescription_id, generating) =>
        set((state) => {
          // Find the project in the user's projects
          const projects = state.user.projects;
          const projectIndex = projects.findIndex((p) => p._id === project_id);

          if (projectIndex !== -1) {
            console.log("Generate an image from", projects[projectIndex].name);

            // Filter out the idea from imageDescriptions
            let imageDescriptionsOfproject =
              projects[projectIndex].imageDescriptions;
            const imageDescriptionIndex = imageDescriptionsOfproject.findIndex(
              (p) => p._id === imageDescription_id
            );
            imageDescriptionsOfproject[imageDescriptionIndex].generating =
              generating;
            console.log(imageDescriptionsOfproject);
            projects[projectIndex].imageDescriptions =
              imageDescriptionsOfproject;
          }

          // Return the updated state
          return {
            user: {
              ...state.user,
              projects: [...projects], // Creating a new array for immutability
            },
          };
        }),*/
      deleteIdea: (project_id, id) =>
        set((state) => {
          // Find the project in the user's projects
          const projects = state.user.projects;
          const projectIndex = projects.findIndex((p) => p._id === project_id);

          console.log("id to remove", id);
          if (projectIndex !== -1) {
            console.log("Remove an idea from", projects[projectIndex].name);
            console.log(
              "taille avant",
              projects[projectIndex].imageDescriptions.length
            );
            // Filter out the idea from imageDescriptions
            const updatedImageDescriptions = projects[
              projectIndex
            ].imageDescriptions.filter((item) => item._id !== id);
            projects[projectIndex].imageDescriptions = updatedImageDescriptions;
            console.log("taille ap", updatedImageDescriptions.length);
          }

          // Return the updated state
          return {
            user: {
              ...state.user,
              projects: [...projects], // Creating a new array for immutability
            },
          };
        }),
      addNewIdeas: (project_id, ideas) =>
        set((state) => {
          const projects = state.user.projects;
          const projectIndex = projects.findIndex((p) => p._id === project_id);

          if (projectIndex !== -1) {
            console.log("Add an idea to ", projects[projectIndex].name);
            // Filter out the idea from imageDescriptions
            const updatedImageDescriptions = [
              ...projects[projectIndex].imageDescriptions,
              ...ideas,
            ];
            projects[projectIndex].imageDescriptions = updatedImageDescriptions;
          }

          return { user: { ...state.user, projects: projects } };
        }),
      addNewImage: (project_id, image) =>
        set((state) => {
          const projects = state.user.projects;
          const projectIndex = projects.findIndex((p) => p._id === project_id);

          if (projectIndex !== -1) {
            console.log("Add an image to ", projects[projectIndex].name);
            // Filter out the idea from imageDescriptions
            projects[projectIndex].images.push(image);
          }

          return { user: { ...state.user, projects: projects } };
        }),
    }),
    {
      name: "auth", // unique name for localStorage key, e.g., "auth"
      getStorage: () => localStorage, // define localStorage as the storage option
    }
  )
);
