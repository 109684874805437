import React, { useEffect, useState } from "react";
import { TypingText } from "../fx/TypingText";
import { motion } from "framer-motion";
import { Layout, Typography, Input, Button, Avatar, Card } from "antd";
import style from "../../Styles/style";
import { useProjectService } from "../../services/projectService";
import useProjectStore from "../../store/useProjectStore";
import mixpanel from "mixpanel-browser";

const { Title, Paragraph } = Typography;
export const NameCreatorComponent = ({ onValidated }) => {
  const [nameInput, setnameInput] = useState("");

  const [saveDisabled, setSaveDisabled] = useState(false);
  const { updateName } = useProjectService();
  const { setProjectName, projectName, selectedProject } = useProjectStore();
  const handleInputChange = (e) => {
    setProjectName(e.target.value);
  };
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Call it once to set initial state based on current window size

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleSubmit = () => {
    mixpanel.track("Added a name");
    setProjectName(nameInput);
    updateName(selectedProject._id, projectName, () => {});
    // Add validation logic here if needed
    // If validation passes, call onValidated to go to the next step
    onValidated();
  };
  const handleEnterPress = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault(); // Prevent default to avoid a new line being entered
      console.log("Enter pressed");
      handleSubmit();
      // Your function logic here
    }
  };

  const projectVariants = {
    hidden: { opacity: 0, y: 50 },
    transition: {
      y: { stiffness: 1000, velocity: -10 },
    },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        staggerChildren: 0.2, // 100ms stagger
      },
    },
    exit: { opacity: 0, y: -20 },
  };
  useEffect(() => {
    console.log("Name create Visible");
    if (selectedProject?.isNew) {
      setProjectName("");
    }
  }, []);

  return (
    <div
      style={{
        width: "100%",
        backgroundColor: style.colors.background0,
        padding: 15,
        display: "flex",
        justifyContent: "center", // Centers horizontally
        minHeight: "100%", // Takes full viewport height
        backgroundImage: "url('/nameback.png')", // Add your image path here
        backgroundSize: "cover", // Ensure the image covers the whole div
        backgroundRepeat: "no-repeat", // Prevent the image from repeating
        backgroundPosition: "bottom",
      }}
    >
      <motion.div
        initial="hidden"
        animate="visible"
        exit="exit" // Define the exit animation
        variants={projectVariants}
        transition={{ duration: 2.0 }} // Adjust the timing as needed
        style={{
          marginTop: isMobile ? 50 : 100,
          width: "100%", // Set the width to 100% instead of minWidth
          maxWidth: 700, // Use maxWidth for responsiveness
          display: "flex",
          flexDirection: "column",
        }}
      >
        <TypingText
          text={`What's the name of your song/album ?`}
          onComplete={() => {}}
        />
        <Card
          style={{
            maxWidth: 1000,
            width: "100%",
            margin: 0,
            backgroundColor: style.colors.background1,
          }}
        >
          <Paragraph style={{ color: style.colors.textSoft }}>
            Choose a name in whatever you wants.
          </Paragraph>
          <Input
            autoFocus={!isMobile}
            value={projectName}
            onKeyDown={handleEnterPress}
            onChange={handleInputChange}
            type="text"
            placeholder="TYPE IN THE NAME"
            style={{ height: 50, marginTop: 5, fontSize: 16 }}
          />

          <Button
            onClick={handleSubmit}
            type="primary"
            style={{
              position: "absolute",

              backgroundColor: style.colors.color,
              marginTop: 15,
              right: 30,
              width: 100,

              zIndex: 1,
            }}
          >
            <div>
              <span>Validate</span>
            </div>
          </Button>
        </Card>
      </motion.div>
    </div>
  );
};
