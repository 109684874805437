// LoadingPopup.js
import React from "react";
import { Spin } from "antd";
import { useStore } from "../store/useStore.js";
import style from "../Styles/style.js";

const LoadingPopup = () => {
  const isLoading = useStore((state) => state.isLoading);

  if (!isLoading) return null;

  return (
    <div
      style={{
        position: "fixed",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        backgroundColor: style.colors.background1,
        display: "flex",
        justifyContent: "center", // Center horizontally
        alignItems: "center",
        zIndex: 1000, // Ensure it's above everything else
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center", // Center horizontally
          alignItems: "center",
          backgroundColor: style.colors.background1,
          width: 100,
          height: 100,
        }}
      >
        <Spin size="large" />
      </div>
    </div>
  );
};

export default LoadingPopup;
