import React from "react";
import { Link, Navigate, useOutlet } from "react-router-dom";
import { AppBar } from "./AppBar";
import { Layout, Space } from "antd";
import { useAuth } from "../hooks/useAuth"; // Update the path to the useAuth file accordingly
import style from "../Styles/style";
import { useAuthStore } from "../store/useAuthStore";
import { AppBarIntro } from "./AppBarIntro";
const { Header, Footer, Sider, Content } = Layout;
export const FirstConnection = () => {
  const { user } = useAuthStore();
  const outlet = useOutlet();

  if (!user) {
    return <Navigate to="/" />;
  }

  const fullHeightStyle = {
    height: "100vh", // 100% of the viewport height
    display: "flex",
    flexDirection: "column",
  };
  return (
    <div>
      <Layout style={fullHeightStyle}>
        {" "}
        {/* Apply the style here */}
        <AppBarIntro pages={[{ label: "Login", path: "login" }]} />
        <Layout>
          {" "}
          {/* Add padding to account for fixed Sider */}
          <Content
            style={{

              minHeight: "280px",
            }}
          >
            {outlet}
          </Content>
        </Layout>
      </Layout>
    </div>
  );
};

export default FirstConnection;
