import React, { useState } from "react";
import { Modal, Button, Typography, Card } from "antd";
import { useStore } from "../../store/useStore";
import style from "../../Styles/style";
import { useNavigate } from "react-router-dom";
import {
  PlayCircleOutlined,
  ThunderboltOutlined,
  RobotOutlined,
  FormatPainterFilled,
  CheckCircleOutlined,
  RiseOutlined,
} from "@ant-design/icons";
function TutoModal({ title, content, onCancel }) {
  const navigate = useNavigate();
  const { Title, Text } = Typography;
  const { isShowingTutoModal, hideTutoModal } = useStore();

  const handleCancel = () => {
    hideTutoModal();
    onCancel();
  };

  return (
    <Modal visible={isShowingTutoModal} onCancel={handleCancel} footer={null}>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Title level={3}>{title}</Title>

        {content}
        <Button
          size="small"
          style={{
            marginTop: 30,
            height: 50,
            fontSize: 20,
            fontWeight: 500,
            borderRadius: 20,
            borderColor: style.colors.color,
            width: "100%",
            alignSelf: "center",
          }}
          onClick={() => {
            handleCancel();
          }}
        >
          Ok
        </Button>
      </div>
    </Modal>
  );
}

export default TutoModal;
