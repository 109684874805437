import React, { useEffect, useState } from "react";
import { TypingText } from "../fx/TypingText";
import { motion } from "framer-motion";
import {
  Layout,
  Typography,
  Input,
  Button,
  Avatar,
  Card,
  Row,
  Image,
  Col,
  Divider,
  Spin,
} from "antd";

import instaplaceholder from "../../images/insta-com.png";
import youtubeplaceholder from "../../images/youtube-com.png";
import insta from "../../images/insta.png";
import youtube from "../../images/youtube.png";
import style from "../../Styles/style";
import { useStore } from "../../store/useStore";
import useProjectStore from "../../store/useProjectStore";
import {
  useGenerateIdeasService,
  useProjectService,
} from "../../services/projectService";
import { useAuthStore } from "../../store/useAuthStore";
import { ImagePlatformComponent } from "./ImagePlatformComponent";
import mixpanel from "mixpanel-browser";
const { Title, Paragraph, Text } = Typography;
export const CoverCreatorComponent = ({ onValidated }) => {
  const [nameInput, setnameInput] = useState("");
  const { user } = useAuthStore();

  const [isDirty, setIsDirty] = useState(false);
  const { showModal } = useStore();
  const { selectedImage, setSelectedImage, cover } = useProjectStore();
  const { isGeneratingCover } = useAuthStore();
  const [isUpscaling, setIsUpscaling] = useState(false);
  const [shouldGenerateVertical, setShouldGenerateVertical] = useState(false);
  const [shouldGenerateHorizontal, setShouldGenerateHorizontal] =
    useState(false);
  const { createContentAsset, upScaleCover } = useGenerateIdeasService();
  const {
    actualPrompt,
    setActualPrompt,
    selectedProject,
    instagramImage,
    youtubeImage,
    setInstagramImage,
    setYoutubeImage,
  } = useProjectStore();
  // State for the selected image

  const [info, setInfo] = useState({
    artist: user?.artistName || user?.name,
    title: selectedProject.title,
    text: "Available now",
  });

  function downloadUpscaled(url, name) {
    mixpanel.track("Download upscaled");
    downloadFile(url, name);
  }
  function download1024(url, name) {
    mixpanel.track("Download 1024");
    downloadFile(url, name);
  }
  const downloadFile = (url, name) => {
    const a = document.createElement("a");
    a.href = url;
    a.download = name + ".png";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };
  const changeInfo = (e, field) => {
    setIsDirty(true);
    setInfo({ ...info, [field]: e.target.value });
  };
  const handlePromptChange = (e) => {
    setActualPrompt(e.target.value);
  };
  const upscale = () => {
    mixpanel.track("Click upscale");
    setIsUpscaling(true);
    upScaleCover(cover, selectedProject._id, () => {
      setIsUpscaling(false);
    });
  };

  const handleHorizontalImageCreation = (dataURL) => {
    mixpanel.track("Youtube Image Generation");
    /*setIsDirty(false);
    setYoutubeImage(dataURL);
    setShouldGenerateHorizontal(false);
    return;*/
    createContentAsset(
      selectedProject._id,
      "youtube",
      info.artist,
      info.title,
      info.text,
      dataURL,
      () => {
        setIsDirty(false);
        setYoutubeImage(dataURL);
        setShouldGenerateHorizontal(false);
      },
      () => {
        setIsDirty(false);
        setShouldGenerateHorizontal(false);
      }
    );
  };
  const handleVerticalImageCreation = (dataURL) => {
    /*setIsDirty(false);
    setInstagramImage(dataURL);
    setShouldGenerateVertical(false);
        return;*/
    mixpanel.track("Instagram Image Generation");
    createContentAsset(
      selectedProject._id,
      "instagram",
      info.artist,
      info.title,
      info.text,
      dataURL,
      () => {
        setIsDirty(false);
        setInstagramImage(dataURL);
        setShouldGenerateVertical(false);
      },
      () => {
        setIsDirty(false);
        setShouldGenerateVertical(false);
      }
    );
  };
  function generateVertical() {
    for (const key in info) {
      if (info[key] === "") {
        showModal(<p>Attention the field {key} is missing.</p>, {
          title: "Info missing",
          isInfoModal: true,
          okText: "ok",
          onOk: () => {},
        });
        return;
      }
    }
    setShouldGenerateVertical(true);
  }
  function generateHorizontal() {
    for (const key in info) {
      if (info[key] === "") {
        showModal(<p>Attention the field {key} is missing.</p>, {
          title: "Info missing",
          isInfoModal: true,
          okText: "ok",
          onOk: () => {},
        });
        return;
      }
    }
    setShouldGenerateHorizontal(true);
  }
  const handleSubmit = () => {
    // Add validation logic here if needed
    // If validation passes, call onValidated to go to the next step
    onValidated();
  };

  const projectVariants = {
    hidden: { opacity: 0, y: 50 },
    transition: {
      y: { stiffness: 1000, velocity: -10 },
    },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        staggerChildren: 0.2, // 100ms stagger
      },
    },
    exit: { opacity: 0, y: -20 },
  };
  useEffect(() => {
    console.log("Geneate Image Visible");
    setCoverInfo();
  }, []);

  function setCoverInfo() {
    setInfo({
      artist: selectedProject?.cover?.artistInfo || user.artistName || "",
      title: selectedProject?.cover?.titleInfo || "",
      text: selectedProject?.cover?.textInfo || "Available now",
    });
  }
  return (
    <div
      style={{
        width: "100%",
        backgroundColor: style.colors.background0,
        padding: 20,
        display: "flex",
        justifyContent: "center", // Centers horizontally
        minHeight: "100vh", // Takes full viewport height
      }}
    >
      <motion.div
        initial="hidden"
        animate="visible"
        exit="exit" // Define the exit animation
        variants={projectVariants}
        transition={{ duration: 2.0 }} // Adjust the timing as needed
        style={{
          marginTop: 30,
          width: "100%", // Set the width to 100% instead of minWidth
          // Use maxWidth for responsiveness
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Row style={{ width: "100%" }}>
          <Col xs={24} sm={24} md={24} lg={24} xl={11}>
            <div
              style={{
                with: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              {" "}
              <TypingText text={`Here is your cover.`} onComplete={() => {}} />
            </div>

            <div
              style={{
                marginTop: 30,
                width: "100%",
                minHeight: 400,
                maxHeight: 500,
                display: "flex",
                justifyContent: "center",
                alignItems: "center", // Aligns items vertically in the center
              }}
            >
              <Image
                placeholder={true}
                src={selectedProject?.cover?.url}
                style={{
                  minHeight: 400,
                  maxHeight: 500,
                  maxWidth: "100%", // Ensures the image doesn't stretch beyond its container
                  objectFit: "cover",
                  // Adds space between image and buttons
                  border: "0px solid white",
                }}
              />
            </div>

            <Row
              gutter={24}
              style={{
                display: "flex",
                justifyContent: "center", // Centers buttons horizontally
                marginTop: 20, // Adds space between image and buttons
              }}
            >
              <Col
                xs={24}
                sm={24}
                md={12}
                lg={12}
                xl={12}
                style={{
                  padding: "0 5%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Button
                  type={cover?.isUpscaled ? "primary" : "default"}
                  onClick={() => {
                    if (cover?.isUpscaled) {
                      downloadUpscaled(
                        selectedProject?.cover?.upscaledUrl,
                        selectedProject?.name
                      );
                    } else {
                      download1024(
                        selectedProject?.cover?.url,
                        selectedProject?.name
                      );
                    }
                  }}
                  style={{
                    borderColor: style.colors.color,
                    paddingLeft: 30,
                    paddingRight: 30,
                    width: "100%",
                    marginTop: 16,
                    maxWidth: 350,
                  }}
                >
                  {cover?.isUpscaled ? "Download" : "Download in 1024x1024"}
                </Button>
              </Col>
              <Col
                xs={24}
                sm={24}
                md={12}
                lg={12}
                xl={12}
                style={{
                  padding: "0 5%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                {!cover?.isUpscaled && (
                  <div
                    style={{
                      marginLeft: 10,
                      width: "100%",
                      maxWidth: 350,
                      marginTop: 16,
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "column",
                      textAlign: "cneter",
                    }}
                  >
                    <Button
                      style={{
                        paddingLeft: 30,
                        paddingRight: 30,
                        width: "100%",
                        maxWidth: 350,
                      }}
                      type="primary"
                      loading={isUpscaling}
                      onClick={() => {
                        upscale();
                      }}
                    >
                      Upscale for spotify
                    </Button>
                    <div
                      style={{
                        marginTop: 4,
                        textAlign: "center",
                        width: "100%",
                      }}
                    >
                      {" "}
                      {/* Add this line for the recommended text */}
                      <Text>(recommended)</Text>
                    </div>
                  </div>
                )}
              </Col>
            </Row>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={1}>
            <Divider type="vertical" style={{ height: "100%" }}></Divider>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={11}>
            <Title style={{ marginTop: 5 }} level={5}>
              Communication assets.
            </Title>

            <Paragraph style={{ color: style.colors.textSoft }}>
              Create vertical and horizontal images to promote the release of
              your song. (Fill below the information to appear on the contents)
            </Paragraph>
            <div
              style={{
                width: "100%",
                margin: 0,
                padding: 10,
                backgroundColor: style.colors.background0,
              }}
            >
              <div style={{ display: "flex", flexDirection: "column" }}>
                <Text style={{ marginTop: 15 }}>Your artist name</Text>
                <Input
                  style={{ backgroundColor: style.colors.background1 }}
                  placeholder="enter your artist name"
                  value={info.artist}
                  onChange={(e) => changeInfo(e, "artist")}
                />
                <Text style={{ marginTop: 15 }}>Your song's title</Text>
                <Input
                  style={{ backgroundColor: style.colors.background1 }}
                  placeholder="enter your song's name"
                  value={info.title}
                  onChange={(e) => changeInfo(e, "title")}
                />
                <Text style={{ marginTop: 15 }}>Text</Text>
                <Input
                  style={{ backgroundColor: style.colors.background1 }}
                  placeholder="available now"
                  value={info.text}
                  onChange={(e) => changeInfo(e, "text")}
                />
              </div>
            </div>

            <Row
              style={{
                margin: 0,

                marginTop: 20,
                width: "100%",
              }}
            >
              <Col style={{ padding: 10 }} xs={24} sm={12} md={12} xl={12}>
                <Row
                  style={{
                    margin: 0,
                    width: "100%",
                  }}
                >
                  <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "100%",
                        height: "100%",
                      }}
                    >
                      <img
                        src={insta}
                        style={{ height: "40px" }}
                        alt="Instagram"
                      />
                    </div>
                  </Col>
                  <Col xs={20} sm={20} md={20} lg={20} xl={20}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        width: "100%",
                        height: "100%",
                      }}
                    >
                      <Paragraph
                        style={{
                          color: style.colors.text,
                          width: "100%",
                          height: "10%",
                          marginLeft: 10,
                        }}
                      >
                        Instagram vertical (Story)
                      </Paragraph>
                    </div>
                  </Col>
                </Row>
                <Card
                  loading={shouldGenerateVertical}
                  style={{
                    marginTop: 15,
                    backgroundColor: style.colors.background1,
                  }}
                >
                  <Spin spinning={false}>
                    <img
                      src={instagramImage || instaplaceholder}
                      style={{ width: "100%", marginRight: "10px" }}
                      alt="Communication"
                    />
                    {(isDirty || !instagramImage) && (
                      <Button
                        type="primary"
                        style={{
                          margin: 0,
                          width: "100%",
                        }}
                        onClick={() => {
                          generateVertical();
                        }}
                        loading={shouldGenerateVertical}
                      >
                        {isDirty && instagramImage ? "Update" : "Generate"}
                      </Button>
                    )}

                    {instagramImage && (
                      <Button
                        type={isDirty && instagramImage ? "default" : "primary"}
                        style={{
                          margin: 0,
                          marginTop: 15,
                          width: "100%",
                        }}
                        onClick={() => {
                          downloadFile(
                            instagramImage,
                            selectedProject?.name + " instagram"
                          );
                        }}
                        loading={isGeneratingCover}
                      >
                        Download
                      </Button>
                    )}
                  </Spin>
                </Card>
              </Col>
              <Col style={{ padding: 10 }} xs={24} sm={12} md={12} xl={12}>
                <Row
                  style={{
                    margin: 0,
                    width: "100%",
                  }}
                >
                  <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "100%",
                        height: "100%",
                      }}
                    >
                      <img
                        src={youtube}
                        style={{ height: "40px" }}
                        alt="Youtube"
                      />
                    </div>
                  </Col>
                  <Col xs={20} sm={20} md={20} lg={20} xl={20}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        width: "100%",
                        height: "100%",
                      }}
                    >
                      <Paragraph
                        style={{
                          color: style.colors.text,
                          width: "100%",
                          height: "10%",
                          marginLeft: 10,
                        }}
                      >
                        Youtube Horizontal
                      </Paragraph>
                    </div>
                  </Col>
                </Row>
                <Card
                  loading={shouldGenerateVertical}
                  style={{
                    marginTop: 15,
                    backgroundColor: style.colors.background1,
                  }}
                >
                  <Spin spinning={false}>
                    <img
                      src={youtubeImage || youtubeplaceholder}
                      style={{ width: "100%", marginRight: "10px" }}
                      alt="Communication"
                    />
                    {(isDirty || !youtubeImage) && (
                      <Button
                        type="primary"
                        style={{
                          margin: 0,
                          width: "100%",
                        }}
                        onClick={() => {
                          generateHorizontal();
                        }}
                        loading={shouldGenerateHorizontal}
                      >
                        {isDirty && youtubeImage ? "Update" : "Generate"}
                      </Button>
                    )}

                    {youtubeImage && (
                      <Button
                        type={isDirty && youtubeImage ? "default" : "primary"}
                        style={{
                          margin: 0,
                          marginTop: 15,
                          width: "100%",
                        }}
                        onClick={() => {
                          downloadFile(
                            youtubeImage,
                            selectedProject?.name + " youtube"
                          );
                        }}
                        loading={isGeneratingCover}
                      >
                        Download
                      </Button>
                    )}
                  </Spin>
                </Card>
              </Col>
            </Row>

            <ImagePlatformComponent
              onImageCreated={handleVerticalImageCreation}
              onHorizontalCreated={handleHorizontalImageCreation}
              background={selectedProject.cover?.url}
              shouldgenerateVertical={shouldGenerateVertical}
              shouldgenerateHorizontal={shouldGenerateHorizontal}
              info={info}
            ></ImagePlatformComponent>
          </Col>
        </Row>
      </motion.div>
    </div>
  );
};
