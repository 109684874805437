import React from "react";
import { Typography, Form, Input, Button, Space, Layout, Result } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import { Link as RouterLink } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import style from "../Styles/style";

const { Title, Paragraph } = Typography;
const { Content } = Layout;

export const PrivacyPolicyPage = () => {
  return (
    <Layout>
      <Content style={{}}>
        <div
          style={{
            background: style.colors.background0,
            padding: 100,
            minHeight: 280,
            paddingLeft: "10%",
            paddingRight: "10%",
          }}
        >
          <Typography>
            <Title>Privacy Policy for TonalPixel</Title>
            <Paragraph>Last Updated: [21/12/23]</Paragraph>

            <Title level={2}>1. Introduction</Title>
            <Paragraph>
              Welcome to TonalPixel. Our commitment to your privacy and data
              security is paramount. This Privacy Policy details our practices
              in handling the information we collect and use through our
              services, in compliance with global data protection regulations.
            </Paragraph>

            <Title level={2}>2. Information Collection and Use</Title>
            <Paragraph>
              <ul>
                <li>
                  Google OAuth for Onboarding: We use Google OAuth to simplify
                  your sign-up process. Through this, we collect your name and
                  email address for account creation and communication purposes.
                </li>
                <li>
                  Usage Data: To enhance our services, we collect anonymous
                  usage metrics. This data is strictly for internal analysis and
                  is not tied to your personal information.
                </li>
              </ul>
            </Paragraph>

            <Title level={2}>3. Data Storage and Security</Title>
            <Paragraph>
              <ul>
                <li>
                  Secure Storage: Your data, including email addresses, is
                  securely stored in Atlas MongoDB, adhering to robust security
                  measures.
                </li>
                <li>
                  Backend Security: We maintain high-security standards in our
                  backend processes, including secure token exchanges with
                  Google OAuth, ensuring the confidentiality and integrity of
                  your data.
                </li>
              </ul>
            </Paragraph>

            <Title level={2}>4. Our Stance on Cookies and Tracking</Title>
            <Paragraph>
              We respect your privacy to the fullest extent and do not employ
              cookies or tracking technologies on our platform.
            </Paragraph>

            <Title level={2}>5. Data Retention and Deletion</Title>
            <Paragraph>
              We retain personal data only as long as necessary for the purposes
              outlined in this policy. We also provide mechanisms for data
              deletion upon user request.
            </Paragraph>

            <Title level={2}>6. Your Rights</Title>
            <Paragraph>
              As a user, you have the right to access, correct, or request the
              deletion of your personal data. You may also inquire about our
              data handling practices.
            </Paragraph>

            <Title level={2}>7. Changes to This Privacy Policy</Title>
            <Paragraph>
              Our Privacy Policy may evolve over time. We will notify you of
              significant changes and always maintain the latest version
              accessible on our platform.
            </Paragraph>

            <Title level={2}>8. Contact Us</Title>
            <Paragraph>
              For any privacy-related inquiries or concerns, please contact us
              at tonalpixel@gmail.com
            </Paragraph>
          </Typography>
        </div>
      </Content>
    </Layout>
  );
};
