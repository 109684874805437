import React, { useState, useEffect } from "react";
import { Button, Select } from "antd";
import WebFont from "webfontloader";
import { UpOutlined, DownOutlined } from "@ant-design/icons";
import "./googlefontselect.css";
import style from "../../Styles/style";

const { Option } = Select;
const googleFontApi = process.env.REACT_APP_GOOGLE_FONT_KEY;
const GoogleFontsSelect = ({ onFontChange, font }) => {
  const [allFonts, setAllFonts] = useState([]);
  const [fontData, setFontData] = useState([]);
  const [filteredFonts, setFilteredFonts] = useState([]);
  const [selectedFontIndex, setSelectedFontIndex] = useState(0);
  const [category, setCategory] = useState("All");

  useEffect(() => {
    fetch(
      `https://www.googleapis.com/webfonts/v1/webfonts?sort=popularity&key=${googleFontApi}`
    )
      .then((response) => response.json())
      .then((data) => {
        const googleFonts = data.items.map((item) => item.family);
        setFontData(data.items);
        setAllFonts(googleFonts);
        setFilteredFonts(googleFonts);
        if (!font) {
          handleFontChange(googleFonts[0]);
        }
      })
      .catch((error) => console.error("Error fetching Google Fonts:", error));
  }, []);

  useEffect(() => {
    const applyFilter = (category) => {
      if (category === "All") {
        setFilteredFonts(allFonts);
      } else {
        const filtered = fontData
          .filter((item) => item.category === category)
          .map((item) => item.family);
        setFilteredFonts(filtered);
        handleFontChange(filtered[0]);
      }
    };

    applyFilter(category);
  }, [category, allFonts]);

  const handleFontChange = (selectedFont) => {
    const index = filteredFonts.indexOf(selectedFont);
    setSelectedFontIndex(index);

    WebFont.load({
      google: {
        families: [selectedFont],
      },
      active: () => {
        if (onFontChange) {
          onFontChange(selectedFont);
        }
      },
    });
  };
  const handleArrowUp = () => {
    if (selectedFontIndex > 0) {
      setSelectedFontIndex(selectedFontIndex - 1);
      handleFontChange(filteredFonts[selectedFontIndex - 1]);
    }
  };

  const handleArrowDown = () => {
    if (selectedFontIndex < filteredFonts.length - 1) {
      setSelectedFontIndex(selectedFontIndex + 1);
      handleFontChange(filteredFonts[selectedFontIndex + 1]);
    }
  };
  const handleCategoryChange = (newCategory) => {
    setCategory(newCategory);
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          width: "100%",
          marginBottom: "8px",
        }}
      >
        <label style={{ marginRight: "8px", marginBottom: 0, flexShrink: 0 }}>
          Style:
        </label>
        <Select
          defaultValue="All"
          style={{ width: "100%" }}
          onChange={handleCategoryChange}
        >
          <Option value="All">All</Option>
          <Option value="handwriting">Handwriting</Option>
          <Option value="serif">Serif</Option>
          <Option value="sans-serif">Sans Serif</Option>
        </Select>
      </div>
      <div
        style={{ display: "flex", alignItems: "center", marginBottom: "8px" }}
      >
        <label
          style={{
            flexGrow: 0,
            marginRight: "8px",
            marginBottom: 0,
            flexShrink: 0,
          }}
        >
          Font:
        </label>
        <div
          className="font-select-container"
          style={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            marginBottom: "0px",
          }} // Ensure the container takes full width
        >
          <Select
            showSearch
            value={font}
            style={{ flex: "1 1 auto", fontSize: 18, marginRight: 8 }} // Use shorthand flex property
            className="font-select"
            placeholder="Select a font"
            onChange={handleFontChange}
          >
            {filteredFonts.map((fontName) => (
              <Option key={fontName} value={fontName}>
                {fontName}
              </Option>
            ))}
          </Select>

          <div
            className="arrow-buttons"
            style={{
              display: "flex",
              flexDirection: "column",
              flex: "0 1 auto",
            }}
          >
            <Button
              className="arrow-button"
              style={{
                flex: "0 1 auto",
                backgroundColor: style.colors.background0,
              }}
              onClick={handleArrowUp}
            >
              <UpOutlined />
            </Button>
            <Button
              className="arrow-button"
              style={{
                flex: "0 1 auto",
                backgroundColor: style.colors.background0,
              }}
              onClick={handleArrowDown}
            >
              <DownOutlined />
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GoogleFontsSelect;
