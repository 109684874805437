import React, { useEffect, useState } from "react";
import { TypingText } from "../fx/TypingText";
import { motion } from "framer-motion";
import { Layout, Typography, Input, Button, Avatar, Card } from "antd";
import style from "../../Styles/style";

import useProjectStore from "../../store/useProjectStore";
import ThumbnailEditor from "../ProjectComponents/ThumbnailEditor";
import { useGenerateIdeasService } from "../../services/projectService";
import mixpanel from "mixpanel-browser";
const { Title, Paragraph } = Typography;
export const EditorCreatorComponent = ({ onValidated }) => {
  const [nameInput, setnameInput] = useState("");
  const { createCover } = useGenerateIdeasService();
  const [saveDisabled, setSaveDisabled] = useState(false);

  const {
    selectedImage,
    setSelectedImage,
    isEditingCover,
    setIsCreatingCover,
  } = useProjectStore();
  const [isDirty, setIsDirty] = useState(false);
  const [editorImage, setEditorImage] = useState(null);
  const { setProjectName, projectName, selectedProject } = useProjectStore();
  const handleInputChange = (e) => {
    setProjectName(e.target.value);
  };

  const handleSubmit = () => {
    // setProjectName(nameInput);
    // Add validation logic here if needed
    // If validation passes, call onValidated to go to the next step
    //onValidated();
  };
  const validate = (json, dataURL) => {
    mixpanel.track("Validate Editor");
    console.log("json", json);
    createCover(selectedProject._id, selectedImage._id, json, dataURL, () => {
      setIsCreatingCover(false);
      onValidated();
    });
    //onValidated()
  };

  const projectVariants = {
    hidden: { opacity: 0, y: 50 },
    transition: {
      y: { stiffness: 1000, velocity: -10 },
    },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        staggerChildren: 0.2, // 100ms stagger
      },
    },
    exit: { opacity: 0, y: -20 },
  };

  useEffect(() => {
    setTimeout(() => loadSelectedImage(), 0.2);
  }, []);
  const loadSelectedImage = () => {
    setEditorImage(selectedImage);
  };

  return (
    <div
      style={{
        width: "100%",
        padding: 5,
        marginBottom: 30,
        backgroundColor: style.colors.background0,
        display: "flex",
        justifyContent: "center", // Centers horizontally
        minHeight: "100vh", // Takes full viewport height
      }}
    >
      <motion.div
        initial="hidden"
        animate="visible"
        exit="exit" // Define the exit animation
        variants={projectVariants}
        transition={{ duration: 2.0 }} // Adjust the timing as needed
        style={{
          marginTop: 10,
          width: "100%", // Set the width to 100% instead of minWidth

          display: "flex",
          flexDirection: "column",
        }}
      >
        <Card
          style={{
            width: "100%",
            paddingBottom: 100,
            backgroundColor: style.colors.background1,
          }}
        >
          <Title style={{ marginTop: 5 }} level={5}>
            Personalize the cover
          </Title>
          <Paragraph style={{ color: style.colors.textSoft }}>
            You can add multiple text to your art work.
          </Paragraph>
          <ThumbnailEditor
            url={editorImage?.url}
            edit={() => {
              setIsDirty(true);
            }}
            hasJson={selectedProject.hasCover}
            json={selectedProject.cover?.canvas}
            validateCover={validate}
          ></ThumbnailEditor>
        </Card>
      </motion.div>
    </div>
  );
};
