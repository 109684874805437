// authService.js
import axios from "axios";
import api from "./apiInfo";
import { axiosInstance, setAccessAndRefreshTokens } from "./AxiosSetting";
import { useAuthStore } from "../store/useAuthStore";
const { setUser, user } = useAuthStore.getState();

const createCheckoutSession = async (data) => {
  try {
    const response = await axiosInstance.post("/create-checkout-session", data);
    return response.data;
  } catch (error) {
    console.error("Error creating checkout session:", error);
    throw error;
  }
};

export { createCheckoutSession };
