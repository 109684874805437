import React from "react";
import { GoogleOAuthProvider, useGoogleLogin } from "@react-oauth/google";
import { Typography, Layout, Button } from "antd";
import { useAuth } from "../hooks/useAuth";

const { Title } = Typography;
const { Content } = Layout;

export const LoginPage = () => {
  const { login } = useAuth();

  const googleLogin = useGoogleLogin({
    onSuccess: (tokenResponse) => {
      // Handle the successful login here
      handleLogin(tokenResponse);
    },
    // Specify more options as needed
  });

  // This is the callback function after successful login
  const handleLogin = async (tokenResponse) => {
    // The tokenResponse object should contain an access_token if the login was successful
    console.log(tokenResponse);

    // Your login logic here, using the token to authenticate with your backend
    login({
      token: tokenResponse.access_token, // Or whatever token your backend requires
    });
  };

  return (
    <Content style={{ padding: "50px" }}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Title level={3}>Log In with Google</Title>
        <Button onClick={() => googleLogin()}>Sign in with Google 🚀 </Button>
      </div>
    </Content>
  );
};
