import React, { useState } from "react";
import { Image } from "antd";
import { UpOutlined, DownOutlined } from "@ant-design/icons";
import style from "../../../Styles/style";
import mixpanel from "mixpanel-browser";
const IMAGES_PER_PAGE = 3; // Set the number of images you want per page

export const ImageGallery = ({ selectedProject, handleSelectImage }) => {
  const [currentPage, setCurrentPage] = useState(0);

  const handlePrevious = () => {
    mixpanel.track("Click Previous Gallery");
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 0));
  };

  const handleNext = () => {
    mixpanel.track("Click Next Gallery");
    setCurrentPage((prevPage) =>
      Math.min(
        prevPage + 1,
        Math.ceil(selectedProject?.images?.length / IMAGES_PER_PAGE) - 1
      )
    );
  };

  const startIndex = currentPage * IMAGES_PER_PAGE;
  const paginatedImages = selectedProject?.images?.slice(
    startIndex,
    startIndex + IMAGES_PER_PAGE
  );

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column", // Changed to column for vertical layout
        alignItems: "center",
      }}
    >
      <UpOutlined // Changed to Up arrow for vertical scrolling
        onClick={handlePrevious}
        style={{ color: "white", cursor: "pointer", fontSize: "24px" }}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "column", // Align items in a column for vertical layout
          overflowY: "auto", // Enable vertical scrolling
          gap: "16px",
          padding: "16px",
        }}
      >
        {paginatedImages?.map((image, index) => (
          <Image
            preview={false}
            placeholder={true}
            key={index}
            src={image.url}
            width={100} // Adjust the width as necessary
            height={100} // Adjust the height as necessary
            style={{ objectFit: "cover", cursor: "pointer" }}
            onClick={() => handleSelectImage(image)}
          />
        ))}
      </div>
      <DownOutlined // Changed to Down arrow for vertical scrolling
        onClick={handleNext}
        style={{ color: "white", cursor: "pointer", fontSize: "24px" }}
      />
    </div>
  );
};
