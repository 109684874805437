import React from "react";
import {
  Typography,
  Form,
  Input,
  Button,
  Space,
  Layout,
  Result,
  Card,
} from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import style from "../Styles/style";

const { Title, Paragraph } = Typography;
const { Content } = Layout;

export const CancelPage = () => {
  const navigate = useNavigate();
  return (
    <Layout
      style={{
        backgroundColor: style.colors.background0,
        padding: "15%",
        paddingTop: 100,
        background: `url(/backsoft.png)`, // Use backgroundImage with url()
        backgroundSize: "cover", // Ensures the image covers the entire Layout
        backgroundPosition: "center", // Centers the background image
        backgroundRepeat: "no-repeat",
        backgroundAttachment: "fixed",
        height: "100vh", //
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Content style={{ padding: "50px", textAlign: "center", maxWidth: 800 }}>
        <Card
          style={{
            margin: 30,

            backgroundColor: style.colors.background1,
            borderRadius: 20,
          }}
          bordered={false}
        >
          <ExclamationCircleOutlined
            style={{ color: style.colors.color, fontSize: 42 }}
          />
          <Title level={3}>Payment canceled.</Title>
          <Paragraph style={{ marginTop: 10 }}>
            Your card has not been debited, please contact us if you have any
            issue with the payment.
          </Paragraph>
          <Space style={{ marginTop: 30 }}>
            <Button
              type="primary"
              size="large"
              onClick={() => {
                navigate("/dashboard/buy-credits", { replace: true });
              }}
            >
              Buy credits
            </Button>
            <Button
              type="default"
              size="large"
              href="mailto:tonalpixel@gmail.com"
            >
              Contact Support
            </Button>
          </Space>
        </Card>
      </Content>
    </Layout>
  );
};
