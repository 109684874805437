import React, { useState, useEffect } from "react";
import {
  Layout,
  Menu,
  Button,
  Row,
  Col,
  Typography,
  Card,
  List,
  Avatar,
  Form,
  Input,
  Divider,
} from "antd";
import {
  PlayCircleOutlined,
  ThunderboltOutlined,
  RobotOutlined,
  FormatPainterFilled,
  CheckCircleOutlined,
  RiseOutlined,
} from "@ant-design/icons";
import style from "../Styles/style";
import Helmet from "react-helmet";
import mixpanel from "mixpanel-browser";
import { useStore } from "../store/useStore";
const { Header, Content, Footer } = Layout;
const { Title, Paragraph } = Typography;

const features = [
  {
    title: "AI-Generator based on YouTube data",
    icon: <RobotOutlined />,
    description:
      "Harness the power of AI trained with extensive YouTube data for thumbnails that stand out.",
  },
  {
    title: "Breathtaking thumbnails in seconds",
    icon: <ThunderboltOutlined />,
    description:
      "Create stunning thumbnails in a blink with our lightning-fast AI algorithm.",
  },
  {
    title: "No design experience required",
    icon: <FormatPainterFilled />,
    description:
      "Our user-friendly platform allows anyone to generate professional-looking thumbnails.",
  },
];

export const HomePage = () => {
  const { showLoginModal } = useStore();
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  function getStartedTop() {
    showLoginModal();
    mixpanel.track("Get started", { location: "top" });
  }
  function getStartedEnd() {
    showLoginModal();
    mixpanel.track("Get started", { location: "bottom" });
  }
  useEffect(() => {
    // Page Visit
    mixpanel.track("Home Page Visit");
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Call it once to set initial state based on current window size

    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <Layout
      className="layout"
      style={{
        backgroundColor: style.colors.background0,
        minHeight: "100vh",
        width: "100%",
      }}
    >
      <Helmet>
        <title>
          TonalPixel - Spend time on your music, Not on your visuals
        </title>
        <meta
          name="description"
          content="Tonal Pixel creates stunning artworks for your music releases. It generates cover and promotional visuals for your next single or album release in just one click."
        />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="theme-color" content="#000000" />
        <link rel="icon" href="/tonalpixel.ico" />
        <link rel="apple-touch-icon" href="/logo192.png" />
        <link rel="manifest" href="/manifest.json" />
        <meta property="og:title" content="TonalPixel" />
        <meta
          property="og:description"
          content="Spend time on your music, Not on your visuals."
        />
        <meta property="og:image" content="/social-preview-image.png" />
        <meta property="og:url" content="https://www.tonalpixel.com" />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="TonalPixel" />
        <meta
          name="twitter:description"
          content="Spend time on your music, Not on your visuals."
        />
        <meta name="twitter:image" content="/logo512.png" />
        {/* Additional meta tags as needed */}
      </Helmet>
      <div
        style={{
          width: "100%",
          paddingBottom: 100,
          paddingLeft: "10%",
          paddingRight: "10%",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          background: isMobile
            ? `url(/home-mobile.png)`
            : `url(/sphere-background.png)`, // Use backgroundImage with url()
          backgroundSize: "cover",
          backgroundPosition: "center", // Centers the background image
          backgroundRepeat: "no-repeat",
          backgroundAttachment: "fixed",
        }}
      >
        <div
          style={{
            marginTop: 150,
            marginBottom: 0,
            fontSize: 14,
            width: "100%",
            textAlign: "center",
            fontWeight: "bold",
            color: style.colors.text,
          }}
        >
          Made
          <span style={{ color: style.colors.color }}> by Artists,</span> For
          <span style={{ color: style.colors.color }}> Artists.</span>{" "}
        </div>
        <Title style={{ marginTop: 30, textAlign: "center", fontWeight: 900 }}>
          Spend time on your music, <br></br> Not on your visuals.
        </Title>
        <Paragraph className="subtitle">
          Let AI create stunning artwork and promotional visuals for your next
          song in just one click.
        </Paragraph>
        <Button
          type="primary"
          style={{ borderRadius: 30, marginTop: 20 }}
          size="large"
          onClick={getStartedTop}
        >
          Get Started
        </Button>

        <img
          style={{ marginTop: 70, width: "100%" }}
          src="/example-landing.png"
        ></img>
      </div>
      <div
        style={{
          marginTop: 0,
          width: "100%",
          padding: 10,
          backgroundColor: style.colors.background1,
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          background: `url(/liaison.png)`, // Use backgroundImage with url()
          backgroundSize: "cover",
          backgroundPosition: "center", // Centers the background image
          backgroundRepeat: "no-repeat",
        }}
      ></div>
      <div
        className="home-container"
        style={{
          width: "100%",

          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          background: isMobile
            ? `url(/mobile-section.png)`
            : `url(/sphere-blur.png)`,
          // Use backgroundImage with url()
          backgroundSize: "cover",
          backgroundPosition: "center", // Centers the background image
          backgroundRepeat: "no-repeat",
        }}
      >
        <Title style={{ margin: 0, marginBottom: 30 }} level={2}>
          {" "}
          Why choose us ?
        </Title>
        <Row gutter={24} style={{ width: "100%", marginTop: 30 }}>
          <Col xs={24} sm={24} md={24} lg={12} xl={12} className="hide-desktop">
            <div
              style={{
                width: "100%",
                maxHeight: 400,
                flexDirection: "column",
                display: "flex",
                alignContent: "center",
                alignItems: "center",
              }}
            >
              <img className="image-home" src="/stands.png"></img>
            </div>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={12} style={{}}>
            <div className="card-home">
              <div
                style={{
                  backgroundColor: "rgba(23, 23, 26, 0.5)",
                  padding: 30,
                  borderRadius: 30,
                }}
              >
                <Title style={{ marginTop: 0 }} level={3}>
                  Make your music{" "}
                  <span style={{ color: style.colors.color }}>stand out</span>
                </Title>
                <Paragraph style={{ fontSize: 16, marginBottom: 0 }}>
                  Create memorable artwork that will attract new listeners'
                  attention, increasing your reach.
                </Paragraph>
              </div>
            </div>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={12} className="hide-mobile">
            <div
              style={{
                width: "100%",
                maxHeight: 400,
                flexDirection: "column",
                display: "flex",
                alignContent: "center",
                alignItems: "center",
              }}
            >
              <img className="image-home" src="/stands.png"></img>
            </div>
          </Col>
        </Row>
        <Row gutter={24} style={{ width: "100%", marginTop: 40 }}>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            <div
              style={{
                width: "100%",
                maxHeight: 400,
                flexDirection: "column",
                display: "flex",
                alignContent: "center",
                alignItems: "center",
              }}
            >
              <img className="image-home-smaller" src="/promotion.png"></img>
            </div>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={12} style={{}}>
            <div className="card-home">
              <div
                style={{
                  backgroundColor: "rgba(23, 23, 26, 0.5)",
                  padding: 30,

                  borderRadius: 30,
                }}
              >
                <Title style={{ marginTop: 0 }} level={3}>
                  Visuals for{" "}
                  <span style={{ color: style.colors.color }}>Instagram</span>{" "}
                  and <span style={{ color: style.colors.color }}>Youtube</span>
                  .
                </Title>
                <Paragraph style={{ fontSize: 16 }}>
                  Get perfectly formatted promotional images (vertical and
                  horizontal) that make announcing your new releases impactful.
                </Paragraph>
              </div>
            </div>
          </Col>
        </Row>
        <Row gutter={24} style={{ width: "100%", marginTop: 30 }}>
          <Col xs={24} sm={24} md={24} lg={12} xl={12} className="hide-desktop">
            <div
              style={{
                width: "100%",
                maxHeight: 400,
                flexDirection: "column",
                display: "flex",
                alignContent: "center",
                alignItems: "center",
              }}
            >
              <img className="image-home" src="/format.png"></img>
            </div>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={12} style={{}}>
            <div className="card-home">
              <div
                style={{
                  backgroundColor: "rgba(23, 23, 26, 0.5)",
                  padding: 30,
                  borderRadius: 30,
                }}
              >
                <Title style={{ marginTop: 0 }} level={3}>
                  Publish your song in
                  <span style={{ color: style.colors.color }}> no time.</span>
                </Title>
                <Paragraph style={{ fontSize: 16, marginBottom: 0 }}>
                  Our visuals are crafted to meet the specifications of all
                  major streaming platforms, ensuring you are ready to publish
                  your songs in no time
                </Paragraph>
              </div>
            </div>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={12} className="hide-mobile">
            <div
              style={{
                width: "100%",
                maxHeight: 400,
                flexDirection: "column",
                display: "flex",
                alignContent: "center",
                alignItems: "center",
              }}
            >
              <img className="image-home-squared" src="/format.png"></img>
            </div>
          </Col>
        </Row>
      </div>
      <div
        style={{
          marginTop: 0,
          width: "100%",
          padding: 10,
          backgroundColor: style.colors.background1,
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          background: `url(/liaison.png)`, // Use backgroundImage with url()
          backgroundSize: "cover",
          backgroundPosition: "center", // Centers the background image
          backgroundRepeat: "no-repeat",
        }}
      ></div>
      <div
        style={{
          width: "100%",
          paddingBottom: 100,
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          background: `url(/background-design.png)`, // Use backgroundImage with url()
          backgroundSize: "contain",
          backgroundPosition: "center", // Centers the background image
          backgroundRepeat: "no-repeat",
          backgroundAttachment: "fixed",
        }}
      >
        <Title style={{ marginTop: 150, textAlign: "center" }}>
          Design Made <span style={{ color: style.colors.color }}>Simple</span>
        </Title>
        <Paragraph className="subtitle">
          No design skills or expensive software needed. Our intuitive platform
          lets you easily add the essentials to your visuals.
        </Paragraph>
        <Button
          type="primary"
          style={{ borderRadius: 30, marginTop: 20 }}
          size="large"
          onClick={getStartedEnd}
        >
          Try for free
        </Button>
      </div>

      <Footer style={{ textAlign: "center" }}>
        TonalPixel ©{new Date().getFullYear()} Created with love and code. |
        <a
          href="/privacy"
          style={{ margin: "0 10px", color: style.colors.color }}
        >
          Privacy Policy
        </a>
        |
        <a
          href="/termsandservices"
          style={{ margin: "0 10px", color: style.colors.color }}
        >
          Terms & Services
        </a>
      </Footer>
    </Layout>
  );
};
