import React, { useState } from "react";
import { Layout, Typography, Row, Col, Drawer, Button, Modal } from "antd";

import { useNavigate, useLocation } from "react-router-dom";
import { MenuOutlined, CloseOutlined } from "@ant-design/icons";
import { useAuth } from "../hooks/useAuth";
import style from "../Styles/style";
import { GoogleLogin, useGoogleLogin } from "@react-oauth/google";
import { useStore } from "../store/useStore";
import UserCredits from "../components/navbar/usercredits";
import { useAuthStore } from "../store/useAuthStore";
const { Header } = Layout;
const { Title } = Typography;
export const AppBarIntro = ({ pages }) => {
  const [drawerVisible, setDrawerVisible] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const currentPath = location.pathname;
  const { user, login, logout } = useAuth();
  const { decrementCredits } = useAuthStore();
  const { isLoginModalVisible, showLoginModal, hideLoginModal } = useStore();

  // ... other state and hooks ...

  const googleLogin = useGoogleLogin({
    flow: "implicit", // or 'auth-code' depending on the flow you need
    scope: "openid email profile",
    onSuccess: (tokenResponse) => {
      // The tokenResponse should contain the ID token if configured correctly
      console.log(tokenResponse);

      // The ID token is usually found in the id_token field

      // Send the ID token to your server for verification and processing
      login({
        token: tokenResponse,
      });
    },
    onError: (error) => {
      console.log("Login Failed:", error);
    },
    // Other necessary configurations...
  });
  const handleLogin = async (googleData) => {
    // Here you can use googleData to authenticate the user in your backend
    // and log them in your app with the login function from useAuth hook.
    console.log(googleData);
    console.log(googleData.credential);
    login({
      token: googleData.credential, // or whatever token your backend requires
    });
  };

  const handleNavClick = (path, label) => {
    setDrawerVisible(false);

    if (label === "Logout") {
      logout();
    } else if (label === "Login") {
      // Instead of navigating, show the login modal
      showLoginModal();
    } else {
      // Navigate only if the path is different from the current path
      if (path !== currentPath) {
        navigate(path);
      }
    }
  };

  const toggleDrawer = () => {
    setDrawerVisible(!drawerVisible);
  };
  const decrement = () => {
    decrementCredits();
  };
  const navigationLinks = pages?.map((page) => {
    // Determine the label based on user's login status and the page label
    let label = page.label;
    if (page.label === "Login" && user) {
      label = "Logout";
    }

    return (
      <Typography.Link
        key={page.key}
        onClick={() => handleNavClick(page.path, label)} // Pass the possibly modified label
        style={{
          color:
            currentPath === page.path
              ? style.colors.text
              : style.colors.textSelected,
          backgroundColor:
            currentPath === page.path
              ? style.colors.background2
              : "transparent",
          padding: "0 16px",
          display: "inline-block",
          lineHeight: "44px",
          borderRadius: 20,
          marginTop: 5,
          marginBottom: 5,
        }}
      >
        {label}
      </Typography.Link>
    );
  });

  return (
    <Header
      style={{
        boxShadow: "0 0px 30px 0 rgba(0,0,0,.2)",
        backgroundColor: style.colors.background0,
        position: "fixed",
        zIndex: 2,
        width: "100%",
        height: "64px", // Adjust height as needed
        lineHeight: "64px", // Align text vertically
        padding: "0 50px", // Adjust horizontal padding as needed
        margin: 0, // Ensure there's no margin
      }}
    >
      <Modal
        visible={isLoginModalVisible}
        onCancel={hideLoginModal}
        footer={
          [
            // Customize modal footer as needed, e.g.:
            // Add other buttons like "Login" here if necessary
          ]
        }
      >
        {/* The content of the login modal, e.g., a login form */}
        <div
          style={{
            display: "flex",
            padding: 0,
            flexDirection: "column",
            alignItems: "center",
            height: 200,
          }}
        >
          <Title level={2}>Login to start!</Title>
          <p>Choose one of the solution below to connect</p>
          <Button
            style={{
              height: 50,
              background: style.colors.blue,
            }}
            onClick={() => googleLogin()}
          >
            Sign in with Google 🚀{" "}
          </Button>
          <GoogleLogin
            onSuccess={handleLogin}
            onError={() => console.log("Login Failed")}
          />
        </div>
      </Modal>
      <Row justify="space-between" align="middle" style={{ height: "44px" }}>
        <Col>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              height: "100%",
              alignContent: "center",
            }}
          >
            <img
              src="/logotonalpixel.png"
              alt="TonalPixel Logo"
              style={{
                paddingTop: 5,
                maxHeight: "40px", // Adjust the size as needed
                cursor: "pointer",
              }}
              onClick={() => handleNavClick("/")}
            />
          </div>
        </Col>
        <Col>
          <Button
            className="navbar-mobile"
            type="primary"
            onClick={toggleDrawer}
          >
            <MenuOutlined />
          </Button>
        </Col>
      </Row>
      <Drawer
        title="Menu"
        placement="right"
        closable={true}
        onClose={toggleDrawer}
        visible={drawerVisible}
        closeIcon={<CloseOutlined />}
        headerStyle={{ padding: "16px" }} // Adjust padding for the drawer header as needed
      >
        <div
          style={{
            color: style.colors.text,
            display: "flex",
            flexDirection: "column",
          }}
        >
          {navigationLinks}
        </div>
      </Drawer>
    </Header>
  );
};
