import style from "../Styles/style";
import React, { useState, useEffect, useRef } from "react";
import { PlusOutlined } from "@ant-design/icons";
import { useAuthStore } from "../store/useAuthStore";
import { CloseOutlined } from "@ant-design/icons";
import { motion, AnimatePresence } from "framer-motion";
import {
  Row,
  Card,
  Col,
  Layout,
  Space,
  Button,
  Menu,
  Typography,
  Input,
  Skeleton,
  Divider,
  Tag,
  Empty,
} from "antd";
import CheckableTag from "antd/es/tag/CheckableTag";
import useGlobalModal from "../components/Modal/useModalGlobal";
import { useStore } from "../store/useStore";
import {
  createProject,
  useGenerateIdeasService,
  useProjectService,
} from "../services/projectService";
import ProjectComponent from "../components/ProjectComponent";
import useProjectStore from "../store/useProjectStore";
const { Title, Paragraph } = Typography;
const { Header, Footer, Sider, Content } = Layout;

export const ProjectPage = () => {
  const { user } = useAuthStore();

  const { showModal } = useStore();
  const setSelectedProject = useProjectStore(
    (state) => state.setSelectedProject
  );
  const selectedProject = useProjectStore((state) => state.selectedProject);

  const createProject = useProjectService();

  const fullHeightStyle = {
    height: "100vh", // 100% of the viewport height
    display: "flex",
    flexDirection: "column",
    backgroundColor: style.colors.background0,
  };

  const handleSelectProject = (project) => {
    setSelectedProject(project);
  };

  const addProject = (inputValue) => {
    createProject(inputValue, () => {
      selectLastProject();
    });
  };
  const handleAddProject = () => {
    showModal("Give a name to your new project", {
      onOk: addProject,
      input: true,
      initialInputValue: "",
      inputPlaceholder: "Add a name to your project",
    });
  };
  const selectLastProject = () => {
    if (user.projects && user.projects.length > 0) {
      let project = user.projects[0];
      setSelectedProject(project);
    }
  };
  useEffect(() => {
    selectLastProject();
  }, []);
  return (
    <Layout style={fullHeightStyle}>
      {" "}
      {/* Apply the style here */}
      <Layout style={{ backgroundColor: style.colors.background0, flex: 1 }}>
        {" "}
        {/* Ensure flex grow is set for inner layout */}
        <Sider
          style={{
            paddingTop: 10,
            height: "100vh",
            position: "fixed",
            paddingLeft: 15,
            paddingRight: 15,
            backgroundColor: style.colors.background1,
          }}
        >
          <Space
            direction="vertical"
            style={{
              width: "100%",
              padding: "10px",
              backgroundColor: style.colors.background1,
            }}
          >
            <Row align="middle" justify="space-between">
              <Col>
                <Title level={5} style={{ margin: 0, lineHeight: "32px" }}>
                  My projects
                </Title>
              </Col>
              <Col>
                <Button
                  type="primary"
                  shape="circle"
                  icon={<PlusOutlined />}
                  size="small" // Set the size to small
                  onClick={handleAddProject}
                />
              </Col>
            </Row>
          </Space>
          <Menu
            selectedKeys={selectedProject ? [selectedProject._id] : []}
            mode="inline"
            style={{
              backgroundColor: style.colors.background1,
              height: "100%",
              borderRight: 0,
            }}
          >
            {user.projects &&
              user.projects.map((project, index) => (
                <Menu.Item
                  key={project._id}
                  onClick={() => handleSelectProject(project)}
                >
                  <motion.div
                    key={project.id}
                    initial={index === 0 ? { opacity: 0, x: -100 } : {}}
                    animate={index === 0 ? { opacity: 1, x: 0 } : {}}
                    exit={index === 0 ? { opacity: 0 } : {}}
                    transition={{ duration: 1 }}
                  >
                    {project.name}
                  </motion.div>
                </Menu.Item>
              ))}
          </Menu>
        </Sider>
        <ProjectComponent></ProjectComponent>
      </Layout>
    </Layout>
  );
};
