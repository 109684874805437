import React, { useEffect, useState } from "react";
import {
  Typography,
  Form,
  Input,
  Button,
  Space,
  Layout,
  Card,
  Result,
  Spin,
  Flex,
} from "antd";
import {
  UserOutlined,
  LockOutlined,
  LoadingOutlined,
  ExclamationCircleOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import { useLocation } from "react-router-dom";
import api from "../services/apiInfo";
import { axiosInstance } from "../services/AxiosSetting";
import { useAuthStore } from "../store/useAuthStore";
import style from "../Styles/style";
import mixpanel from "mixpanel-browser";
const { Title, Paragraph } = Typography;
const { Content } = Layout;

export const SuccessPage = () => {
  const [status, setStatus] = useState("pending");
  const { setUser } = useAuthStore();
  const location = useLocation();
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  const navigate = useNavigate();
  const [credits, setCredits] = useState(50);
  useEffect(() => {
    mixpanel.track("Success payment");
    const query = new URLSearchParams(location.search);
    const sessionId = query.get("sessionId");

    const checkStatus = async () => {
      try {
        const response = await axiosInstance.get(`check-status`, {
          params: { sessionId: sessionId },
        });
        if (response.data.status === "completed" && response.data.user) {
          setUser(response.data.user);
          setCredits(response.data.credits);
        }
        setStatus(response.data.status);
      } catch (error) {
        console.error("Error checking session status:", error);
        setStatus("error");
      }
    };

    if (sessionId) {
      checkStatus();
    }

    // Set up an interval to check the status every 2 seconds
    const interval = setInterval(() => {
      if (status === "pending") {
        checkStatus();
      } else {
        clearInterval(interval); // Stop checking once status is no longer pending
      }
    }, 2000);

    // Clear the interval when the component unmounts
    return () => clearInterval(interval);
  }, [location, status]);

  return (
    <Layout
      style={{
        backgroundColor: style.colors.background0,
        padding: "15%",
        paddingTop: 100,
        background: `url(/backsoft.png)`, // Use backgroundImage with url()
        backgroundSize: "cover", // Ensures the image covers the entire Layout
        backgroundPosition: "center", // Centers the background image
        backgroundRepeat: "no-repeat",
        backgroundAttachment: "fixed",
        height: "100vh", //
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Content style={{ padding: "50px", textAlign: "center", maxWidth: 800 }}>
        {status === "pending" && (
          <Card
            style={{
              margin: 30,

              backgroundColor: style.colors.background1,
              borderRadius: 20,
            }}
            bordered={false}
          >
            <Spin indicator={antIcon} />
            <Title level={3}>Your payment is being processed.</Title>
          </Card>
        )}
        {status === "completed" && (
          <Card
            style={{
              margin: 30,

              backgroundColor: style.colors.background1,
              borderRadius: 20,
            }}
            bordered={false}
          >
            <CheckCircleOutlined
              style={{ color: style.colors.color, fontSize: 24 }}
            />
            <Title level={3}>Your payment is successful.</Title>
            <Paragraph>You are now granted with {credits} credits.</Paragraph>
            <Space style={{ marginTop: 30 }}>
              <Button
                type="default"
                onClick={() => {
                  navigate("/dashboard/project", { replace: true });
                }}
              >
                Go to Projects
              </Button>
            </Space>
          </Card>
        )}
        {status === "failed" && (
          <Card
            style={{
              margin: 30,

              backgroundColor: style.colors.background1,
              borderRadius: 20,
            }}
            bordered={false}
          >
            <CloseCircleOutlined style={{ color: "red", fontSize: 24 }} />
            <Title level={3}>Your payment was denied.</Title>
            <Paragraph>
              There was an issue with your payment. Please try again or contact
              our services.
            </Paragraph>
            <Space style={{ marginTop: 30 }}>
              <Button
                type="default"
                onClick={() => {
                  navigate("/dashboard/project", { replace: true });
                }}
              >
                Go to Projects
              </Button>
              <Button type="primary" href="mailto:tonalpixel@gmail.com">
                Contact Support
              </Button>
            </Space>
          </Card>
        )}
        {status === "error" && (
          <Card
            style={{
              margin: 30,

              backgroundColor: style.colors.background1,
              borderRadius: 20,
            }}
            bordered={false}
          >
            <ExclamationCircleOutlined
              style={{ color: "orange", fontSize: 42 }}
            />
            <Title level={3}>Error Checking Payment Status.</Title>
            <Paragraph style={{ marginTop: 10 }}>
              We encountered an error while checking the payment status. Please
              refresh the page or contact support.
            </Paragraph>
            <Space style={{ marginTop: 30 }}>
              <Button
                type="default"
                onClick={() => {
                  navigate("/dashboard/project", { replace: true });
                }}
              >
                Go to Projects
              </Button>
              <Button type="primary" href="mailto:tonalpixel@gmail.com">
                Contact Support
              </Button>
            </Space>
          </Card>
        )}
      </Content>
    </Layout>
  );
};
