import React, { useEffect } from "react";

import { Avatar, Typography } from "antd";
import { UserOutlined } from "@ant-design/icons";
import "./usercredits.css";
import { useAuthStore } from "../../store/useAuthStore";
import style from "../../Styles/style";

const { Text } = Typography;

const UserCredits = ({ styles }) => {
  const user = useAuthStore((state) => state.user);
  const shouldAnimateCredits = useAuthStore(
    (state) => state.shouldAnimateCredits
  );
  const clearAnimation = useAuthStore((state) => state.clearAnimation);

  useEffect(() => {
    if (shouldAnimateCredits) {
      // Trigger CSS animation and clear the flag after it
      setTimeout(() => {
        clearAnimation();
      }, 600); // This should match the duration of your CSS animation
    }
  }, [shouldAnimateCredits, clearAnimation]);

  return (
    <div className="user-credits" style={styles}>
      <Avatar
        style={{ backgroundColor: "#3F4050" }}
        size="large"
        icon={<UserOutlined />}
      />
      <div className="user-info">
        <Text strong className="user-name">
          {user?.artistName || user?.name}
        </Text>
        <Text
          className={`credits ${shouldAnimateCredits ? "animate-credit" : ""}`}
        >
          <span style={{ fontWeight: "bold", color: style.colors.color }}>
            {user?.credits || 0}{" "}
          </span>
          crédits
        </Text>
      </div>
    </div>
  );
};

export default UserCredits;
